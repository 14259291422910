import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  Select,
  MenuItem,
  Tab,
  Menu,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Formik, useFormik, Form } from "formik";

import { color } from "../../../StyleData/Config";
import { Circle, Clear, MoreHoriz } from "@mui/icons-material";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CreateGeneral from "./CreateGeneral";
import WorkPlan from "./WorkPlan/WorkPlan";
import WOView from "./WOView";
import WorkAssignment from "./WOAssignment/WorkAssignment";
import Completion from "./WOCompletion/Completion";
import ListMenuWO from "./SideMenu/ListMenuWO";
import { useSearchParams } from "react-router-dom";

const WO = ({ mode, editData, handleCloseClick, ui }) => {
  console.log('ui',ui);
  const [value, setValue] = useState("1");
  const [jobPlanId, setJobPlanId] = useState("");
  const [workOrderId, setWorkOrderId] = useState("");
  const [woData, setWoData] = useState({});
  const [dotBL, setDotBL] = useState(null);
  const [direction, setDirection] = useState("row");
  const [searchParams, setSearchParams] = useSearchParams();
  const [refreshKey, setRefreshKey] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
   getData()
  }, []);
  const getData=async()=>{
    const id = searchParams.get("id");
    const wolink = masterAPI["workOrder"].getbyid;
   await axiosPrivate
      .get(`${wolink}/${id}`)
      .then((res) => {
        console.log(res);
        // DataWO(res.data.result)
        setWoData(res.data.result);
        setJobPlanId(res.data.result.jobPlanId);
        setWorkOrderId(res.data.result.id);
        setRefreshKey(prevKey => prevKey + 1);
      })
      .catch((e) => {
        setWoData([])
        setJobPlanId([])
        setWorkOrderId([])
        console.log(e, "workorderview");
      });
  }

  const handleDot = (event) => {
    if (!dotBL) {
      setDotBL(event.currentTarget);
    } else {
      setDotBL(null);
    }
  };

  const contentPosition = (flex) => {
    setDirection(flex);
  };
  return (
    <Grid container spacing={"1rem"}>
      <Grid
        item
        xs={direction === "column" ? 6 : 9.5}
        md={direction === "column" ? 6 : 10.5}
        lg={direction === "column" ? 6 : 11}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            mb: "1rem",
            gap: "1rem",
          }}
          // sx={{mb:"1rem"}}
        >
          <Typography
            sx={{
              // color: color.masterDialogTitleColor,
              // fontSize: color.masterDialogFontSize,
              fontSize: color.AssetTitlesize,
              fontWeight: color.sideFontWeightAsset,
              display: ui ? "flex" : null,
              justifyContent: ui ? "center" : "space-between",
              width: ui ? "40%" : "100%",
              marginTop: ui ? '10px' : null,
            }}
          >
            Work Order : {woData.code} : {woData.workOrderName}
          </Typography>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              <Circle sx={{ fontSize: "0.5rem", color: "green" }} />
              <Typography>{woData.status}</Typography>
            </Box>
            {/* <MoreHoriz onClick={handleDot} sx={{ cursor: "pointer" }} />
              <Menu anchorEl={dotBL} open={Boolean(dotBL)} onClose={handleDot}>
                <MenuItem>Option 1</MenuItem>
                <MenuItem>Option 2</MenuItem>
                <MenuItem>Option 3</MenuItem>
              </Menu> */}
            <Box></Box>
          </Box>
        </Box>
        <TabContext value={value}>
          <Box
            sx={{
              ".css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                color: color.TabLabelcolor,
                // p: 0.5,
              },
              display: "flex",
              justifyContent: ui ? "center" : "space-between",
              width: ui ? "40%" : "100%",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                sx: { backgroundColor: color.TabLabelcolor, height: "3px" },
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="General" value="1" sx={{ textTransform: "none" }} />
              <Tab label="Work Plan" value="2" sx={{ textTransform: "none" }} />
              <Tab
                label="Work Assignment"
                value="3"
                sx={{ textTransform: "none" }}
              />

              <Tab
                label="Completion"
                value="4"
                sx={{ textTransform: "none" }}
              />
              {/* <Tab
                    label="Costing"
                    value="5"
                    sx={{ textTransform: "none" }}
                  /> */}
            </TabList>
          </Box>
          <Box sx={{ mt: "1rem" }}>
            <TabPanel value="1" sx={{ p: 1 }}>
              {/* <CreateGeneral jobPlanIden={handleJobPlanId}/> */}
              <WOView woData={woData} direction={direction} />
            </TabPanel>
            <TabPanel value="2">
              <WorkPlan
                jobPlanId={jobPlanId}
                workOrderId={workOrderId}
                direction={direction}
              />
            </TabPanel>
            <TabPanel value="3">
              <WorkAssignment
                jobPlanId={jobPlanId}
                workOrderId={workOrderId}
                direction={direction}
              />
            </TabPanel>
            <TabPanel value="4">
              <Completion
                workOrderId={workOrderId}
                direction={direction}
                refreshKey={refreshKey}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>
      <Grid
        container
        justifyContent={"flex-end"}
        item
        xs={direction === "column" ? 6 : 2.5}
        md={direction === "column" ? 6 : 1.5}
        lg={direction === "column" ? 6 : 1}
        sx={{
          boxShadow:
            direction === "column" ? "-1px 1px 7px 2px rgb(0 0 0 / 32%)" : 0,
          minHeight: "92vh",
          backgroundColor: "transparent",
        }}
      >
        <ListMenuWO
          contentPosition={contentPosition}
          data={woData}
          direction={direction}
          getData={getData}
        />
      </Grid>
    </Grid>
  );
};

export default WO;
