import { Box, Tab, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { color } from '../../../../StyleData/Config'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import General from './General'
import InventoryBalance from './InventoryBalance'

const PartInventoryView = () => {
    const part_stockroomDetail=useSelector(state=>state.dataRed.part_stockroomDetail)
    console.log(part_stockroomDetail, "testview");

    const [value,setValue]=useState("1")

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
      };

  return (
    <Box sx={{ p: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          mb: "1rem",
          gap: "1rem",
        }}
        // sx={{mb:"1rem"}}
      >
        <Typography
          sx={{
            // color: color.masterDialogTitleColor,
            // fontSize: color.masterDialogFontSize,
            fontSize: color.AssetTitlesize,
            fontWeight: color.sideFontWeightAsset,
          }}
        >

          {part_stockroomDetail.code+" - "+part_stockroomDetail.partName}
        </Typography>
       
      </Box>

      <TabContext value={value}>
        <Box
          sx={{
            ".css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected": {
              color: color.TabLabelcolor,
              // p: 0.5,
            },
            display: "flex",
            justifyContent: "space-between",
            width: "98%",
          }}
        >
          <TabList
            onChange={handleChangeTab}
            aria-label="lab API tabs example"
            TabIndicatorProps={{
              sx: { backgroundColor: color.TabLabelcolor, height: "3px" },
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="General" value="1" sx={{ textTransform: "none" }} />
            <Tab label="Inventory Balance" value="2" sx={{ textTransform: "none" }} />
          </TabList>

          {/* <IconButton type='submit' sx={{backgroundColor:color.TableButtonColor}}>
          <PlaylistAdd sx={{color:"white",fontSize:"1.7rem"}}/>
        </IconButton> */}
        </Box>
        <Box sx={{ mt: "1rem" }}>
          <TabPanel value="1" sx={{ p: 1 }}>
           <General data={part_stockroomDetail}/>
          </TabPanel>
          <TabPanel value="2" sx={{ p: 1 }}>
           <InventoryBalance info={part_stockroomDetail}/>
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  )
}

export default PartInventoryView