import {
  Box,
  Button,
  Dialog,
  Grid,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { masterAPI } from "../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import NormalTable from "../../../../Component/NormalTable";
import { color } from "../../../../StyleData/Config";
import { DeleteOutline, UploadFile, UploadFileRounded } from "@mui/icons-material";
import DocUpload from "../../../../Component/DocUpload";
import { toast } from "react-toastify";

const InsScheduleDocWO = ({ data }) => {
  const [docData, setDocData] = useState([]);
  const [doc, setDoc] = useState(false);

  useEffect(() => {
    console.log(data, "checkData");
    getData();
    //   const data=[{
    //     id:"SPDF2021",
    //     description:"Racking Assemble(A144)",
    //     statusId:"JDF05242.0",
    //     planStartDate:"2023-10-15",
    //     planEndDate:"2023-11-15",
    //     startDate:"2023-10-14",
    //     endDate:"2023-11-14",
    //   },
    //   {
    //     id:"SPDF2021",
    //     description:"Racking Assemble(A144)",
    //     statusId:"JDF05242.0",
    //     planStartDate:"2023-10-15",
    //     planEndDate:"2023-11-15",
    //     startDate:"2023-10-14",
    //     endDate:"2023-11-14",
    //   },
    //   {
    //     id:"SPDF2021",
    //     description:"Racking Assemble(A144)",
    //     statusId:"JDF05242.0",
    //     planStartDate:"2023-10-15",
    //     planEndDate:"2023-11-15",
    //     startDate:"2023-10-14",
    //     endDate:"2023-11-14",
    //   },
    // ]
    //   setWorkData(data)
  }, []);
  const confirmDelete = (id) => {
    toast(
      <Grid>
        <Typography sx={{fontSize:"15px",fontWeight:600, color:"#797979"}}>Are you sure you want to delete?</Typography>
        <Grid item container spacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                handleDelete(id);
                toast.dismiss();
              }}
              sx={{ color: "grey", borderColor: "green" }}
              size="small"
              variant="outlined"
            >
              Yes
            </Button>
          </Grid>
          <Grid item >
            <Button
              onClick={() => toast.dismiss()}
              sx={{ color: "grey", borderColor: "red" }}
              size="small"
              variant="outlined"
            >
              No
            </Button>
          </Grid>
        </Grid>
      </Grid>,
      {
        closeButton: false,
        autoClose: false,
      }
    );
  };
  const getData = async() => {
    const documentLink = masterAPI["inspSchedule"].getdocumentbyinsid;
    console.log(data);
   await axiosPrivate
      .get(`${documentLink}/${data}`)
      .then((res) => {
        console.log(res.data);
        setDocData(res.data.result);
      })
      .catch((e) => {
        setDocData([]) 
        console.log(e, "Work Order Error");
      });
  };

  const handleUpload = async(value) => {
    const doclink = masterAPI["inspSchedule"].uploaddocument;
    const uploadData = {
      InspectionId: data,
      ...value,
    };
    console.log(uploadData, "upload doc");
    await axiosPrivate
      .post(`${doclink}`, uploadData)
      .then((res) => {
        console.log(res);
        toast.success(res.data.message);
        handleClose();
      })
      .catch((e) => {
        console.log(e, "error doc wo ");
      });
  };

  const handleDelete = async(id) => {
    const deleteLink = masterAPI["inspSchedule"].deletedocument;
   await axiosPrivate
      .delete(`${deleteLink}/${id}`)
      .then((res) => {
        console.log(res.data);
        getData();
      })
      .catch((e) => {
        console.log(e, "delete WODoc");
      });
  };

  const handleDownload=async(id)=>{
    const downloadLink=masterAPI["inspSchedule"].downloaddocument
   await axiosPrivate.get(`${downloadLink}/${id}`).then(res=>{
      console.log(res)
      //  window.open(`https://demo.orienseam.com/${res.data.result.documentName}}`)
      window.open(res.data.result.documentPath)
    }).catch(e=>{
      console.log(e)
    })
  
  }

  const columns = [
    // {
    //   Header: "Doc ID",
    //   accessor: "id",
    //   Cell: ({ cell }) => {
    //     return (
    //       <Typography
    //         sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue" }}
    //       >
    //         {cell.value}
    //       </Typography>
    //     );
    //   },
    // },
    {
      Header: "Doc Name",
      accessor: "documentName",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue",cursor:"pointer" }}
            onClick={()=>{handleDownload(cell.row.original.id)}}
          >
           {cell.value.split("/")[2].split("_")[1]}
          </Typography>
        );
      },
    },
    {
      Header: "Doc Type",
      accessor: "documenttype",
    },
    // {
    //   Header: "Actions",
    //   Cell: ({ cell }) => {
    //     // console.log(cell)
    //     return (
    //       <Box sx={{ whiteSpace: "nowrap" }}>
           
    //         <IconButton  onClick={() => {
    //               handleDelete(cell.row.original.id);
    //             }}>
    //           <DeleteOutline
               
    //             sx={{
    //               fontSize: color.masterEditDelButton,
    //               color: color.masterEditDelButtonColor,
    //             }}
    //           />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
     {
            Header: "Action",
            accessor: "id",
            Cell: ({ cell }) => (
              <IconButton onClick={() => confirmDelete(cell.value)}>
                <DeleteOutline sx={{ color: "grey" }} />
              </IconButton>
            ),
          },
  ];

  const handleFile = () => {
    setDoc(true);
  };

  const handleClose = () => {
    setDoc(false);
    getData();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>
        <Typography
          sx={{
            color: color.sideTitleColor,
            fontSize: color.fontSizeSide,
            fontWeight: color.sideWeightFont,
          }}
        >
          Document
        </Typography>
      </Box>
      <Paper>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            p: 1,
          }}
        >
          {/* <IconButton>
        <UploadFileRounded sx={{color:"blue",fontSize:"1.7rem"}}/>
      </IconButton> */}
          <Button variant="contained" onClick={handleFile}>
            <UploadFileRounded />
            File Upload
          </Button>
        </Box>
        <Divider />
        <NormalTable columns={columns} data={docData} />
      </Paper>
      <Dialog
        open={doc}
        onClose={handleClose}
        fullWidth
        maxWidth={"xs"}
        children={
          <DocUpload
            handleCloseClick={handleClose}
            handleUpload={handleUpload}
          />
        }
      />
    </Box>
  );
};

export default InsScheduleDocWO;
