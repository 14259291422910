import { Box, Grid, Divider, IconButton, Paper, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { masterAPI } from "../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import NormalTable from "../../../../Component/NormalTable";
import { toast } from "react-toastify";
import { color } from "../../../../StyleData/Config";
import DateFormatter from "../../../../Component/DateFormatter";
import moment from "moment";
import { DeleteOutlined } from "@mui/icons-material";
import { common } from "@mui/material/colors";
const Log = ({ data }) => {
  const [logData, setLogData] = useState([]);

  useEffect(() => {
    getData()
   
  }, []);

  const getData=async()=>{
    const documentLink = masterAPI["assetLogs"].getById;
    console.log(data);
   await axiosPrivate
      .get(`${documentLink}?assetid=${data}`)
      .then((res) => {
        console.log(res.data.result);
        setLogData(res.data.result);
      })
      .catch((e) => {
        // setLogData([])
        console.log(e, "assetDocumentError");
      });
    }

    const handleDelete = async(id)=>{
      const deletelink = masterAPI["assetLogs"].delete;
      await axiosPrivate
      .delete(`${deletelink}/${id}`)
      .then(()=>{
        getData()
        //toast.success("Successfully deleted!")
      })
      .catch((e)=>{
        console.log(e, "errorindelete");
      });
    }
  //   const data1=[
  //   {
  //     createdDate:"22-3-2023",
  //     statusName:"Not Active",
  //     modifiedByUser:"Mo1",
  //     Detail:"----"
  //   },
  //   {
  //     createdDate:"22-3-2023",
  //     statusName:"Active",
  //     modifiedByUser:"Mo2",
  //     Detail:"----"
  //   }
  //  ]
  //   setLogData(data1)
  // }
  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toLocaleString('en-US', {
  //     year: 'numeric',
  //     month: 'long',
  //     day: 'numeric',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     hour12: true,
  //   });
  // };

const confirmDelete = (id) => {
  toast(
    <Grid>
      <Typography sx={{fontSize:"15px",fontWeight:600, color:"#797979"}}>Are you sure you want to delete?</Typography>
      <Grid item container spacing={2}>
        <Grid item>
          <Button
            onClick={() => {
              handleDelete(id);
              toast.dismiss();
            }}
            sx={{ color: "grey", borderColor: "green" }}
            size="small"
            variant="outlined"
          >
            Yes
          </Button>
        </Grid>
        <Grid item >
          <Button
            onClick={() => toast.dismiss()}
            sx={{ color: "grey", borderColor: "red" }}
            size="small"
            variant="outlined"
          >
            No
          </Button>
        </Grid>
      </Grid>
    </Grid>,
    {
      closeButton: false,
      autoClose: false,
    }
  );
};
  const columns = [
    // {
    //   Header: "Doc ID",
    //   accessor: "id",
    //   Cell: ({ cell }) => {
    //     return (
    //       <Typography
    //         sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue" }}
    //       >
    //         {cell.value}
    //       </Typography>
    //     );
    //   },
    // },
    {
      Header: "Log Type",
      accessor: "isCreateOrUpdate",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "inherit", fontWeight: "inherit",color: cell.value=== true ? "green":"#FFA500"  }}>
            {cell.value ? "Created" : "Updated"}
          </Typography>
        );
      },
    },
    {
      Header: "Date",
      accessor: "createdDate",
      Cell: ({ value }) => moment(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      Header: "Location",
      accessor: "locationName",
    },
    {
      Header: "Asset Type",
      accessor: "assetTypeName",
    },
    {
      Header: "Category",
      accessor: "categoryName",
    },
    {
        Header: "Status",
        accessor: "statusName",
        Cell: ({ cell }) => {
            return (
              <Typography sx={{ fontSize: "inherit", fontWeight: "inherit",color: cell.value==="Active"?"green":"red"  }}>
                {cell.value}
              </Typography>
            );
          },
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ cell }) => (
          <IconButton onClick={() => confirmDelete(cell.value)}>
            <DeleteOutlined sx={{ color: "grey" }} />
          </IconButton>
        ),
      },
      ];
 
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
    <Box>
      <Typography
        sx={{
          color: color.sideTitleColor,
          fontSize: color.fontSizeSide,
          fontWeight: color.sideWeightFont,
        }}
      >
        Log
      </Typography>
    </Box>
    <Paper sx={{p:1}} >
      
    <Typography
            sx={{
              color: color.sideTitle2Color,
              fontSize: color.fontSizeSide,
              fontWeight: color.sideFontWeight,
            }}
          >
            Activity Log
          </Typography>
          <Divider sx={{m:"0.5rem 0 0.5rem 0"}} />
      <NormalTable columns={columns} data={logData} />
    </Paper>
    </Box>
  );
};

export default Log;
