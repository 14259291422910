import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Grid, Button, Dialog, Divider, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import NormalTable from "../../Component/NormalTable";
import { color } from "../../StyleData/Config";

const MTBFChart = ({ dashBoardDataXaxis, dashBoardDataYaxis, type, mtbfwoList }) => {
  const [chartDialog, setChartDialog] = useState({ openComWOList: false, selectedCode: [], assetCode: "" });
  const filteredData = mtbfwoList ? mtbfwoList : [];

  console.log('mtbfwoList:', mtbfwoList);  // Debugging line: check if mtbfwoList is passed correctly
  const updatedData = filteredData.map((dataItem, index) => ({
    ...dataItem,  
    code: chartDialog.selectedCode[index] || '',  
  }));
  const columns = [
    {
      Header: "WO Code",
      accessor: "code",  // Keep this as the accessor for the code column
      Cell: ({ cell }) => {
        const codeMatch = cell.value.match(/Code:\s*([\w-]+)\s*- ID:\s*([a-f0-9-]+)\s*Name:\s*(.*)/);
        const displayCode = codeMatch ? codeMatch[1] : '';  // Code part
        const assetId = codeMatch ? codeMatch[2] : '';      // ID part
        const workOrderName = codeMatch ? codeMatch[3] : ''; // Name part
        const combinedCode = workOrderName ? `${displayCode}` : displayCode;
  
        return (
          <Link
            to={`/redirectwo?id=${assetId}`}  // Pass only the ID in the URL
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
             target="_blank"
            rel="noopener noreferrer"
          >
            {combinedCode} 
          </Link>
        );
      },
    },
    {
      Header: "WO Name",
      accessor: "workOrderName",  
      Cell: ({ cell }) => {
        const workOrderName = cell.row.original.code.match(/Code:\s*([\w-]+)\s*- ID:\s*([a-f0-9-]+)\s*Name:\s*(.*)/)?.[3] || '';
        return workOrderName && workOrderName !== 'NULL' ? (
          <>{workOrderName}</>
        ) : null;
      },
    },
  ];
  
  
  
  const handleChartDialogOpen = (assetCode) => {
    console.log('handleChartDialogOpen triggered with assetCode:', assetCode);
    if (!mtbfwoList || !assetCode) {
      console.error("mtbfwoList or assetCode is undefined")
      return;  
    }
    const selectedAsset = mtbfwoList.find(item => item.assetCode === assetCode);

    if (selectedAsset) {
      const codes = selectedAsset.code.split(',').map(code => code.trim());
      setChartDialog({ openComWOList: true, selectedCode: codes, assetCode: assetCode });
    } else {
      console.error(`Asset with assetCode ${assetCode} not found in mtbfwoList`);
    }
  };

  const handleChartDialogClose = () => {
    setChartDialog({ openComWOList: false, selectedCode: [], assetCode: ""  });
  };

  return (
    <>
 <Chart
  onClick={(e, chartContext, config) => {
    const dataPointIndex = config?.dataPointIndex;
    const seriesIndex = config?.seriesIndex;
    // Check if dataPointIndex and seriesIndex are defined
    if (dataPointIndex !== undefined && seriesIndex !== undefined) {
      const assetCode = dashBoardDataYaxis[dataPointIndex];  // Access asset code based on the clicked data point
      console.log('Chart clicked with assetCode:', assetCode);  // Debugging line
      handleChartDialogOpen(assetCode);  // Pass assetCode to the handler
    } else {
      console.error('dataPointIndex or seriesIndex is undefined.');
    }
  }}
  height={type ? (dashBoardDataXaxis.length ? `${32 * dashBoardDataXaxis.length + 200}px` : "100%") : 200}
  width={"100%"}
  options={{
    responsive: [
      {
        breakpoint: 768, 
        options: {
          chart: {
            width: 600,
          },
        },
      },
    ],
    series: [{ data: dashBoardDataXaxis }],
    title: {
      text: "Mean Time Before Failure",
      style: {
        fontFamily: "Nunito Sans, sans-serif",
        color: "#2362B4",
      },
    },
    chart: {
      type: "bar",
      toolbar: {
        export: {
          csv: { filename: "MTBF" },
          svg: { filename: "MTBF" },
          png: { filename: "MTBF" },
        },
      },
      events: {
        dataPointSelection: function(event, chartContext, config) {
          const dataPointIndex = config?.dataPointIndex;
          const seriesIndex = config?.seriesIndex;

          if (dataPointIndex !== undefined && seriesIndex !== undefined) {
            const assetCode = dashBoardDataYaxis[dataPointIndex];
            console.log('DataPoint selected:', assetCode);  
            handleChartDialogOpen(assetCode);
          }
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: { enabled: false },
    yaxis: { title: { text: "Asset" } },
    xaxis: {
      title: { text: "Hours", style: { fontSize: '11px' } },
      categories: dashBoardDataYaxis,  
    },
  }}
  type="bar"
  series={[{ data: dashBoardDataXaxis }]}
/>




      <Dialog open={chartDialog.openComWOList} onClose={handleChartDialogClose}>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <Typography sx={{ color: color.masterPageTitleColor, fontSize: color.masterPageTitleFontSize, fontWeight: color.masterPageTitleFontWeight, mb: color.masterPageTitlemd, px: 2, py: 1 }}>
                Mean Time Before Failure Work Orders
              </Typography>
              <Typography sx={{ color: color.masterPageTitleColor, fontSize: color.masterPageTitleFontSize, fontWeight: color.masterPageTitleFontWeight, mb: color.masterPageTitlemd, px: 2, py: 1 }}>
              Asset Code: <span style={{ color: color.masterDialogTitleColor }}>{chartDialog.assetCode}</span>
              </Typography>
            </Box>
            <Box sx={{ px: 2, py: 1 }}>
              <NormalTable data={updatedData} columns={columns} filt={true} />
            </Box>
          </Grid>
  
          <Grid container item justifyContent={"flex-end"}>
            <Button onClick={handleChartDialogClose}>Close</Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};


export default MTBFChart