import axios, { AxiosRequestConfig } from "axios";
import { store } from "../Pages/ReduxStore/store";
import {toast} from "react-toastify"

 //export const baseURL="https://eamapi.s2tsoft.com/api"
// export const imageBaseURl="https://brommawebapp.ddns.net:8443/ojtapi/"
export const baseURL="https://demoapi.orienseam.com/api"
//export const baseURL="https://localhost:7182/api"

// export const siteInfo={
//   siteId:"2b143042-85e1-4edb-be93-ddfc87905275",
//   name:"Shah Alam"
// }

export const siteInfo={
  siteId:"e1bbe690-1ac0-41e4-9105-5e4449214082",
  name:"Combined Cycle Site"
}


export const axiosPrivate = axios.create({
  baseURL:baseURL,
});

const excludedEndpoints = [
  "/kpireport/getmtbfreport",
  "/kpireport/getmttrreport",
  "/kpireport/getcomplaiancechart",
  "/kpireport/getcompliancereportbystatus",
  "/kpireport/getservicerequestreport",
  "/kpireport/getworkordercostreport",
  "/kpireport/failurereportforfailures",
  "/kpireport/failurereportforproblem",
  "/kpireport/failurereportforcauses",
  "/kpireport/failurereportforremedy",
  "/kpireport/getkpichartlocation",
  "/workorder/searchcloseworkorder",
  "/kpireport/getcompliancereportbystatuswolist",
  "/kpireport/getcostreportforwolist",
  "/kpireport/getKPIReportComplaianceWOList",
  "/kpireport/getservicerequestreportlist",
  "/kpireport/getmtbfreportwoList",
  "/kpireport/getmttrreportwoList",
  "/kpireport/failurereportforfailuresWOList",
  "/kpireport/failurereportforproblemWOList",
  "/kpireport/failurereportforcausesWOList",
  "/kpireport/failurereportforremedyWOList",
  "/workorder/getiotdata",
  "/inventory/search",
];

axiosPrivate.interceptors.response.use(
  (response) => {
const method = response.config.method;
const url = response.config.url;
    // Check if API is in the excluded list
    const isExcluded = excludedEndpoints.some((endpoint) => url.includes(endpoint));

    if (!isExcluded) {
      if(method === "post" && url === "users/signin"){
        toast.success("Successfully logged in!");
      } else if (method === "post") {
        toast.success("Successfully created!");
      } else if (method === "put") {
        toast.success("Successfully updated!");
      } else if (method === "delete") {
        toast.success("Successfully deleted!");
      }
    }

    return response;
  // (error) => {
  //   toast.error(error.response?.data?.message || "Something went wrong!");
  //   return Promise.reject(error);
  // }
}
);
  



axiosPrivate.interceptors.request.use(
  (config)=> {
    const storeData = store.getState();
    const  tokenData=storeData.auth.loginInfo.accessToken
    if(tokenData!==undefined){
      config.headers = config.headers || {};
      config.headers["Authorization"] =tokenData ;
    }
    return config;
  }
);

// axiosPrivate.interceptors.response.use(async function(config){
   
//   return config;
// },(error) => {
//   // console.log(error.response, "errrr");
//   return new Promise( (resolve) => {

//     const originalRequest = error.config;
//     // console.log("checkkk");
//     const refreshToken = store.getState().auth.loginInfo.refreshToken;
   
//     if (error.response && error.response.data.StatusCode === 401 && refreshToken) {
//       // console.log("res");
//       originalRequest._retry = true;

//       // body: formBody
//       console.log(refreshToken)
//       const response =  axios
//         .post(`${baseURL}/users/refreshtoken?refreshtoken=${refreshToken}`)
//         .then((res) => {
//           console.log(res, "ccccc");
//           return res;
//         })
//         .then((res) => {
//           // console.log(res)
//           store.dispatch({type:"updateAccessToken",payload:res.data.result});
//           originalRequest.headers.authorization = res.data.result.accessToken;
//           return axios(originalRequest);
//         })
//         .catch((e) => {
//           if(e.response.data.StatusCode === 401){
//             store.dispatch({type:"logout",payload:{}});
//             console.log(e)
//             toast.warning("Session Expired")
//             // window.location.href = '/'
//             //  throw e
            
            
//           }
//           return Promise.reject(e);
//         });
//       resolve(response);
//     }
//     // else{
//     //   throw error;
//     // }

//     if (error.response) {
//       throw error.response.data;
//     }

//     return Promise.reject(error);
//   });
// })




let isRefreshing = false;
let refreshQueue = [];
let unauthorizedUrls = [];
axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const storeData = store.getState();
    const refreshToken = storeData.auth.loginInfo.refreshToken;

    if (error.response && error.response.status === 401) {


      // Store the 401 URL in the array
      if (originalRequest?.url) {
        unauthorizedUrls.push(originalRequest.url);
      }

      // Optionally log all 401 URLs for debugging
      console.log("all401url:", unauthorizedUrls);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      refreshToken
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          refreshQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return axiosPrivate(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        axios.interceptors.request.use(request => {
          console.log('Request Headers:', request.headers);
          return request;
        });
        
        const res = await axios.post(
          `${baseURL}/users/refreshtoken?refreshtoken=${refreshToken}`, 
        );
        
        console.log('Response Data:', res.data);
        
        const newAccessToken = res.data.result.accessToken;

        // Update the store with new tokens
        store.dispatch({
          type: "updateAccessToken",
          payload: res.data.result
      });
        console.log("token",res.data.result.accessToken)
        console.log("Updated Token:", store.getState().auth.loginInfo.accessToken);

        // Update the default headers and retry the original request
        axiosPrivate.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
        refreshQueue.forEach((prom) => prom.resolve(newAccessToken));
        refreshQueue = [];
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axiosPrivate(originalRequest);
      } catch (err) {
        // Handle refresh token expiration or errors
        if(err.response.data.StatusCode === 401){
          store.dispatch({type:"logout",payload:{}}); // Use the action creator
          toast.warning("Session Expired");
        }
        
        refreshQueue.forEach((prom) => prom.reject(err));
        refreshQueue = [];
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    if (error.response) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);


