import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import {
  AccountTree,
  BorderColor,
  BorderColorTwoTone,
  Circle,
  Clear,
  Edit,
  MoreHoriz,
  Save,
  SaveOutlined,
} from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Loading from "../../../Component/Loading"; 

import product_image from "../../../images/product2.jpg"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import moment from "moment";
import LocationTreeView from "../../Asset/Assets/locationTreeView";
import LineChart from "../../KPIReport/LineChart";
import InspView from "./InspView";
import ListMenuINS from "./InsScheduleSideMenu/ListMenuINS";

const InsDetails = ({assetExpl}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dotBL, setDotBL] = useState(null);
  const [value, setValue] = useState("1");
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [locationDialog, setLocationDialog] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [location, setLocation] = useState("");
  const [installationDateValue, setInstallationDateValue] = useState("");

  const [assetCategory, setAssetCategory] = useState([]);
  const [assetType, setAssetType] = useState([]);

  const [failureClasses, setFailureClasses] = useState([]);
  const [assetPriority, setAssetPriority] = useState([]);
  const [assetCriticality, setAssetCriticality] = useState([]);
  const [parentAsset, setParentAsset] = useState([]);
  const [suppiler,setSuppiler]=useState([])
  const [manufacturer,setManufacturer]=useState([])
  const [assetImage,setAssetImage]=useState(null)

  const [direction, setDirection] = useState("row");

  useEffect(() => {
    setIsLoading(true);
    insGetData();
    //getData();
//     if (edit) {
//       const Critlink = masterAPI["selectList"].criticalitybyType;
//       axiosPrivate
//         .get(`${Critlink}/Asset`)
//         .then((res) => {
//           setAssetCriticality(res.data);
//         })
//         .catch((e) => {
//           setAssetCategory([])
//           console.log(e, "createAcritigetAll");
//         });
//       /////////Category//////
//       const assetCat = masterAPI["assetCategory"].link;
//       axiosPrivate
//         .get(`${assetCat}`)
//         .then((res) => {
//           setAssetCategory(res.data.result);
//         })
//         .catch((e) => {
//           setAs
//           console.log(e, "createACgetAll");
//         });
//       //////Asset Type/////
//       const assetTyp = masterAPI["assetType"].link;
//       axiosPrivate
//         .get(`${assetTyp}`)
//         .then((res) => {
//           setAssetType(res.data.result);
//         })
//         .catch((e) => {
//           console.log(e, "createAssetTypegetAll");
//         });
//       const failClass = masterAPI["failureClass"].link;
//       axiosPrivate
//         .get(`${failClass}`)
//         .then((res) => {
//           setFailureClasses(res.data.result);
//         })
//         .catch((e) => {
//           console.log(e, "createfailureClassgetAll");
//         });
//       /////////asset Priority//////////
//       const assetPri = masterAPI["priority"].link;
//       axiosPrivate
//         .get(`${assetPri}`)
//         .then((res) => {
//           setAssetPriority(res.data.result);
//         })
//         .catch((e) => {
//           console.log(e, "createAssetPrigetAll");
//         });

//       const assets = masterAPI["assets"].link;
    
//       axiosPrivate
//         .get(`${assets}`)
//         .then((res) => {
//           setParentAsset(res.data.result);
//         })
//         .catch((e) => {
//           console.log(e, "createParentAssetgetAll");
//         });

// const typeLink=masterAPI["selectList"].businessByType       

//         axiosPrivate.get(`${typeLink}?businessType=Supplier`).then(res=>{
//           console.log(res)
//             setSuppiler(res.data)
//         }).catch(e=>{
//           console.log(e,"error")
//         })

//         axiosPrivate.get(`${typeLink}?businessType=Manufacturer`).then(res=>{
//           setManufacturer(res.data)
//       }).catch(e=>{
//         console.log(e,"error")
//       })
//     }
  }, [edit,assetExpl?.assetId]);

  const getData = async () => {
    const id = assetExpl?.page?assetExpl?.assetId:searchParams.get("id");
    const link = masterAPI["assets"].getById;
    console.log(id, link);
   await axiosPrivate
      .get(`${link}/${id}`)
      .then((res) => {
        console.log(res);
        setData(res.data.result);
        setLocation(res.data.result.locationName)
        setLocationId(res.data.result.locationId)
        setInstallationDateValue(res.data.result.installationDate);
        
        if(res.data.result.assetImage.split("/")[3]!==""){
          console.log(res.data.result.assetImage)
          setAssetImage(res.data.result.assetImage)
          // console.log(res.data.result.assetImage.split("Documents"))
          // setAssetImage(`https://demo.orienseam.com/api/documents/${res.data.result.assetImage.split("Documents")[1]}`)
        }else{
          setAssetImage(product_image)
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setData([])
        setLocation("")
        setLocationId("")
        setInstallationDateValue("")
        setAssetImage("")
        console.log(e, "assetDetailsError");
      });
  };
  const insGetData=async()=>{
    setIsLoading(true);
    const inspectionById=masterAPI["inspSchedule"].getByid
   await axiosPrivate.get(`${inspectionById}/${searchParams.get("id")}`).then(res=>{
      setData(res.data.result)
    }).catch(e=>{
      console.log(e)
      setData([])
    })
    setIsLoading(false);
  }
  const contentPosition = (flex) => {
    setDirection(flex);
  };

  const handleDot = (event) => {
    if (!dotBL) {
      setDotBL(event.currentTarget);
    } else {
      setDotBL(null);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    setEdit(!edit);
  };

  const locationHandle = () => {
    setLocationDialog(true);
  };

  const handlelocationClick = (name, id) => {
    if (
      name !== undefined &&
      name !== null &&
      id !== undefined &&
      id !== null
    ) {
      setLocation(name);
      setLocationId(id);
    }

    setLocationDialog(false);
  };
  const handleCreateClose = () => {
    setLocationDialog(false);
  };

  const formik = useFormik({
    initialValues: {
      code:data.code,
      assetName: data.assetName,
      assetTypeId: data.assetTypeId,
      priorityId: data.priorityId,
      criticalityId: data.criticalityId,
      parentAssetId: data.parentAssetId,
      failureClassesId: data.failureClassesId,
      locationId: location,
      assetCategoryId: data.assetCategoryId,
      serialNumber: data.serialNumber,
      model: data.model,
      purchasePrice: data.purchasePrice,
      installationDate: installationDateValue,
      supplierId: data.supplierId,
      manufacturerId: data.manufacturerId,
    },
    // initialValues:{check:data.assetTypeName},
    enableReinitialize: true,
    onSubmit:async (values) => {
      // alert(JSON.stringify(values, null, 2));
      const updateLink = masterAPI["assets"].update;
      const updateValue = {
        ...values,
        id: data.id,
        ["locationId"]: locationId,
      };
      await axiosPrivate
        .put(`${updateLink}`, updateValue)
        .then((res) => {
          console.log(res.data);
          // setLoading(false)
          setLocation("");
          setLocationId("");
          setEdit(false);
          // handleCloseClick();
        })
        .catch((e) => {
          // setLoading(false)
          console.log(e, "updateAssets");
        });
    },
  });
  return (
    <Box>
      {/* <Box sx={{width: {xs:direction==="column"?"50%":"100%",lg:direction==="column"?"50%":"100%"}}}> */}
      <Grid container spacing={"1rem"}>
        <Grid
          item
          xs={assetExpl?.page?12:direction === "column" ? 6 : 9.5}
          md={assetExpl?.page?12:direction === "column" ? 6 : 10.5}
          lg={assetExpl?.page?12:direction === "column" ? 6 : 11}
        >
          <Box
            sx={{
              display: "flex",
              width: "95%",
              justifyContent: "space-between",
              p: 1,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: color.AssetTitlesize,
                fontWeight: color.sideFontWeightAsset,
              }}
            >
            Inspection Schedule: {data.code}: {data.name}
            </Typography>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Box
                sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
              >
                {/* <Circle sx={{ fontSize: "0.5rem", color: "green" }} /> */}
                {/* <Typography>{data.statusName}</Typography> */}
              </Box>
              {/* <MoreHoriz onClick={handleDot} sx={{ cursor: "pointer" }} />
              <Menu anchorEl={dotBL} open={Boolean(dotBL)} onClose={handleDot}>
                <MenuItem>Option 1</MenuItem>
                <MenuItem>Option 2</MenuItem>
                <MenuItem>Option 3</MenuItem>
              </Menu> */}
              <Box></Box>
            </Box>
          </Box>
          {loading ? (
            <Loading />
          ) : (
            <TabContext value={value}>
              <Box
                sx={{
                  ".css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                    color: color.TabLabelcolor,
                    // p: 0.5,
                  },
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{
                    sx: { backgroundColor: color.TabLabelcolor, height: "3px" },
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    label="General"
                    value="1"
                    sx={{ textTransform: "none" }}
                  />
                  {/* <Tab
                    label="Parts/BOM"
                    value="2"
                    sx={{ textTransform: "none" }}
                  />
                       <Tab
                    label="Specification"
                    value="4"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab label="Work" value="3" sx={{ textTransform: "none" }} />
             
                  <Tab
                    label="Asset Health"
                    value="5"
                    sx={{ textTransform: "none" }}
                  /> */}
                </TabList>
               
              </Box>
              <TabPanel value="1" sx={{ p: 1 }}>
                {edit ? (
                  null
              
                ) : (
                  <InspView data={data} direction={assetExpl?.page?"column":direction} assetImage={assetImage} />
                )}
               </TabPanel>
               {/* <TabPanel value="2">
                <Parts data={data.id} />
              </TabPanel>
              <TabPanel value="3">
                <WorkTab data={data.id} />
              </TabPanel>
              <TabPanel value="4">
                <Specification data={data.id} direction={direction} />
              </TabPanel>
              <TabPanel value="5">
                <LineChart data={data}  />
              </TabPanel> */}
            </TabContext>
          )}
        </Grid>
        {/* </Box> */}
        {/* <Box sx={{width: {xs:direction==="column"?"50%":"15%",lg:direction==="column"?"50%":"fit-content"}}}> */}

        <Grid
          container
          justifyContent={"flex-end"}
          item
          xs={direction === "column" ? 6 : 2.5}
          md={direction === "column" ? 6 : 1.5}
          lg={direction === "column" ? 6 : 1}
          sx={{
            boxShadow:
              direction === "column" ? "-1px 1px 7px 2px rgb(0 0 0 / 32%)" : 0,
            minHeight: "92vh",
            backgroundColor: "transparent",
            display:assetExpl?.page?"none":"default"
          }}
        >
          <ListMenuINS
            contentPosition={contentPosition}
            data={data}
            direction={direction}
            assetExpl={assetExpl?.page}
            getData={insGetData}
          />
        </Grid>
        {/* </Box> */}
        <Dialog
          open={locationDialog}
          onClose={handleCreateClose}
          children={
            <LocationTreeView
              handlelocationClick={(name, id) => {
                handlelocationClick(name, id);
              }}
              handleCreateClose={handleCreateClose}
            />
          }
        />
      </Grid>
    </Box>
  );
};

export default InsDetails;
