

import { Box, Dialog, Divider, Grid, Button, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import NormalTable from "../../../Component/NormalTable";
import { color } from "../../../StyleData/Config";
import { DeleteOutline, LibraryAdd } from "@mui/icons-material";
import PartTechSpecMulti from "./PartTechSpecMulti";
import { toast } from "react-toastify";

const PartSpecification = ({ data,direction }) => {
  const [techData, setTechData] = useState([]);
  const [attrData, setAttrData] = useState([]);
  const [add,setAdd]=useState(false)


  useEffect(() => {
   getData()

  }, []);

  const getData=async()=>{
    const assetId=masterAPI["parttechspec"].getByPartId
    console.log(data)
   await axiosPrivate.get(`${assetId}/${data}`).then(res=>{
        console.log(res.data)
        setTechData(res.data.result)
        // setAttrData(res.data.result.technicalAttributeList)
    }).catch(e=>{
        setTechData([])
        console.log(e,"parttechspec")
    })
  }

  const handleDelete = async(id) => {
    const deleteLink = masterAPI["parttechspec"].delete;
   await axiosPrivate
      .delete(`${deleteLink}/${id}`)
      .then((res) => {
        console.log(res.data);
      //  toast.success("Successfully deleted!")
        getData();
      })
      .catch((e) => {
        console.log(e, "parttechspec");
      });
  };

  const columns = [
    {
      Header: "Attributes Code",
      accessor: "technicalAttributes.code",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue" }}
          >
            {cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Attributes Name",
      accessor: "technicalAttributes.attributeName",
    },
    {
      Header: "UOM",
      accessor: "technicalAttributes.uomName",
    },
    {
      Header: "Attribute Values",
      accessor: "",
      Cell: ({ row }) => {
        const { attributeTextValue, attributeNumericValue, attributeDateValue } = row.original;
        const values = [];
  
        if (attributeTextValue !== " " && attributeTextValue !== "") values.push(attributeTextValue);
        if (attributeNumericValue !== 0) values.push(attributeNumericValue);
        if (attributeDateValue) values.push(attributeDateValue);
  
        return values.join(', ') || null;  // Join values with a comma or show 'N/A' if all are empty
      },
    },
    // {
    //   Header: "Attribute Text Value",
    //   accessor: "attributeTextValue",
    // },
    // {
    //     Header: "Attribute Numeric Value",
    //     accessor: "attributeNumericValue",
    // },
    // {
    //     Header: "Attribute Date Value",
    //     accessor: "attributeDateValue",
    // },
  ];
  const confirmDelete = (id) => {
    toast(
      <Grid>
        <Typography sx={{fontSize:"15px",fontWeight:600, color:"#797979"}}>Are you sure you want to delete?</Typography>
        <Grid item container spacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                handleDelete(id);
                toast.dismiss();
              }}
              sx={{ color: "grey", borderColor: "green" }}
              size="small"
              variant="outlined"
            >
              Yes
            </Button>
          </Grid>
          <Grid item >
            <Button
              onClick={() => toast.dismiss()}
              sx={{ color: "grey", borderColor: "red" }}
              size="small"
              variant="outlined"
            >
              No
            </Button>
          </Grid>
        </Grid>
      </Grid>,
      {
        closeButton: false,
        autoClose: false,
      }
    );
  };
  const columnsTech = [
    {
      Header: "Technical Code",
      accessor: "technicalSpecification.code",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue" ,cursor:"pointer"}}
            onClick={()=>{handleAttribute(cell.row.original.techSpecId)}}
          >
            {cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Technical Name",
      accessor: "technicalSpecification.techSpecName",
    },
    // {
    //   Header: "Actions",
    //   Cell: ({ cell }) => {
    //     // console.log(cell)
    //     return (
    //       <Box sx={{ whiteSpace: "nowrap" }}>
         
    //         <IconButton  onClick={() => {
    //               handleDelete(cell.row.original.id);
    //             }}>
    //           <DeleteOutline
               
    //             sx={{
    //               fontSize: color.masterEditDelButton,
    //               color: color.masterEditDelButtonColor,
    //             }}
    //           />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
    
     {
            Header: "Action",
            accessor: "id",
            Cell: ({ cell }) => (
              <IconButton onClick={() => confirmDelete(cell.value)}>
                <DeleteOutline sx={{ color: "grey" }} />
              </IconButton>
            ),
          },
  ];

  const handleAdd=()=>{
setAdd(true)
  }

  const handleClose=()=>{
    setAdd(false)
    getData()
  }

const handleAttribute=async(id)=>{
 const attrLink=masterAPI["technicalAttributes"].getByTechSpecId
await axiosPrivate.get(`${attrLink}/${id}`).then(res=>{
  console.log(res)
  setAttrData(res.data.result)
 }).catch(e=>{
  setAttrData([])
  console.log(e,"attr Specfication")
 })
}

  return (
    // <Box sx={{display:"flex",flexDirection:direction,gap:"1rem"}}>
    <Grid container spacing={"1rem"}>
      <Grid item xs={direction==="column"?12:4}>
    <Paper sx={{p:1}}>
      <Grid container>
        <Grid  item xs={12}>
          <Box sx={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:'center'}}>
            <Typography sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}>Specification</Typography>
           <Box sx={{display:"flex",alignItems:"center"}}>
    <IconButton onClick={handleAdd}>
        <LibraryAdd/>
    </IconButton>
    <Typography>Add</Typography>
    </Box>
          </Box>
          <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}}/>
        </Grid>
       
        <Grid item xs={12}>
      <NormalTable columns={columnsTech} data={techData} />
      </Grid>
      </Grid>
    </Paper>
    </Grid>
    <Grid item xs={direction==="column"?12:8}>
    <Paper sx={{p:1}} >
      <Grid container>
      <Grid  item xs={12}>
          <Box sx={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:'center',p:1}}>
            <Typography sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}>Attributes</Typography>
         
          </Box>
          <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}}/>
        </Grid>
        <Grid item xs={12}>
      <NormalTable columns={columns} data={attrData} />
      </Grid>
      </Grid>
    </Paper>
    </Grid>
    <Dialog
        open={add}
        onClose={handleClose}
       fullWidth
       maxWidth={"md"}
        children={
          <PartTechSpecMulti
            handleCloseClick={handleClose}
         
          />
        }
      />
    </Grid>
    // </Box>
  );
};

export default PartSpecification;
