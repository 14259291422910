import { useState } from 'react';
import Chart from "react-apexcharts";
import { Dialog, Button, Typography, Grid, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import NormalTable from "../../Component/NormalTable";  
import { color } from "../../StyleData/Config";

const FailureClassChart = ({ failureClassXaxis, failureClassYaxis, type, failurewoList,isDialogOpen }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFailureCode, setSelectedFailureCode] = useState('');
  const [workOrders, setWorkOrders] = useState([]);
console.log(isDialogOpen)
  // Handle chart click
  const handleChartClick = (e, chartContext, config) => {
    const dataPointIndex = config?.dataPointIndex;
    if (dataPointIndex !== undefined && isDialogOpen) {
      const failureCode = failureClassXaxis[dataPointIndex];
      setSelectedFailureCode(failureCode);
      // Find all work orders with the selected failure code
      const filteredWorkOrders = failurewoList.filter(item => item.failureCode === failureCode);
      setWorkOrders(filteredWorkOrders);
      setDialogOpen(true); // Open the dialog
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedFailureCode('');
    setWorkOrders([]);
  };

  const columns = [
    {
      Header: "WO Code",
      accessor: "workOrderCode",
      Cell: ({ cell }) => {
        return (
          <Link
            to={`/redirectwo?id=${cell.row.original.id}`}  
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
             target="_blank"
            rel="noopener noreferrer"
          >
            {cell.value} 
          </Link>
        );
      },
    },
    {
      Header: "WO Name",
      accessor: "workOrderName",  
    },
  ];

  return (
    <>
      <Chart
        height={type ? (failureClassYaxis.length ? `${32 * failureClassYaxis.length + 200}px` : "100%") : 200}
        width={"100%"}
        options={{
          responsive: [
            {
              breakpoint: 768,
              options: {
                chart: {
                  width: 600,
                },
              },
            },
          ],
          series: [
            {
              data: failureClassXaxis,
            },
          ],
          title: {
            text: "Failure Report",
            style: {
              fontFamily: "Nunito Sans, sans-serif",
              color: "#2362B4",
            },
          },
          chart: {
            type: "bar",
            toolbar: {
              export: {
                csv: { filename: "Failure" },
                svg: { filename: "Failure" },
                png: { filename: "Failure" },
              },
            },
            events: {
              dataPointSelection: handleChartClick, // Handle chart click event
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            title: {
              text: "No of Records",
            },
            labels: {
              show: type,
            },
          },
          xaxis: {
            title: {
              text: "Failure",
              style: {
                fontSize: '11px',
              },
            },
            categories: failureClassXaxis,
          },
        }}
        type="bar"
        series={[{ data: failureClassYaxis }]}
      />

      {/* Dialog to show work orders */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              {/* <Typography sx={{ color: '#2362B4', fontSize: '16px', fontWeight: 600, mb: 2, px: 2, py: 1 }}>
                Work Orders for Failure Code: <span style={{ color: '#0d6efd' }}>{selectedFailureCode}</span>
              </Typography> */}
              <Typography sx={{ color: color.masterPageTitleColor, fontSize: color.masterPageTitleFontSize, fontWeight: color.masterPageTitleFontWeight, mb: color.masterPageTitlemd, px: 2, py: 1 }}>
              Work Orders for Failure
                </Typography>
                 <Typography sx={{ color: color.masterPageTitleColor, fontSize: color.masterPageTitleFontSize, fontWeight: color.masterPageTitleFontWeight, mb: color.masterPageTitlemd, px: 2, py: 1 }}>
                              Failure Code: <span style={{ color: color.masterDialogTitleColor }}>{selectedFailureCode}</span>
                              </Typography>
              <Box sx={{ px: 2, py: 1 }}>
                {workOrders.length > 0 ? (
                  <NormalTable data={workOrders} columns={columns} filt={true} />
                ) : (
                  <Typography sx={{ color: 'grey', fontSize: '14px' }}>No work orders found for this failure code.</Typography>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid container item justifyContent={"flex-end"}>
            <Button onClick={handleDialogClose}>Close</Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default FailureClassChart;
