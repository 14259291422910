import React, { useEffect, useState } from "react";
import TableContainer from "../../../../Component/TableContainer";
import { Box, Checkbox, Dialog, IconButton, Switch, Typography } from "@mui/material";
import { Link, useSearchParams,useNavigate } from "react-router-dom";
import { masterAPI } from "../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import {
  ArrowBack,
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { color } from "../../../../StyleData/Config";
import {useDispatch, useSelector} from "react-redux"
import CreateAssetCategory from "./StockRoomAisleModal";

import StockRoomAisleModal from "./StockRoomAisleModal";

const StockRoomAisle = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const actionsAllow=useSelector(state=>state.dataRed.actionsAllow)
  
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [filterValue, setFilterValue] = useState("enableData");
  const [backUpData, setBackUpData] = useState([]);
  const code = searchParams.get("code")
  const name = searchParams.get("name")
  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["stockRoomAisle"].link;
    const id=searchParams.get("id")
   await axiosPrivate
      .get(`${link}/${id}`)
      .then((res) => {
        res.data.result.sort((a, b) => {
          return a.code.localeCompare(b.code, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        if (filterValue === "enableData") {
          const filterDataResult = res.data.result.filter(
            (fil) => fil.isActive
          );
          console.log(filterDataResult, "filterData");
          setData(filterDataResult);
          setBackUpData(res.data.result);
        } else {
          setData(res.data.result);
          setBackUpData(res.data.result);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "stockRoomAisle");
        setIsLoading(false);
        setData([])
        setBackUpData([])
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  // const handleDelete = (id) => {
  //   const deleteLink = masterAPI["stockRoomAisle"].delete;
  //   axiosPrivate
  //     .delete(`${deleteLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "delete stockRoomAisle");
  //     });
  // };

  const handleDelete = async(e, id) => {
    const enableLink = masterAPI["stockRoomAisle"].enable;
    const disableLink = masterAPI["stockRoomAisle"].disable;
    if (e.target.checked) {
     await axiosPrivate
        .put(`${enableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "enable stockroomaisle");
        });
    } else {
     await axiosPrivate
        .put(`${disableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "disable stockroomaisle");
        });
    }
  };

  const columns = [
    {
      accessor: "id",
      disableFilters: true,
      Cell: () => {
        return (
          <Box sx={{ width: color.checkBoxWidth }}>
            <Checkbox
              // icon={<RadioButtonUncheckedOutlined />}
              // checkedIcon={<CheckCircle />}
            />
          </Box>
        );
      },
    },
    {
      Header: "Aisle Code",
      accessor: "code",
      Cell: ({ cell }) => {
        return (
          <Link
            to={`/stockroomrow?id=${cell.row.original.id}&code=${cell.value}&name=${cell.row.original.aisleName}`}
            onClick={()=>{dispatch({type:"stockRoom",name:"aisle",payload:cell.row.original.id});
                          dispatch({ type: "stockRoom",name:"aisleCode",payload: cell.value });
                          dispatch({ type: "stockRoom",name:"aisleName",payload: cell.row.original.aisleName});  }}
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
          >
            {cell.value}
          </Link>
        );
      },
      // disableFilters: true,
    },
    {
      Header: "Aisle Name",
      accessor: "aisleName",
      // disableFilters: true,
    },
    {
      Header: "Stock Room",
      accessor: "stockRoomName",
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton
             disabled={!actionsAllow.edit}
              onClick={() => {
                handleEdit(cell.row.original);
              }}
            >
              <EditOutlined
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: actionsAllow.edit? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton>
            {/* <IconButton
             disabled={!actionsAllow.delete}
              onClick={() => {
                handleDelete(cell.row.original.id);
              }}
            >
              <DeleteOutline
                sx={{
                  fontSize: color.masterEditDelButton,
                  color:actionsAllow.delete? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton> */}
            <Switch
              disabled={!actionsAllow.delete}
              checked={cell.row.original.isActive}
              onChange={(e) => {
                handleDelete(e, cell.row.original.id);
              }}
            />
          </Box>
        );
      },
    },
  ];

  const handleChangeFilter = (e) => {
    if (e === "showAll") {
      setData(backUpData);
    } else {
      const filterDataResult = backUpData.filter((fil) => fil.isActive);

      setData(filterDataResult);
    }
    setFilterValue(e);
  };

  return (
    <>
        <Box sx={{display:"flex",alignItems:"center"}}>
        <IconButton onClick={()=>{navigate("/stockroom")}}>
          <ArrowBack/>
        </IconButton>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            // mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["stockRoomAisle"].title} - {code} - {name}
        </Typography>
      </Box>

      <TableContainer
        columns={columns}
        data={data}
        buttonNew={handleButtonNew}
        loading={isLoading}
        buttonDisable={!actionsAllow.create}
        filterValue={filterValue}
        handleChange={handleChangeFilter}    
        filterShow={true}
      />

      <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"md"}
        children={
          <StockRoomAisleModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      />
    </>
  );
};

export default StockRoomAisle;
