const Reducer = (
  state = {
    failureClass: "",
    problem: "",

    stockRoom: "",
    aisle: "",
    aisleCode: "",
    stockRoomCode:"",
    stockRoomName:"",
    aisleName:"",
    issueItem: {},
    receiptItem:{},
    returnItem:{},
    vendorItem:{},
    techspecInfo:{},
    part_stockroomDetail:{},
   
    /////authenticate menuList//////////////
    menuList: [],
    menuArray: [],
    actionsAllow: {},
    
  },
  actions
) => {
  switch (actions.type) {
    case "failureClass":
      return { ...state, [actions.name]: actions.payload };
    case "stockRoom":
      return { ...state, [actions.name]: actions.payload };
    case "menuList":
      return { ...state, menuList: actions.payload };
    case "menuArray":
      return { ...state, menuArray: actions.payload };
    case "actionsAllow":
      return { ...state, actionsAllow: actions.payload };
    case "storeDisplayListName":
      return {...state,[actions.payload.name]:actions.payload.data}
    case "techspecInfo":
      return {...state,techspecInfo:actions.payload}
    case "partStockRoom":
      return {...state,part_stockroomDetail:actions.payload}
    default:
      return state;
  }
};

export const dataReducer = Reducer;
