import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import {
  Box,
  Checkbox,
  Dialog,
  IconButton,
  Typography,
  Paper,
  Grid,
  Button,
  Select,
  MenuItem,
  Switch,
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import {toast} from "react-toastify"
import { color } from "../../../StyleData/Config";

import NormalTable from "../../../Component/NormalTable";

const InventoryCount = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [stockRoom,setStockRoom]=useState([])
  const [selectedSR,setSelectedSR]=useState({})
  const [parts,setParts]=useState([])
  const [partSelect,setPartSelect]=useState({
    id:[],
    // detail:[]
  })
  const [sel,setSel]=useState({
    autostockRoom:""
  })




  useEffect(() => {
    getData();
  }, []);

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const getData = async() => {
  
  const stockRoomLink=masterAPI["stockRoom"].link
 await axiosPrivate.get(`${stockRoomLink}`).then(res=>{
    console.log(res)
    setStockRoom(res.data.result)
  }).catch(e=>{
    console.log(e)
  })
    
  };

  const handleCheck = (e, menuValue) => {
   if(e.target.checked){
    setPartSelect({
      id:[...partSelect.id,menuValue.partId],
      // detail:[...partSelect.detail,menuValue]
    })
   }else{
    const filterId=partSelect.id.filter(fil=>fil!==menuValue.partId)
    // const filterDetail=partSelect.detail.filter(fil=>fil.id!==menuValue.id)
    setPartSelect({
      id:filterId,
      // detail:filterDetail
    })
   }
    
  };

  const initialValue={
    safetyStockLevel: 0,
    economicReOrderQuantity: 0,
    reOrderPoint: 0,
    maxQuantity: 0,
    stockcategory: "STK",
    isReOrder: false
  }


  const columns = [
    {
      accessor: "partId",
      disableFilters: true,
      Cell: ({ cell }) => {
        return (
          <Box sx={{ width: color.checkBoxWidth }}>
            <Checkbox
              checked={partSelect.id.includes(cell.value)}
              onChange={(e) => {
                handleCheck(e, cell.row.original);
              }}
           
            />
          </Box>
        );
      },
    },
    {
      Header: "Part Code",
      accessor: "partCode",
    },

    {
      Header: "Part Name",
      accessor: "partName",
    }
  
  ];

  const onSubmit = async(value) => {
   const putData=[]
   for(let i=0;partSelect.id.length>i;i++){
    putData.push(
      {
        partId: partSelect.id[i],
        stockRoomId:selectedSR.id,
        safetyStockLevel: value.safetyStockLevel,
        economicReOrderQuantity: value.economicReOrderQuantity,
        reOrderPoint: value.reOrderPoint,
        maxQuantity: value.maxQuantity,
        stockcategory: value.stockcategory,
        isReOrder: value.isReOrder
      }
    )
   }
   const bulkUpate=masterAPI['inventory'].bulkupdate
  await axiosPrivate.put(`${bulkUpate}`,putData).then(res=>{
    console.log(res)
    toast.success("Successfully Updated")
    setPartSelect({
      id:[],
      // detail:[]
    })
    setSelectedSR({})
    setParts([])
    setSel({
      autostockRoom:""
    })
   }).catch(e=>{
    console.log(e)
    toast.error("Unable to update")
   })
  };
  const handleCancel = () => {
  setPartSelect({
    id:[],
    // detail:[]
  })
  setSelectedSR({})
  setParts([])
  setSel({
    autostockRoom:""
  })
  };

  const handleStockRoom=async(dataSR)=>{
    setSelectedSR(dataSR)
  const link=masterAPI["inventory"].getbystockroom
 await axiosPrivate.get(`${link}/${dataSR.id}`).then(res=>{
    console.log(res)
   setParts(res.data.result)
  }).catch(e=>{
    console.log(e)
    setParts([])
  })
  }
 

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["inventory"].partStockLevelTitle}
        </Typography>
      </Box>
    
      <Paper sx={{ p: 1 }}>
        <Grid container spacing={"1rem"}>
   
          <Grid item xs={12}>
          <Formik initialValues={initialValue} onSubmit={onSubmit}>
      {({ handleChange, setFieldValue, values , resetForm }) => (
        <Form>
          <Grid container spacing={"1rem"}>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Safety Stock Level
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="safetyStockLevel"
                placeholder="Enter Safety Stock Level"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Economic Reorder Quantity
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="economicReOrderQuantity"
                placeholder="Enter Economic Reorder Quantity"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Reorder Point
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="reOrderPoint"
                placeholder="Enter Reorder Point"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Max Quantity
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="maxQuantity"
                placeholder="Enter Max Quantity"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Stock Category
                </Typography>
                
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="stockcategory"
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.stockcategory !== null ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
               
               
                  <MenuItem value={"STK"} >
                    STK
                    </MenuItem>
                    <MenuItem value={"NSTK"} >
                    NSTK
                    </MenuItem>
               
              </Field>
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Is Reorder
                </Typography>
                
                <Switch
              
              checked={values.isReOrder}
              onChange={(e) => {
                setFieldValue("isReOrder",e.target.checked)
              }}
            />
            </Grid>

           
            <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
              <Button
                onClick={handleCancel}
                variant="contained"
                sx={{
                  backgroundColor: color.TableCancelButton,
                  textTransform: "none",
                  color: color.CancelTextColor,
                  ":hover":{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type={"submit"}
                variant="contained"
                
                sx={{
                      
                  textTransform: "none",
                  backgroundColor:color.TableButtonColor,
                  "&:hover": {
                    backgroundColor: color.buttonDisable,
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
      </Formik>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
              Stock Room
            </Typography>
            <Autocomplete
              
              inputValue={sel.autostockRoom}
            //   value={sel.roleDetail}
              fullWidth
              filterOptions={filterOptions}
              loading={stockRoom.length === 0}
              options={stockRoom}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.code+"-"+option.stockRoomName}
              freeSolo
              onChange={(event, value) => {
                console.log(value);
                if (value !== null) {
                handleStockRoom(value)
                 
                } else {
                  setSel({ ...sel, autostockRoom: "" });
                }
              }}
              onInputChange={(event, newInputValue) => {
                setSel({ ...sel, autostockRoom: newInputValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Stock Room"
                  //  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        {stockRoom.length === 0 ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <NormalTable data={parts} columns={columns} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default InventoryCount;
