import { useState } from 'react';
import Chart from "react-apexcharts";
import { Dialog, Button, Typography, Grid, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import NormalTable from "../../Component/NormalTable";  
import { color } from "../../StyleData/Config";

const ProblemChart = ({xAxis,yAxis,type, problemWolist, isDialogOpen}) => {
  console.log('problemWolist',problemWolist)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedProblemCode, setSelectedProblemCode] = useState('');
    const [workOrders, setWorkOrders] = useState([]);
   const handleChartClick = (e, chartContext, config) => {
      const dataPointIndex = config?.dataPointIndex;
      if (dataPointIndex !== undefined && isDialogOpen) {
        const problemCode = xAxis[dataPointIndex];
        setSelectedProblemCode(problemCode);
        // Find all work orders with the selected failure code
        const filteredWorkOrders = problemWolist.filter(item => item.problemCode === problemCode);
        setWorkOrders(filteredWorkOrders);
        setDialogOpen(true); // Open the dialog
      }
    };
  
    const handleDialogClose = () => {
      setDialogOpen(false);
      setSelectedProblemCode('');
      setWorkOrders([]);
    };
  
    const columns = [
      {
        Header: "WO Code",
        accessor: "workOrderCode",
        Cell: ({ cell }) => {
          return (
            <Link
              to={`/redirectwo?id=${cell.row.original.id}`}  
              style={{
                color: "#0d6efd",
                textDecorationLine: "underline",
                fontWeight: 800,
              }}
               target="_blank"
            rel="noopener noreferrer"
            >
              {cell.value} 
            </Link>
          );
        },
      },
      {
        Header: "WO Name",
        accessor: "workOrderName",  
      },
    ];
  
  return (
    <>
    <Chart
    height={type?
     ( yAxis.length
        ? `${32 * yAxis.length + 200}px`
        : "100%"):200
    }
    width={"100%"}
    options={{
      
      responsive: [
        {
          breakpoint: 768, // Change the breakpoint as needed
          options: {
            chart: {
              width: 600,
            },
          },
        },
      ],
      series: [
        {
          data: xAxis,
        },
      ],
      title: {
        text: "Problem Report",
        style: {
          fontFamily: "Nunito Sans, sans-serif",
          color: "#2362B4",
        },
      },

      chart: {
        type: "bar",
        toolbar: {
          export: {
            csv: {
              filename: "Problem",
            },
            svg: {
              filename: "Problem",
            },
            png: {
              filename: "Problem",
            },
          },
        },
        events: {
          dataPointSelection: handleChartClick, // Handle chart click event
        },
      },
    //   grid:{
    //     padding:{
    //         bottom:-10
    //     }
    //   },

      plotOptions: {
        bar: {

          borderRadius: 4,
          horizontal: false,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
                fontFamily: "Nunito Sans, sans-serif",
              },
            },
          },
        },
        noData: {
          text: "No Result",
          style: {
            fontSize: "1.5rem",
            color: "grey",
            fontFamily: "Nunito Sans, sans-serif",
          },
        },

        stroke: {
          width: 1,
          colors: ["#fff"],
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        title: {
          text: "No of Records",
          // style:{
          //  padding:10
          // }
        },
        labels:{
            show:type
        }
      },
      xaxis: {
        title: {
          text: "Problem",
          style: {
            fontSize: '11px' // Set the font size of the x-axis title
          }
        },
      //   labels:{
      //     show:type
      // },
        // labels:{
        //     show:false
        // },
        categories: xAxis,

        
      },
    }}
    type="bar"
    series={[{ data: yAxis }]}
  />
  <Dialog open={dialogOpen} onClose={handleDialogClose}>
  <Grid container>
    <Grid item xs={12}>
      <Box>
        {/* <Typography sx={{ color: '#2362B4', fontSize: '16px', fontWeight: 600, mb: 2, px: 2, py: 1 }}>
          Work Orders for Failure Code: <span style={{ color: '#0d6efd' }}>{selectedProblemCode}</span>
        </Typography> */}
        <Typography sx={{ color: color.masterPageTitleColor, fontSize: color.masterPageTitleFontSize, fontWeight: color.masterPageTitleFontWeight, mb: color.masterPageTitlemd, px: 2, py: 1 }}>
        Work Orders for Problem
          </Typography>
           <Typography sx={{ color: color.masterPageTitleColor, fontSize: color.masterPageTitleFontSize, fontWeight: color.masterPageTitleFontWeight, mb: color.masterPageTitlemd, px: 2, py: 1 }}>
                        Problem Code: <span style={{ color: color.masterDialogTitleColor }}>{selectedProblemCode}</span>
                        </Typography>
        <Box sx={{ px: 2, py: 1 }}>
          {workOrders.length > 0 ? (
            <NormalTable data={workOrders} columns={columns} filt={true} />
          ) : (
            <Typography sx={{ color: 'grey', fontSize: '14px' }}>No work orders found for this failure code.</Typography>
          )}
        </Box>
      </Box>
    </Grid>
    <Grid container item justifyContent={"flex-end"}>
      <Button onClick={handleDialogClose}>Close</Button>
    </Grid>
  </Grid>
</Dialog>
</>
  )
}

export default ProblemChart
