export const masterAPI = {
  assetCategory: {
    title: "Asset Category",
    link: "/assetcategory/getall",
    create: "/assetcategory/create",
    update: "/assetcategory/update",
    delete: "/assetcategory/delete",
    enable: "/assetcategory/enable",
    disable: "/assetcategory/disable",
  },
  criticality: {
    title: "Criticality",
    link: "/criticality/getall",
    create: "/criticality/create",
    update: "/criticality/update",
    delete: "/criticality/delete",
    enable: "/criticality/enable",
    disable: "/criticality/disable",
  },
  priority: {
    title: "Priority",
    link: "/priority/getall",
    create: "/priority/create",
    update: "/priority/update",
    delete: "/priority/delete",
    enable: "/priority/enable",
    disable: "/priority/disable",
  },
  assetStatus: {
    title: "Asset Status",
    link: "/assetstatus/getall",
    create: "/assetstatus/create",
    update: "/assetstatus/update",
    delete: "/assetstatus/delete",
    enable: "/assetstatus/enable",
    disable: "/assetstatus/disable",
  },
  assetType: {
    title: "Asset Type",
    link: "/assettype/getall",
    create: "/assettype/create",
    update: "/assettype/update",
    delete: "/assettype/delete",
    enable: "/assettype/enable",
    disable: "/assettype/disable",
  },
  warranty: {
    title: "Warranty",
    link: "/warranty/getall",
    create: "/warranty/create",
    update: "/warranty/update",
    delete: "/warranty/delete",
  },
  // assetWarranty: {
  //   title: "Asset Warranty",
  //   link: "/assetwarranty/getall",
  //   create: "/assetwarranty/create",
  //   update: "/assetwarranty/update",
  //   delete: "/assetwarranty/delete",
  // },
  locationType: {
    title: "Location Type",
    link: "/locationtype/getall",
    create: "/locationtype/create",
    update: "/locationtype/update",
    delete: "/locationtype/delete",
    enable: "/locationtype/enable",
    disable: "/locationtype/disable",
  },
  location: {
    title: "Location",
    link: "/location/getall",
    getbyid: "/location/getbyid",
    create: "/location/create",
    update: "/location/update",
    delete: "/location/delete",
    enable: "/location/enable",
    disable: "/location/disable",
  },
  technicalSpecification: {
    title: "Technical Specification",
    link: "/technicalspec/getall",
    create: "/technicalspec/create",
    update: "/technicalspec/update",
    delete: "/technicalspec/delete",
    assetTechSpec: "/technicalspec/getbyasset",
    enable: "/technicalspec/enable",
    disable: "/technicalspec/disable",
  },
  assetTechSpec: {
    create: "/assettechspec/create",
    delete: "/assettechspec/delete",
    getByAssetId: "/assettechspec/getbyassetid",
    bulkinsert: "/assettechspec/bulkinsert",
  },
  parttechspec: {
    create: "/parttechspec/create",
    delete: "/parttechspec/delete",
    getByPartId: "/parttechspec/getbypartid",
    bulkinsert: "/parttechspec/bulkinsert",
  },


  technicalAttributes: {
    title: "Technical Attributes",
    link: "/technicalattributes/getall",
    create: "/technicalattributes/create",
    update: "/technicalattributes/update",
    delete: "/technicalattributes/delete",
    getByTechSpecId: "/techspecattributes/getbytechspecid",
    enable: "/technicalattributes/enable",
    disable: "/technicalattributes/disable",
  },
  uom: {
    title: "UOM",
    link: "/uom/getall",
    create: "/uom/create",
    update: "/uom/update",
    delete: "/uom/delete",
    enable: "/uom/enable",
    disable: "/uom/disable",
  },
  business: {
    title: "Business",
    link: "/business/getall",
    create: "/business/create",
    update: "/business/update",
    delete: "/business/delete",
    enable: "/business/enable",
    disable: "/business/disable",
  },
  failureClass: {
    title: "Failure Class",
    link: "/failureclass/getall",
    create: "/failureclass/create",
    update: "/failureclass/update",
    delete: "/failureclass/delete",
    enable: "/failureclass/enable",
    disable: "/failureclass/disable",
  },
  failureCode: {
    title: "Failure Code",
    link: "/failurecode/getall",
    create: "/failurecode/create",
    update: "/failurecode/update",
    delete: "/failurecode/delete",
  },
  meterGroup: {
    title: "Meter Group",
    link: "/metergroup/getall",
    create: "/metergroup/create",
    update: "/metergroup/update",
    delete: "/metergroup/delete",
    getMGByMeter: "/metergroup/getbymeterid",
    enable: "/metergroup/enable",
    disable: "/metergroup/disable",
  },
  meter: {
    title: "Meter",
    link: "/meter/getall",
    create: "/meter/create",
    update: "/meter/update",
    delete: "/meter/delete",
    getMeterByAsset: "/assetsmeter/getmetersbyasset",
    enable: "/meter/enable",
    disable: "/meter/disable",
  },
  stockCategory: {
    title: "Stock Category",
    link: "/stockcategory/getall",
    create: "/stockcategory/create",
    update: "/stockcategory/update",
    delete: "/stockcategory/delete",
    enable: "/stockcategory/enable",
    disable: "/stockcategory/disable",
  },
  stockRoom: {
    title: "Stock Room",
    link: "/stockroom/getall",
    create: "/stockroom/create",
    update: "/stockroom/update",
    delete: "/stockroom/delete",
    enable: "/stockroom/enable",
    disable: "/stockroom/disable",
  },
  stockRoomAisleRow: {
    title: "Stock Room Aisle Rows",
    link: "/stockroomaislerows/getbyaisle",
    create: "/stockroomaislerows/create",
    update: "/stockroomaislerows/update",
    delete: "/stockroomaislerows/delete",
    enable: "/stockroomaislerows/enable",
    disable: "/stockroomaislerows/disable",
  },
  stockRoomRowBin: {
    title: "Stock Room Bins",
    link: "/stockroomrowbins/getbystockroomrow",
    create: "/stockroomrowbins/create",
    update: "/stockroomrowbins/update",
    delete: "/stockroomrowbins/delete",
    enable: "/stockroomrowbins/enable",
    disable: "/stockroomrowbins/disable",
  },
  stockRoomAisle: {
    title: "Stock Room Aisles",
    link: "/stockroomaisles/getbystockroom",
    create: "/stockroomaisles/create",
    update: "/stockroomaisles/update",
    delete: "/stockroomaisles/delete",
    enable: "/stockroomaisles/enable",
    disable: "/stockroomaisles/disable",
  },
  partsGroup: {
    title: "Part Group",
    link: "/partsgroup/getall",
    create: "/partsgroup/create",
    update: "/partsgroup/update",
    delete: "/partsgroup/delete",
    enable: "/partsgroup/enable",
    disable: "/partsgroup/disable",
  },
  labour: {
    title: "Labour",
    link: "/labours/getall",
    create: "/labours/create",
    update: "/labours/update",
    delete: "/labours/delete",
    enable: "/labours/enable",
    disable: "/labours/disable",
  },
  crafts: {
    title: "Crafts",
    link: "/crafts/getall",
    create: "/crafts/create",
    update: "/crafts/update",
    delete: "/crafts/delete",
    enable: "/crafts/enable",
    disable: "/crafts/disable",
  },
  labourCraft: {
    title: "Labour Craft",
    link: "/labourcraft/getall",
    create: "/labourcraft/create",
    update: "/labourcraft/update",
    delete: "/labourcraft/delete",
    enable: "/labourcraft/enable",
    disable: "/labourcraft/disable",
    craftBylabour: "/labourcraft/craftbylabourid",
  },
  person: {
    title: "Person",
    link: "/person/getall",
    create: "/person/create",
    update: "/person/update",
    delete: "/person/delete",
    getByid: "/person/getbyid",
    enable: "/person/enable",
    disable: "/person/disable",
  },
  roles: {
    title: "Roles",
    link: "/roles/getall",
    create: "/roles/create",
    update: "/roles/update",
    delete: "/roles/delete",
    enable: "/roles/enable",
    disable: "/roles/disable",
  },
  assets: {
    title: "Assets",
    link: "/assets/getall",
    create: "/assets/create",
    update: "/assets/update",
    delete: "/assets/delete",
    getById: "/assets/getbyid",
    treeView: "/assets/treeview",
    changeStatus: "/assets/changestatus",
    assetLogByid: "/assets/getlogsbyassetid",
    assetByLocation: "/assets/getassetsbylocation",
    enable: "/assets/enable",
    disable: "/assets/disable",
    getCost: "/assets/getltdcostid",
    getMTBF: "/assets/getassetmtbf",
    assetYTDCost: "/assets/getytdcostid",
    getAssetByLocAndAssettype: "/assets/getallassetbylocationandtype",
  },
  assetLogs: {
    title: "Asset Log",
    getById: "/assetlogs/getallbyassetid",
    delete: "/assetlogs/delete",
  },
  assetPart: {
    link: "/assetparts/getall",
    create: "/assetparts/create",
    update: "/assetparts/update",
    delete: "/assetparts/delete",
    assetParts: "/assetparts/partsbyassetid",
    bulkinsert: "/assetparts/bulkinsert",
  },
  menus: {
    title: "Menus",
    link: "/menus/getall",
    create: "/menus/create",
    update: "/menus/update",
    delete: "/menus/delete",
    enable: "/menus/enable",
    disable: "/menus/disable",
  },
  roleMenu: {
    title: "Role Menus",
    link: "/rolemenus/getall",
    create: "/rolemenus/create",
    update: "/rolemenus/update",
    delete: "/rolemenus/delete",
    bulkinsert: "/rolemenus/bulkinsert",
    getbyRoleId: "/rolemenus/getmenusbyroleid",
  },
  parts: {
    title: "Parts",
    link: "/parts/getall",
    create: "/parts/create",
    update: "/parts/update",
    delete: "/parts/delete",
    enable: "/parts/enable",
    disable: "/parts/disable",
    getById: "/parts/getById",
  },
  workOrder: {
    title: "Work Order",
    link: "/workorder/getall",
    create: "/workorder/create",
    update: "/workorder/update",
    delete: "/workorder/delete",
    search: "/workorder/search",
    getbyid: "/workorder/getbyid",
    getDocumentbyworkOrderId: "/workorder/getdocumentbyworkorderid",
    changeStatus: "/workorder/updatestatus",
    completeDetail: "/workorder/getcompletedetails",
    linkedRec: "/workorder/getlinkedrecords",
    uploadDoc: "/workorder/uploaddocument",
    deleteDoc: "/workorder/deletedocument",
    download: "/workorder/downloaddocument",
    enable: "/workorder/enable",
    disable: "/workorder/disable",
    getByServiceReqId: "/workorder/getbyservicereqid",
  },
  jobPlan: {
    title: "Job Plan",
    link: "/jobplan/getall",
    create: "/jobplan/create",
    update: "/jobplan/update",
    delete: "/jobplan/delete",
    getByid: "/jobplan/getbyid",
    completeDetail: "/jobplan/getcompletedetails",
    enable: "/jobplan/enable",
    disable: "/jobplan/disable",
  },
  selectList: {
    assetStatus: "/selectlist/assetsstatuses",
    jobPlanStatus: "/selectlist/jobplanstatus",
    jobPriority: "/selectlist/jobpriority",
    taskTypes: "/selectlist/tasktypes",
    failureCodeType: "/selectlist/failurecodetypes",
    woStatus: "/selectlist/wostatus",
    pmStatus: "/selectlist/pmstatus",
    pmJobType: "/selectlist/pmjobtype",
    frequencyunitsfortime: "/selectlist/frequencyunitsfortime",
    frequencyunitsformeter: "/selectlist/frequencyunitsformeter",
    workordertype: "/selectlist/worktype",
    problem: "/selectlist/problems",
    documentType: "/selectlist/documenttypes",
    documentcategories: "selectlist/documentcategories",
    causes: "/selectlist/causes",
    remedy: "/selectlist/remedy",
    pmScheduleType: "/selectlist/pmscheduletypes",
    technicalSpecificationType: "/selectlist/technicalspecificationtypes",
    stockRoom: "/selectlist/stockroom",
    stockRoomAisle: "/selectlist/stockroomaisle",
    stockRoomAisleRow: "/selectlist/stockroomrows",
    stockRoomAisleRowBin: "/selectlist/stockroomrowbins",
    stockRoomByPart: "/selectlist/stockroombypart",
    aisleByPartAndRoom: "/selectlist/aislesbypartandroom",
    rowByPartAndAisle: "/selectlist/rowsbypartandaisle",
    binByPartAndRow: "/selectlist/binsbypartandrow",
    techspecbyType: "/selectlist/techspecsbytype",
    businessType: "/selectlist/businesstype",
    businessByType: "/selectlist/businessbytype",
    inventoryusageStatus: "/selectlist/inventoryusagestatus",
    criticalitybyType: "/selectlist/criticalities",
    serviceRequestStatus: "/selectlist/servicerequeststatus",
    uomCategory: "/selectlist/uomcategory",
    uomByCat: "/selectlist/uombycategory",
    questionType: "/selectlist/questiontype",
    partsInventory: "/selectlist/partinventoryparts",
  },
  assetDocument: {
    getDocumentbyassetId: "/assetdocument/getbyassetid",
    uploadDoc: "/assetdocument/upload",
    delete: "/assetdocument/delete",
    download: "/assetdocument/download",
  },
  assetMovement: {
    create: "/assetmovement/create",
  },
  user: {
    signin: "users/signin",
    signout: "users/signout",
    getUserBySite: "/users/getusersbysite",
  },
  jobPlanTask: {
    getByJobPlanId: "/jobplantask/getall",
    create: "/jobplantask/create",
    delete: "/jobplantask/delete",
    update: "/jobplantask/update",
  },
  workOrderTask: {
    getByWorkOrderId: "/workordertask/getall",
    create: "/workordertask/create",
    update: "/workordertask/update",
    delete: "/workordertask/delete",
    bulkinsert: "/workordertask/bulkinsert",
    bulkupdate: "/workordertask/bulkstatusupdate",
    upload: "/workordertask/uploaddocument",
  },
  workOrderTaskLabour: {
    create: "/workordertasklabour/create",
    bulkinsert: "/workordertasklabour/bulkinsert",
    update: "/workordertasklabour/update",
    delete: "/workordertasklabour/delete",
    updateplannedHrs: "/workordertasklabour/updatehrsandcost",
  },
  jobPlanTaskLabour: {
    create: "/jobplantasklabour/create",
    bulkinsert: "/jobplantasklabour/bulkinsert",
    update: "/jobplantasklabour/update",
    delete: "/jobplantasklabour/delete",
  },
  workOrderTaskPart: {
    create: "/workordertaskparts/create",
    update: "/workordertaskparts/update",
    delete: "/workordertaskparts/delete",
    bulkinsert: "/workordertaskparts/bulkinsert",
  },
  jobPlanTaskParts: {
    create: "/jobplantaskparts/create",
    update: "/jobplantaskparts/update",
    delete: "/jobplantaskparts/delete",
    bulkinsert: "/jobplantaskparts/bulkinsert",
  },
  PM: {
    title: "PM Schedule",
    link: "/pmaintenace/getall",
    create: "/pmaintenace/create",
    update: "/pmaintenace/update",
    delete: "/pmaintenace/delete",
    getByid: "/pmaintenace/getbyid",
    getdocbyid: "/pmaintenace/getdocumentbypmid",
    uploadDoc: "/pmaintenace/uploaddocument",
    deleteDoc: "/pmaintenace/deletedocument",
    download: "/pmaintenace/downloaddocument",
    generateWO: "/pmaintenace/generateworkorderbylistofids",
    enable: "/pmaintenace/enable",
    disable: "/pmaintenace/disable",
  },
  serviceRequest: {
    title: "Service Request",
    link: "/servicerequest/getall",
    create: "/servicerequest/create",
    update: "/servicerequest/update",
    delete: "/servicerequest/delete",
    getByid: "/servicerequest/getbyid",
    coverttowo: "/servicerequest/converttoworkorder",
    getdocbyid: "/servicerequest/getdocumentbysrid",
    uploadDoc: "/servicerequest/uploaddocument",
    deleteDoc: "/servicerequest/deletedocument",
    download: "/servicerequest/downloaddocument",
    updateStatus: "/servicerequest/updatestatus",
    enable: "/servicerequest/enable",
    disable: "/servicerequest/disable",
  },
  site: {
    title: "Site",
    link: "/site/getall",
    create: "/site/create",
    update: "/site/update",
    delete: "/site/delete",
    getByid: "/site/getbyid",
    enable: "/site/enable",
    disable: "/site/disable",
  },
  businessCraft: {
    title: "Business Craft",
    link: "/businesscraft/getall",
    create: "/businesscraft/create",
    update: "/businesscraft/update",
    delete: "/businesscraft/delete",
    enable: "/businesscraft/enable",
    disable: "/businesscraft/disable",
    craftByBusiness: "/businesscraft/craftbybusinessid",
  },
  problems: {
    title: "Problem",
    link: "/problems/getall",
    create: "/problems/create",
    update: "/problems/update",
    delete: "/problems/delete",
    problembyFailureClass: "/problems/getall",
    enable: "/problems/enable",
    disable: "/problems/disable",
  },
  Causes: {
    title: "Causes",
    link: "/causes/getall",
    create: "/causes/create",
    update: "/causes/update",
    delete: "/causes/delete",
    causesbyproblem: "/causes/getall",
    enable: "/causes/enable",
    disable: "/causes/disable",
  },
  Remedies: {
    title: "Remedies",
    link: "/remedy/getall",
    create: "/remedy/create",
    update: "/remedy/update",
    delete: "/remedy/delete",
    remdybycause: "/remedy/getall",
    enable: "/remedy/enable",
    disable: "/remedy/disable",
  },
  tasktype: {
    title: "Task Type",
    link: "/tasktype/getall",
    create: "/tasktype/create",
    update: "/tasktype/update",
    delete: "/tasktype/delete",
    enable: "/tasktype/enable",
    disable: "/tasktype/disable",
  },
  TreeView: {
    location: "/location/treeview",
  },
  failureReport: {
    bulkinsert: "/failurereport/bulkinsert",
    getByWO: "/failurereport/getall",
  },
  inventoryCreation: {
    title: "Part Inventory",
    link: "/inventorycreation/getallinitialentry",
    create: "/inventorycreation/create",
    update: "/inventorycreation/update",
    delete: "/inventorycreation/delete",
    getAllInv: "inventorycreation/getallinventory",
  },
  receipt: {
    title: "Receipts",
    link: "/receipts/getall",
    create: "/receipts/createreceipt",
    update: "/receipts/updatereceipt",
    delete: "/receipts/deletereceipt",
    receiptList: "/receipts/getbyreceiptitemlist",
    delRecList: "/receipts/deletereceiptitem",
    enable: "/receipts/enablereceipt",
    disable: "/receipts/disablereceipt",
    enableItem: "/receipts/enablereceiptitem",
    disableItem: "/receipts/disablereceiptitem",
  },
  issue: {
    title: "Issue Transaction",
    link: "/issue/getall",
    create: "/issue/createIssue",
    update: "/issue/updateIssue",
    delete: "/issue/deleteIssue",
    issueList: "/issue/getbyissueditemlist",
    delIssueList: "/issue/deleteIssueitem",
    updateStatus: "/issue/updateIssuestatus",
    getIssuedPartsbyWO: "/issue/getissueddpartsbyworkorder",
    enable: "/issue/enableIssue",
    disable: "issue/disableIssue",
    enableItem: "/issue/enableIssueitem",
    disableItem: "issue/disableIssueitem",
  },
  inventory: {
    partStockLevelTitle: "Inventory Count",
    getAll: "/inventory/getall",
    search: "/inventory/search",
    bulkupdate: "/inventory/bulkupdate",
    update: "/inventory/update",
    getbystockroom: "/inventory/getbystockroomid",
  },
  issueReturn: {
    title: "Return Transaction",
    getAll: "/issuereturn/getall",
    create: "/issuereturn/createissuereturn",
    delete: "/issuereturn/deleteissuereturn",
    returnList: "/issuereturn/getbyissuereturnlist",
    delReturnList: "/issuereturn/deleteissuereturnitem",
    updateStatus: "/issuereturn/updateissuereturnstatus",
    enable: "/issuereturn/enableissuereturn",
    disable: "/issuereturn/disableissuereturn",
    enableItem: "/issuereturn/enableissuereturnitem",
    disableItem: "/issuereturn/disableissuereturnitem",
  },
  vendorReturn: {
    title: "Vendor Return Transaction",
    getAll: "/vendorreturn/getall",
    create: "/vendorreturn/createvendorreturn",
    delete: "/vendorreturn/deletevendorreturn",
    returnList: "/vendorreturn/getbyvendorreturnlist",
    delReturnList: "/vendorreturn/deletevendorreturnitem",
    enable: "/vendorreturn/enablevendorreturn",
    disable: "/vendorreturn/disablevendorreturn",
  },
  KPIReport: {
    getmtbfreport: "/kpireport/getmtbfreport",
    getmttrreport: "/kpireport/getmttrreport",
    getcomplaiancechart: "/kpireport/getcomplaiancechart",
    woBacklogAndOpenStatus: "/kpireport/getcompliancereportbystatus",
    serviceReqStatus: "/kpireport/getservicerequestreport",
    woCostandLabour: "/kpireport/getworkordercostreport",
    getFailureClass: "/kpireport/failurereportforfailures",
    getProblem: "/kpireport/failurereportforproblem",
    getCauses: "/kpireport/failurereportforcauses",
    getRemedies: "/kpireport/failurereportforremedy",
    getChartLocation: "/kpireport/getkpichartlocation",
    getCloseWOListWithLocation: "/workorder/searchcloseworkorder",
    getcompliancereportbystatuswolist: "/kpireport/getcompliancereportbystatuswolist",
    getcostreportforwolist: "/kpireport/getcostreportforwolist",
    getKPIReportComplaianceWOList: "/kpireport/getKPIReportComplaianceWOList",
    getservicerequestreportlist: "/kpireport/getservicerequestreportlist",
    getmtbfreportwoList: "/kpireport/getmtbfreportwoList",
    getmttrreportwoList: "/kpireport/getmttrreportwoList",
    failurereportforfailuresWOList: "/kpireport/failurereportforfailuresWOList",
    failurereportforproblemWOList: "/kpireport/failurereportforproblemWOList",
    failurereportforcausesWOList: "/kpireport/failurereportforcausesWOList",
    failurereportforremedyWOList: "/kpireport/failurereportforremedyWOList",
  },
  organization: {
    title: "Organization",
    link: "/organization/getall",
    create: "/organization/create",
    update: "/organization/update",
    delete: "/organization/delete",
    enable: "/organization/enable",
    disable: "/organization/disable",
  },
  techSpecAttr: {
    title: "Technical Specification Attributes",
    link: "/techspecattributes/getall",
    create: "/techspecattributes/create",
    update: "/techspecattributes/update",
    delete: "/techspecattributes/delete",
    getByTechSpecId: "/techspecattributes/getbytechspecid",
    clone: "/techspecattributes/clone",
  },
  assetMeter: {
    title: "Asset Meter",
    link: "/assetsmeter/getall",
    create: "/assetsmeter/create",
    update: "/assetsmeter/update",
    delete: "/assetsmeter/delete",
    enable: "/assetsmeter/enable",
    disable: "/assetsmeter/disable",
    createreading: "/assetsmeter/createmeterreading",
    search: "/assetsmeter/search",
  },
  srName: {
    title: "Service Request Name",
    link: "/servicerequestname/getall",
    create: "/servicerequestname/create",
    update: "/servicerequestname/update",
    delete: "/servicerequestname/delete",
    enable: "/servicerequestname/enable",
    disable: "/servicerequestname/disable",
  },
  inspectionCat: {
    title: "Inspection Category",
    link: "/inspectioncategory/getall",
    create: "/inspectioncategory/create",
    update: "/inspectioncategory/update",
    delete: "/inspectioncategory/delete",
    enable: "/inspectioncategory/enable",
    disable: "/inspectioncategory/disable",
  },
  question: {
    title: "Question",
    link: "/questionmaster/getall",
    create: "/questionmaster/create",
    update: "/questionmaster/update",
    delete: "/questionmaster/delete",
    enable: "/questionmaster/enable",
    disable: "/questionmaster/disable",
  },
  assetTypeQuestion: {
    title: "Asset Type Question",
    link: "/assettypequestions/getall",
    create: "/assettypequestions/create",
    update: "/assettypequestions/update",
    delete: "/assettypequestions/delete",
    enable: "/assettypequestions/enable",
    disable: "/assettypequestions/disable",
    getByAssetTypeId: "/assettypequestions/getquestionsbyassetypeid",
  },
  route: {
    title: "Route",
    link: "/routemaster/getall",
    create: "/routemaster/create",
    update: "/routemaster/update",
    delete: "/routemaster/delete",
    enable: "/routemaster/enable",
    disable: "/routemaster/disable",
  },
  assetRoute: {
    title: "Asset Route",
    link: "/assetsroute/getall",
    getByRouteId: "/assetsroute/getallassetsbyrouteid",
    create: "/assetsroute/create",
    update: "/assetsroute/update",
    delete: "/assetsroute/delete",
    enable: "/assetsroute/enable",
    disable: "/assetsroute/disable",
    bulkinsert: "/assetsroute/bulkinsert",
  },
  inspSchedule: {
    title: "Inspection Schedule",
    link: "/inspection/getall",
    create: "/inspection/create",
    update: "/inspection/update",
    delete: "/inspection/delete",
    getByid: "/inspection/getbyid",
    enable: "/inspection/enable",
    disable: "/inspection/disable",
    generateworkorder: "/inspection/generateworkorder",
    getdocumentbyinsid: "/inspection/getdocumentbyinsid",
    uploaddocument: "/inspection/uploaddocument",
    downloaddocument: "/inspection/downloaddocument",
    deletedocument: "/inspection/deletedocument",
  },
  inspWo: {
    title: "Inspection WorkOrder",
    link: "/inspectionworkorders/getall",
    create: "/inspectionworkorders/create",
    update: "/inspectionworkorders/update",
    delete: "/inspectionworkorders/delete",
    getByid: "/inspectionworkorders/getbyid",
    enable: "/inspectionworkorders/enable",
    changeStatus: "/inspectionworkorders/updatestatus",
    getdocumentbyinsworkorderid: "/inspectionworkorders/getdocumentbyinsworkorderid",
    disable: "/inspectionworkorders/disable",
    uploaddocument: "/inspectionworkorders/uploaddocument",
    bulkinsert: "/inspectionworkorders/bulkinsert",
    deletedocement: "/inspectionworkorders/deletedocement", 
    downloaddocument: "/inspectionworkorders/downloaddocument",
    bulkupdate: "/inspectionworkorders/bulkupdate",
    getInspectionWo: "/inspectionworkorders/getquestions",
    getQuestionByInsWo: "/inspectionworkorders/getallquestions",
  },
  inspWoLabour: {
    title: "Inspection WorkOrder Labour",
    link: "/inspectionworkorderlabours/getall",
    create: "/inspectionworkorderlabours/create",
    update: "/inspectionworkorderlabours/update",
    delete: "/inspectionworkorderlabours/delete",
    getByid: "/inspectionworkorderlabours/getbyid",
    enable: "/inspectionworkorderlabours/enable",
    disable: "/inspectionworkorderlabours/disable",
    getLabourByIWid:
      "/inspectionworkorderlabours/getlabourbyinspectionworkorderid",
    bulkinsert: "/inspectionworkorderlabours/bulkinsert",
  },
  partmovement: {
    title: "Parts Movement",
    link: "/partmovement/getall",
    enable: "/partmovement/enable",
    disable: "/partmovement/disable",
    create: "/partmovement/create",
    update: "/partmovement/update",
  },
  LineChart: {
    lineChartGraph:"Asset Health",
    lineValue:"Graph Value",
    iotInsertData:"/workorder/iotbulkinsert",
    getIotData:"/workorder/getiotdata"
  },
  currency: {
    title: "Currency",
    link: "/currency/getall",
    create: "/currency/create",
    update: "/currency/update",
    delete: "/currency/delete",
    getByid: "/currency/getbyid",
    enable: "/currency/enable",
    disable: "/currency/disable",
  },
  shift: {
    title: "Shift",
    link: "/shift/getall",
    create: "/shift/create",
    update: "/shift/update",
    delete: "/shift/delete",
    getByid: "/shift/getbyid",
    enable: "/shift/enable",
    disable: "/shift/disable",
  },
};
