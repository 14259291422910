import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Autocomplete,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Field, Form, Formik } from "formik";
  import * as Yup from "yup";
  import { Clear } from "@mui/icons-material";
  import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import dayjs from "dayjs";
  import moment from "moment";
  
  import { color } from "../../../StyleData/Config";
  import { axiosPrivate } from "../../../axios/axios";
  import { masterAPI } from "../dataConfig";
  import { DesktopDatePicker } from "@mui/lab";
  import { DateTimePicker } from "@mui/x-date-pickers";
  const countryCurrencyList = [
    { country: "United States", currency: "US Dollar", symbol: "$", decimalPlaces: 2 },
    { country: "Eurozone", currency: "Euro", symbol: "€", decimalPlaces: 2 },
    { country: "Japan", currency: "Japanese Yen", symbol: "¥", decimalPlaces: 0 },
    { country: "United Kingdom", currency: "British Pound", symbol: "£", decimalPlaces: 2 },
    { country: "India", currency: "Indian Rupee", symbol: "₹", decimalPlaces: 2 },
    { country: "Australia", currency: "Australian Dollar", symbol: "A$", decimalPlaces: 2 },
    { country: "Canada", currency: "Canadian Dollar", symbol: "C$", decimalPlaces: 2 },
    { country: "Switzerland", currency: "Swiss Franc", symbol: "CHF", decimalPlaces: 2 },
    { country: "China", currency: "Chinese Yuan", symbol: "¥", decimalPlaces: 2 },
    { country: "Sweden", currency: "Swedish Krona", symbol: "kr", decimalPlaces: 2 },
    { country: "Malaysia", currency: "Malaysian Ringgit", symbol: "RM", decimalPlaces: 2 },
    { country: "Singapore", currency: "Singapore Dollar", symbol: "S$", decimalPlaces: 2 },
    { country: "United Arab Emirates", currency: "UAE Dirham", symbol: "AED", decimalPlaces: 2 },
  ];
  
  
  const CurrencyModal = ({ mode, handleCloseClick, editData }) => {
   
    const [loading,setLoading]=useState(false)
  
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [formValues, setFormValues] = useState({
      country: mode === "Edit" ? editData.country : "",
      currencyName: mode === "Edit" ? editData.currencyName : "",
      symbol: mode === "Edit" ? editData.symbol : "",
      decimalPlaces: mode === "Edit" ? editData.decimalPlaces : "",
    });
  
    const initialValue = {
      currencyName: mode === "Edit" ? editData.currencyName : "",
      code: mode === "Edit" ? editData.code:"",
      
        };
        const handleCountryChange = (event, newValue) => {
          if (newValue) {
            setFormValues({
              country: newValue.country,
              currencyName: newValue.currency,
              symbol: newValue.symbol,
              decimalPlaces: newValue.decimalPlaces,
            });
            setSelectedCountry(newValue);
          } else {
            setFormValues({
              country: "",
              currencyName: "",
              symbol: "",
              decimalPlaces: "",
            });
            setSelectedCountry(null);
          }
        };
    const onSubmit = async(value) => {
      console.log(value);
      setLoading(true)
      const createLink = masterAPI["currency"].create;
      const updateLink = masterAPI["currency"].update;
      if (mode === "Create") {
          
       await axiosPrivate
          .post(`${createLink}`, value)
          .then((res) => {
            console.log(res.data);
            setLoading(false)
            handleCloseClick();
          })
          .catch((e) => {
            setLoading(false)
            console.log(e, "createfailureClass");
          });
      } else {
        const updateAC = {
          id: editData.id,
          ...value,
        };
       await axiosPrivate
          .put(`${updateLink}`, updateAC)
          .then((res) => {
            console.log(res.data);
            setLoading(false)
            handleCloseClick();
          })
          .catch((e) => {
            setLoading(false)
            console.log(e, "updatefailureClass");
          });
      }
    };
  
    const handleCancel = () => {
      handleCloseClick();
    };
  
    return (
      <Box sx={{ p: color.masterPageDialogPadding }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
            {mode === "Edit"?editData.code+"-"+editData.name:"New Currency"}
          </Typography>
          <IconButton onClick={handleCancel}>
            <Clear />
          </IconButton>
        </Box>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        <Formik      initialValues={{
        country: mode === "Edit" ? editData.country : "",
        code: mode ==="Edit" ? editData.code : "",
        currencyName: mode === "Edit" ? editData.currencyName : "",
        symbol: mode === "Edit" ? editData.symbol : "",
        decimalPlaces: mode === "Edit" ? editData.decimalPlaces : "",
      }}
      enableReinitialize onSubmit={onSubmit}>
          {({ handleChange, setFieldValue, values, resetForm }) => (
            <Form>
              <Grid container spacing={"1rem"}>
              <Grid item xs={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Currency Code
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="code"
                    placeholder="Enter Currency Code"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Country
                </Typography>
              <Autocomplete
                options={countryCurrencyList}
                getOptionLabel={(option) => option.country}
                value={countryCurrencyList.find((c) => c.country === values.country) || null} 
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFieldValue("country", newValue.country);
                    setFieldValue("currencyName", newValue.currency);
                    setFieldValue("symbol", newValue.symbol);
                    setFieldValue("decimalPlaces", newValue.decimalPlaces);
                  } else {
                    setFieldValue("country", "");
                    setFieldValue("currencyName", "");
                    setFieldValue("symbol", "");
                    setFieldValue("decimalPlaces", "");
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Country" fullWidth size="small" />
                )}
              />
            </Grid>

            {/* Currency Name (Read-Only) */}
            <Grid item xs={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Currency Name
                </Typography>
              <Field
                as={TextField}
                size="small"
                type="text"
                name="currencyName"
                value={values.currencyName}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>

            {/* Symbol (Read-Only) */}
            <Grid item xs={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Symbol
                </Typography>
              <Field
                as={TextField}
                size="small"
                type="text"
                name="symbol"
                value={values.symbol}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>

            {/* Decimal Places (Read-Only) */}
            <Grid item xs={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Decimal Points
                </Typography>
              <Field
                as={TextField}
                size="small"
                type="text"
                name="decimalPlaces"
                value={values.decimalPlaces}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"flex-end"}
                  gap="1rem"
                >
                  <Button
                    onClick={handleCancel}
                    variant="contained"
                    sx={{
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                      ":hover": {
                        backgroundColor: color.TableCancelButton,
                        textTransform: "none",
                        color: color.CancelTextColor,
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    variant="contained"
                    disabled={loading}
                    sx={{
                      
                      textTransform: "none",
                      backgroundColor: loading ? "default" : color.TableButtonColor,
                      "&:hover": {
                        backgroundColor: loading
                          ? "default"
                          : color.buttonDisable,
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };
  
  export default CurrencyModal;
  