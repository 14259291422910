import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import {
  AccountTree,
  BorderColor,
  BorderColorTwoTone,
  Circle,
  Clear,
  Edit,
  MoreHoriz,
  Save,
  SaveOutlined,
} from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import General from "./General";
import GeneralEdit from "./WorkTab";
import Loading from "../../../Component/Loading"; 

import product_image from "../../../images/product2.jpg"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import moment from "moment";
import WorkTab from "./WorkTab";
import ListMenu from "./SideMenu/ListMenu";
import Parts from "./Parts";
import Specification from "./Specification";
import LocationTreeView from "./locationTreeView";
import LineChart from "../../KPIReport/LineChart";

const Asset_Details = ({assetExpl}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dotBL, setDotBL] = useState(null);
  const [value, setValue] = useState("1");
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [locationDialog, setLocationDialog] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [location, setLocation] = useState("");
  const [installationDateValue, setInstallationDateValue] = useState("");

  const [assetCategory, setAssetCategory] = useState([]);
  const [assetType, setAssetType] = useState([]);

  const [failureClasses, setFailureClasses] = useState([]);
  const [assetPriority, setAssetPriority] = useState([]);
  const [assetCriticality, setAssetCriticality] = useState([]);
  const [parentAsset, setParentAsset] = useState([]);
  const [suppiler,setSuppiler]=useState([])
  const [manufacturer,setManufacturer]=useState([])
  const [assetImage,setAssetImage]=useState(null)

  const [direction, setDirection] = useState("row");

  useEffect(() => {
    setIsLoading(true);

    getData();
//     if (edit) {
//       const Critlink = masterAPI["selectList"].criticalitybyType;
//       axiosPrivate
//         .get(`${Critlink}/Asset`)
//         .then((res) => {
//           setAssetCriticality(res.data);
//         })
//         .catch((e) => {
//           setAssetCategory([])
//           console.log(e, "createAcritigetAll");
//         });
//       /////////Category//////
//       const assetCat = masterAPI["assetCategory"].link;
//       axiosPrivate
//         .get(`${assetCat}`)
//         .then((res) => {
//           setAssetCategory(res.data.result);
//         })
//         .catch((e) => {
//           setAs
//           console.log(e, "createACgetAll");
//         });
//       //////Asset Type/////
//       const assetTyp = masterAPI["assetType"].link;
//       axiosPrivate
//         .get(`${assetTyp}`)
//         .then((res) => {
//           setAssetType(res.data.result);
//         })
//         .catch((e) => {
//           console.log(e, "createAssetTypegetAll");
//         });
//       const failClass = masterAPI["failureClass"].link;
//       axiosPrivate
//         .get(`${failClass}`)
//         .then((res) => {
//           setFailureClasses(res.data.result);
//         })
//         .catch((e) => {
//           console.log(e, "createfailureClassgetAll");
//         });
//       /////////asset Priority//////////
//       const assetPri = masterAPI["priority"].link;
//       axiosPrivate
//         .get(`${assetPri}`)
//         .then((res) => {
//           setAssetPriority(res.data.result);
//         })
//         .catch((e) => {
//           console.log(e, "createAssetPrigetAll");
//         });

//       const assets = masterAPI["assets"].link;
    
//       axiosPrivate
//         .get(`${assets}`)
//         .then((res) => {
//           setParentAsset(res.data.result);
//         })
//         .catch((e) => {
//           console.log(e, "createParentAssetgetAll");
//         });

// const typeLink=masterAPI["selectList"].businessByType       

//         axiosPrivate.get(`${typeLink}?businessType=Supplier`).then(res=>{
//           console.log(res)
//             setSuppiler(res.data)
//         }).catch(e=>{
//           console.log(e,"error")
//         })

//         axiosPrivate.get(`${typeLink}?businessType=Manufacturer`).then(res=>{
//           setManufacturer(res.data)
//       }).catch(e=>{
//         console.log(e,"error")
//       })
//     }
  }, [edit,assetExpl?.assetId]);

  const getData = async () => {
    const id = assetExpl?.page?assetExpl?.assetId:searchParams.get("id");
    const link = masterAPI["assets"].getById;
    console.log(id, link);
   await axiosPrivate
      .get(`${link}/${id}`)
      .then((res) => {
        console.log(res);
        setData(res.data.result);
        setLocation(res.data.result.locationName)
        setLocationId(res.data.result.locationId)
        setInstallationDateValue(res.data.result.installationDate);
        
        if(res.data.result.assetImage.split("/")[3]!==""){
          console.log(res.data.result.assetImage)
          setAssetImage(res.data.result.assetImage)
          // console.log(res.data.result.assetImage.split("Documents"))
          // setAssetImage(`https://demo.orienseam.com/api/documents/${res.data.result.assetImage.split("Documents")[1]}`)
        }else{
          setAssetImage(product_image)
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setData([])
        setLocation("")
        setLocationId("")
        setInstallationDateValue("")
        setAssetImage("")
        console.log(e, "assetDetailsError");
      });
  };

  const contentPosition = (flex) => {
    setDirection(flex);
  };

  const handleDot = (event) => {
    if (!dotBL) {
      setDotBL(event.currentTarget);
    } else {
      setDotBL(null);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    setEdit(!edit);
  };

  const locationHandle = () => {
    setLocationDialog(true);
  };

  const handlelocationClick = (name, id) => {
    if (
      name !== undefined &&
      name !== null &&
      id !== undefined &&
      id !== null
    ) {
      setLocation(name);
      setLocationId(id);
    }

    setLocationDialog(false);
  };
  const handleCreateClose = () => {
    setLocationDialog(false);
  };

  const formik = useFormik({
    initialValues: {
      code:data.code,
      assetName: data.assetName,
      assetTypeId: data.assetTypeId,
      priorityId: data.priorityId,
      criticalityId: data.criticalityId,
      parentAssetId: data.parentAssetId,
      failureClassesId: data.failureClassesId,
      locationId: location,
      assetCategoryId: data.assetCategoryId,
      serialNumber: data.serialNumber,
      model: data.model,
      purchasePrice: data.purchasePrice,
      installationDate: installationDateValue,
      supplierId: data.supplierId,
      manufacturerId: data.manufacturerId,
    },
    // initialValues:{check:data.assetTypeName},
    enableReinitialize: true,
    onSubmit:async (values) => {
      // alert(JSON.stringify(values, null, 2));
      const updateLink = masterAPI["assets"].update;
      const updateValue = {
        ...values,
        id: data.id,
        ["locationId"]: locationId,
      };
      await axiosPrivate
        .put(`${updateLink}`, updateValue)
        .then((res) => {
          console.log(res.data);
          // setLoading(false)
          setLocation("");
          setLocationId("");
          setEdit(false);
          // handleCloseClick();
        })
        .catch((e) => {
          // setLoading(false)
          console.log(e, "updateAssets");
        });
    },
  });
  return (
    <Box>
      {/* <Box sx={{width: {xs:direction==="column"?"50%":"100%",lg:direction==="column"?"50%":"100%"}}}> */}
      <Grid container spacing={"1rem"}>
        <Grid
          item
          xs={assetExpl?.page?12:direction === "column" ? 6 : 9.5}
          md={assetExpl?.page?12:direction === "column" ? 6 : 10.5}
          lg={assetExpl?.page?12:direction === "column" ? 6 : 11}
        >
          <Box
            sx={{
              display: "flex",
              width: "95%",
              justifyContent: "space-between",
              p: 1,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: color.AssetTitlesize,
                fontWeight: color.sideFontWeightAsset,
              }}
            >
              Asset: {data.code}: {data.assetName}
            </Typography>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Box
                sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
              >
                <Circle sx={{ fontSize: "0.5rem", color: "green" }} />
                <Typography>{data.statusName}</Typography>
              </Box>
              {/* <MoreHoriz onClick={handleDot} sx={{ cursor: "pointer" }} />
              <Menu anchorEl={dotBL} open={Boolean(dotBL)} onClose={handleDot}>
                <MenuItem>Option 1</MenuItem>
                <MenuItem>Option 2</MenuItem>
                <MenuItem>Option 3</MenuItem>
              </Menu> */}
              <Box></Box>
            </Box>
          </Box>
          {loading ? (
            <Loading />
          ) : (
            <TabContext value={value}>
              <Box
                sx={{
                  ".css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                    color: color.TabLabelcolor,
                    // p: 0.5,
                  },
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{
                    sx: { backgroundColor: color.TabLabelcolor, height: "3px" },
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    label="General"
                    value="1"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    label="Parts/BOM"
                    value="2"
                    sx={{ textTransform: "none" }}
                  />
                       <Tab
                    label="Specification"
                    value="4"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab label="Work" value="3" sx={{ textTransform: "none" }} />
             
                  <Tab
                    label="Asset Health"
                    value="5"
                    sx={{ textTransform: "none" }}
                  />
                </TabList>
                {/* <Box sx={{display:assetExpl?.page?"none":"default"}}>
                  {edit ? (
                    <Box>
                   
                      <IconButton onClick={formik.handleSubmit}>
                        <SaveOutlined
                          sx={{ color: "blue", fontSize: "1.7rem" }}
                        />
                      </IconButton>
                      <IconButton onClick={handleEdit}>
                        <Clear sx={{ color: "blue", fontSize: "1.7rem" }} />
                      </IconButton>
                    </Box>
                  ) : (
                    <IconButton onClick={handleEdit} sx={{display:value==="1"?"default":"none"}}>
                      <BorderColorTwoTone
                        sx={{ color: "blue", fontSize: "1.7rem" }}
                      />
                    </IconButton>
                  )}
                </Box> */}
              </Box>
              <TabPanel value="1" sx={{ p: 1 }}>
                {edit ? (
                  null
                  // <Box
                  //   sx={{
                  //     display: "flex",
                  //     // alignItems: "center",
                  //     width:"95%",
                  //     gap: "1.5rem",
                  //     flexWrap: "noWrap",
                  //     flexDirection: direction,
                  //   }}
                  // >
                  //   <Paper sx={{ flex: "0 0 49%" }}>
                  //     <Box sx={{ p: 1.5 }}>
                  //       <Typography
                  //         sx={{
                  //           color: color.Basiccolor,
                  //           fontSize: color.BasicfontSize,
                  //           fontWeight: color.Basicfontweight,
                  //         }}
                  //       >
                  //         Basic
                  //       </Typography>
                  //     </Box>
                  //     <Divider />
                  //     <Box
                  //       sx={{
                  //         display: "flex",
                  //         width: "100%",
                  //         p: 1.5,
                  //         spacing: "1rem",
                  //       }}
                  //     >
                  //       <Grid container sx={{}} spacing={"1.5rem"}>
                  //       <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Asset Code
                  //           </Typography>
                  //           <Box>
                  //             <TextField
                  //               size="small"
                  //               variant="standard"
                  //               name="code"
                  //               value={formik.values.code}
                  //               placeholder="Enter Asset Code"
                  //               onChange={formik.handleChange}
                  //               sx={{
                  //                 ".MuiInputBase-input": {
                  //                   // letterSpacing: "0.2rem",
                  //                   fontSize: "13px",

                  //                   // "&::placeholder": {
                  //                   //   // color: "green",
                  //                   //   opacity: 1,
                  //                   //   color: color.placeholderColor,
                  //                   //   fontSize: "13px",
                  //                   // },
                  //                 },
                  //               }}
                  //             />
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Asset Name
                  //           </Typography>
                  //           <Box>
                  //             <TextField
                  //               size="small"
                  //               variant="standard"
                  //               name="assetName"
                  //               value={formik.values.assetName}
                  //               placeholder="Enter AssetName"
                  //               onChange={formik.handleChange}
                  //               sx={{
                  //                 ".MuiInputBase-input": {
                  //                   // letterSpacing: "0.2rem",
                  //                   fontSize: "13px",

                  //                   // "&::placeholder": {
                  //                   //   // color: "green",
                  //                   //   opacity: 1,
                  //                   //   color: color.placeholderColor,
                  //                   //   fontSize: "13px",
                  //                   // },
                  //                 },
                  //               }}
                  //             />
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Asset Type
                  //           </Typography>
                  //           <Box>
                  //             <Select
                  //               size={"small"}
                  //               // variant="standard"
                  //               // type="text"
                  //               name="assetTypeId"
                  //               onChange={formik.handleChange}
                  //               value={formik.values.assetTypeId}
                  //               fullWidth
                  //               displayEmpty
                  //               MenuProps={{
                  //                 PaperProps: { sx: { maxHeight: "40vh" } },
                  //               }}
                  //               renderValue={
                  //                 formik.values.assetTypeId !== ""
                  //                   ? undefined
                  //                   : () => (
                  //                       <Typography
                  //                         sx={{
                  //                           color: color.placeholderColor,
                  //                           fontSize: "13px",
                  //                         }}
                  //                       >
                  //                         Select
                  //                       </Typography>
                  //                     )
                  //               }
                  //             >
                  //               {assetType.map((id, i) => (
                  //                 <MenuItem value={id.id}>
                  //                   {id.code+" - "+id.assetTypeName}
                  //                 </MenuItem>
                  //               ))}
                  //             </Select>
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Priority
                  //           </Typography>
                  //           <Box>
                  //             <Select
                  //               size={"small"}
                  //               // variant="standard"
                  //               // type="text"
                  //               name="assetPriorityId"
                  //               onChange={formik.handleChange}
                  //               value={formik.values.priorityId}
                  //               fullWidth
                  //               displayEmpty
                  //               MenuProps={{
                  //                 PaperProps: { sx: { maxHeight: "40vh" } },
                  //               }}
                  //               renderValue={
                  //                 formik.values.assetPriorityId !== ""
                  //                   ? undefined
                  //                   : () => (
                  //                       <Typography
                  //                         sx={{
                  //                           color: color.placeholderColor,
                  //                           fontSize: "13px",
                  //                         }}
                  //                       >
                  //                         Select
                  //                       </Typography>
                  //                     )
                  //               }
                  //             >
                  //               {assetPriority.map((id, i) => (
                  //                 <MenuItem value={id.id}>
                  //                   {id.code+" - "+id.priorityName}
                  //                 </MenuItem>
                  //               ))}
                  //             </Select>
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Asset Desc
                  //           </Typography>
                  //           <Box>
                  //             <TextField
                  //               size="small"
                  //               variant="standard"
                  //               placeholder="Enter AssetName"
                  //               value="Dosing"
                  //               sx={{
                  //                 ".MuiInputBase-input": {
                  //                   // letterSpacing: "0.2rem",
                  //                   fontSize: "13px",

                  //                   // "&::placeholder": {
                  //                   //   // color: "green",
                  //                   //   opacity: 1,
                  //                   //   color: color.placeholderColor,
                  //                   //   fontSize: "13px",
                  //                   // },
                  //                 },
                  //               }}
                  //             />
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Criticality
                  //           </Typography>
                  //           <Box>
                  //             <Select
                  //               size={"small"}
                  //               // variant="standard"
                  //               // type="text"
                  //               name="assetCriticalityId"
                  //               onChange={formik.handleChange}
                  //               value={formik.values.criticalityId}
                  //               fullWidth
                  //               displayEmpty
                  //               MenuProps={{
                  //                 PaperProps: { sx: { maxHeight: "40vh" } },
                  //               }}
                  //               renderValue={
                  //                 formik.values.criticalityId !== ""
                  //                   ? undefined
                  //                   : () => (
                  //                       <Typography
                  //                         sx={{
                  //                           color: color.placeholderColor,
                  //                           fontSize: "13px",
                  //                         }}
                  //                       >
                  //                         Select
                  //                       </Typography>
                  //                     )
                  //               }
                  //             >
                  //               {assetCriticality.map((id, i) => (
                  //                 <MenuItem value={id.value}>
                  //                   {id.text}
                  //                 </MenuItem>
                  //               ))}
                  //             </Select>
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Parent
                  //           </Typography>
                  //           <Box>
                  //             <Select
                  //               size={"small"}
                  //               // variant="standard"
                  //               // type="text"
                  //               name="parentAssetId"
                  //               onChange={formik.handleChange}
                  //               value={formik.values.parentAssetId}
                  //               fullWidth
                  //               displayEmpty
                  //               MenuProps={{
                  //                 PaperProps: { sx: { maxHeight: "40vh" } },
                  //               }}
                  //               renderValue={
                  //                 formik.values.parentAssetId !== ""
                  //                   ? undefined
                  //                   : () => (
                  //                       <Typography
                  //                         sx={{
                  //                           color: color.placeholderColor,
                  //                           fontSize: "13px",
                  //                         }}
                  //                       >
                  //                         Select
                  //                       </Typography>
                  //                     )
                  //               }
                  //             >
                  //               {parentAsset.map((id, i) => (
                  //                 <MenuItem value={id.id} disabled={data.id===id.id}>
                  //                   {id.code+" - "+id.assetName}
                  //                 </MenuItem>
                  //               ))}
                  //             </Select>
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Failure Classes
                  //           </Typography>
                  //           <Box>
                  //             <Select
                  //               size={"small"}
                  //               // variant="standard"
                  //               // type="text"
                  //               name="failureClassesId"
                  //               onChange={formik.handleChange}
                  //               value={formik.values.failureClassesId}
                  //               fullWidth
                  //               displayEmpty
                  //               MenuProps={{
                  //                 PaperProps: { sx: { maxHeight: "40vh" } },
                  //               }}
                  //               renderValue={
                  //                 formik.values.failureClassesId !== ""
                  //                   ? undefined
                  //                   : () => (
                  //                       <Typography
                  //                         sx={{
                  //                           color: color.placeholderColor,
                  //                           fontSize: "13px",
                  //                         }}
                  //                       >
                  //                         Select
                  //                       </Typography>
                  //                     )
                  //               }
                  //             >
                  //               {failureClasses.map((id, i) => (
                  //                 <MenuItem value={id.id}>
                  //                   {id.code+" - "+id.failureClassName}
                  //                 </MenuItem>
                  //               ))}
                  //             </Select>
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Locaiton Id
                  //           </Typography>
                  //           <TextField
                  //             size={"small"}
                  //             type="text"
                  //             name="locationId"
                  //             placeholder="Enter Location"
                  //             value={formik.values.locationId}
                  //             fullWidth
                  //             onChange={(e) => {
                  //               // setFieldValue("locationId",e.target.value)
                  //               setLocation(e.target.value);
                  //             }}
                  //             InputProps={{
                  //               endAdornment: (
                  //                 <InputAdornment position="end">
                  //                   <IconButton onClick={locationHandle}>
                  //                     <AccountTree
                  //                       sx={{
                  //                         color: color.loginIcon,
                  //                         fontWeight: 1800,
                  //                       }}
                  //                     />
                  //                   </IconButton>
                  //                 </InputAdornment>
                  //               ),
                  //             }}
                  //             sx={{
                  //               ".MuiInputBase-input": {
                  //                 // letterSpacing: "0.2rem",
                  //                 fontSize: "13px",

                  //                 // "&::placeholder": {
                  //                 //   // color: "green",
                  //                 //   opacity: 1,
                  //                 //   color: color.placeholderColor,
                  //                 //   fontSize: "13px",
                  //                 // },
                  //               },
                  //             }}
                  //           />
                  //         </Grid>
                  //         {/* <Grid item xs={12} md={12} lg={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Rotaing
                  //           </Typography>
                  //           <Box
                  //             sx={{
                  //               display: "flex",
                  //               alignItems: "center",
                  //               gap: "0.5rem",
                  //               // flexWrap: "wrap",
                  //             }}
                  //           >
                  //             <Typography
                  //               sx={{
                  //                 fontSize: color.TabDatafontsize,
                  //                 mb: color.TabDatamarginbottom,
                  //               }}
                  //             >
                  //               In Active
                  //             </Typography>
                  //             <Switch
                  //               sx={{
                  //                 width: 48,
                  //                 height: 26,
                  //                 padding: 0,
                  //                 "& .MuiSwitch-switchBase": {
                  //                   padding: 0,
                  //                   margin: 0.2,
                  //                   transitionDuration: "300ms",
                  //                   "&.Mui-checked": {
                  //                     transform: "translateX(22px)",
                  //                     color: "#fff",
                  //                     "& + .MuiSwitch-track": {
                  //                       backgroundColor: (theme) =>
                  //                         theme.palette.mode === "dark"
                  //                           ? "#2ECA45"
                  //                           : "#65C466",
                  //                       opacity: 1,
                  //                       border: 0,
                  //                     },
                  //                     "&.Mui-disabled + .MuiSwitch-track": {
                  //                       opacity: 0.5,
                  //                     },
                  //                   },
                  //                   "&.Mui-focusVisible .MuiSwitch-thumb": {
                  //                     color: "#33cf4d",
                  //                     border: "6px solid #fff",
                  //                   },
                  //                   "&.Mui-disabled .MuiSwitch-thumb": {
                  //                     color: (theme) =>
                  //                       theme.palette.mode === "light"
                  //                         ? theme.palette.grey[100]
                  //                         : theme.palette.grey[600],
                  //                   },
                  //                   "&.Mui-disabled + .MuiSwitch-track": {
                  //                     opacity: (theme) =>
                  //                       theme.palette.mode === "light"
                  //                         ? 0.7
                  //                         : 0.3,
                  //                   },
                  //                 },
                  //                 "& .MuiSwitch-thumb": {
                  //                   boxSizing: "border-box",
                  //                   width: 22,
                  //                   height: 22,
                  //                 },
                  //                 "& .MuiSwitch-track": {
                  //                   borderRadius: 26 / 2,
                  //                   backgroundColor: (theme) =>
                  //                     theme.palette.mode === "light"
                  //                       ? "#E9E9EA"
                  //                       : "#39393D",
                  //                   opacity: 1,
                  //                   transition: (theme) =>
                  //                     theme.transitions.create(
                  //                       ["background-color"],
                  //                       {
                  //                         duration: 500,
                  //                       }
                  //                     ),
                  //                 },
                  //               }}
                  //             />
                  //             <Typography
                  //               sx={{
                  //                 fontSize: color.TabDatafontsize,
                  //                 mb: color.TabDatamarginbottom,
                  //               }}
                  //             >
                  //               Active
                  //             </Typography>
                  //           </Box>
                  //         </Grid> */}
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Asset Category
                  //           </Typography>
                  //           <Box>
                  //             <Select
                  //               size={"small"}
                  //               // variant="standard"
                  //               // type="text"
                  //               name="assetCategoryId"
                  //               onChange={formik.handleChange}
                  //               value={formik.values.assetCategoryId}
                  //               fullWidth
                  //               displayEmpty
                  //               MenuProps={{
                  //                 PaperProps: { sx: { maxHeight: "40vh" } },
                  //               }}
                  //               renderValue={
                  //                 formik.values.assetCategoryId !== ""
                  //                   ? undefined
                  //                   : () => (
                  //                       <Typography
                  //                         sx={{
                  //                           color: color.placeholderColor,
                  //                           fontSize: "13px",
                  //                         }}
                  //                       >
                  //                         Select
                  //                       </Typography>
                  //                     )
                  //               }
                  //             >
                  //               {assetCategory.map((id, i) => (
                  //                 <MenuItem value={id.id}>
                  //                   {id.code+" - "+id.assetCategoryName}
                  //                 </MenuItem>
                  //               ))}
                  //             </Select>
                  //           </Box>
                  //         </Grid>
                  //       </Grid>
                  //       <Paper
                  //         elevation={4}
                  //         sx={{ height: "fit-content", p: 2 }}
                  //       >
                  //         <img
                  //           src={assetImage}
                  //           alt="productImage"
                  //           style={{ width: "100px", height: "100px" }}
                  //         />
                  //       </Paper>
                  //     </Box>
                  //   </Paper>
                  //   <Paper sx={{ flex: "0 0 49%" }}>
                  //     <Box sx={{ p: 1.5 }}>
                  //       <Typography
                  //         sx={{
                  //           color: color.Basiccolor,
                  //           fontSize: color.BasicfontSize,
                  //           fontWeight: color.Basicfontweight,
                  //         }}
                  //       >
                  //         Additional Info
                  //       </Typography>
                  //     </Box>
                  //     <Divider />
                  //     <Box sx={{ display: "flex", width: "100%", p: 1.5 }}>
                  //       <Grid container sx={{}} spacing={"1.5rem"}>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Serial Number
                  //           </Typography>
                  //           <Box>
                  //             <TextField
                  //               size="small"
                  //               variant="standard"
                  //               name="serialNumber"
                  //               value={formik.values.serialNumber}
                  //               placeholder="Enter AssetName"
                  //               onChange={formik.handleChange}
                  //               sx={{
                  //                 ".MuiInputBase-input": {
                  //                   // letterSpacing: "0.2rem",
                  //                   fontSize: "13px",

                  //                   // "&::placeholder": {
                  //                   //   // color: "green",
                  //                   //   opacity: 1,
                  //                   //   color: color.placeholderColor,
                  //                   //   fontSize: "13px",
                  //                   // },
                  //                 },
                  //               }}
                  //             />
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Manufacturer
                  //           </Typography>
                  //           <Box>
                  //             <Select
                  //               size={"small"}
                  //               // variant="standard"
                  //               // type="text"
                  //               name="manufacturerId"
                  //               onChange={formik.handleChange}
                  //               value={formik.values.manufacturerId}
                  //               fullWidth
                  //               displayEmpty
                  //               MenuProps={{
                  //                 PaperProps: { sx: { maxHeight: "40vh" } },
                  //               }}
                  //               renderValue={
                  //                 formik.values.manufacturerId !== undefined
                  //                   ? undefined
                  //                   : () => (
                  //                       <Typography
                  //                         sx={{
                  //                           color: color.placeholderColor,
                  //                           fontSize: "13px",
                  //                         }}
                  //                       >
                  //                         Select
                  //                       </Typography>
                  //                     )
                  //               }
                  //             >
                  //               {manufacturer.map((id, i) => (
                  //                 <MenuItem value={id.value}>
                  //                   {id.text}
                  //                 </MenuItem>
                  //               ))}
                  //             </Select>
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Model
                  //           </Typography>
                  //           <Box>
                  //             <TextField
                  //               size="small"
                  //               variant="standard"
                  //               name="model"
                  //               value={formik.values.model}
                  //               placeholder="Enter AssetName"
                  //               onChange={formik.handleChange}
                  //               sx={{
                  //                 ".MuiInputBase-input": {
                  //                   // letterSpacing: "0.2rem",
                  //                   fontSize: "13px",

                  //                   // "&::placeholder": {
                  //                   //   // color: "green",
                  //                   //   opacity: 1,
                  //                   //   color: color.placeholderColor,
                  //                   //   fontSize: "13px",
                  //                   // },
                  //                 },
                  //               }}
                  //             />
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             YTD Cost
                  //           </Typography>
                  //           <Box>
                  //             <TextField
                  //               size="small"
                  //               variant="standard"
                  //               placeholder="Enter YTD Cost"
                  //               sx={{
                  //                 ".MuiInputBase-input": {
                  //                   // letterSpacing: "0.2rem",
                  //                   fontSize: "13px",

                  //                   // "&::placeholder": {
                  //                   //   // color: "green",
                  //                   //   opacity: 1,
                  //                   //   color: color.placeholderColor,
                  //                   //   fontSize: "13px",
                  //                   // },
                  //                 },
                  //               }}
                  //             />
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Purchase Price
                  //           </Typography>
                  //           <Box>
                  //             <TextField
                  //               size="small"
                  //               variant="standard"
                  //               name="purchasePrice"
                  //               value={formik.values.purchasePrice}
                  //               placeholder="Enter Purchase Price"
                  //               onChange={formik.handleChange}
                  //             />
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             LTD Cost
                  //           </Typography>
                  //           <Box>
                  //             <TextField
                  //               size="small"
                  //               variant="standard"
                  //               placeholder="Enter LTD Cost"
                  //               sx={{
                  //                 ".MuiInputBase-input": {
                  //                   // letterSpacing: "0.2rem",
                  //                   fontSize: "13px",

                  //                   // "&::placeholder": {
                  //                   //   // color: "green",
                  //                   //   opacity: 1,
                  //                   //   color: color.placeholderColor,
                  //                   //   fontSize: "13px",
                  //                   // },
                  //                 },
                  //               }}
                  //             />
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Installation Date
                  //           </Typography>
                  //           <LocalizationProvider dateAdapter={AdapterDayjs}>
                  //             <DemoContainer components={["DatePicker"]}>
                  //               <DatePicker
                  //                 format="DD/MM/YYYY"
                  //                 value={dayjs(formik.values.installationDate)}
                  //                 name="installationDate"
                  //                 //   sx={{".css-1xhypcz-MuiStack-root":{
                  //                 //     padding:0
                  //                 //   }}}
                  //                 slotProps={{
                  //                   textField: {
                  //                     size: "small",
                  //                     margin: "-8px 0 0 0",
                  //                     fontSize: "13px",
                  //                   },
                  //                 }}
                  //                 onChange={(newValue) => {
                  //                   console.log(newValue);
                  //                   if (newValue !== null) {
                  //                     if (`${newValue.$d}` !== "Invalid Date") {
                  //                       const dateFrom = moment(
                  //                         newValue.$d
                  //                       ).format("YYYY-MM-DD");
                  //                       const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                  //                       const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                  //                       const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                  //                       const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                  //                       setInstallationDateValue(dateFormat);
                  //                     }
                  //                   }
                  //                 }}
                  //               />
                  //             </DemoContainer>
                  //           </LocalizationProvider>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             YTD MTBF
                  //           </Typography>
                  //           <Box>
                  //             <TextField
                  //               size="small"
                  //               variant="standard"
                  //               placeholder="Enter YTD MTBF"
                  //               sx={{
                  //                 ".MuiInputBase-input": {
                  //                   // letterSpacing: "0.2rem",
                  //                   fontSize: "13px",

                  //                   // "&::placeholder": {
                  //                   //   // color: "green",
                  //                   //   opacity: 1,
                  //                   //   color: color.placeholderColor,
                  //                   //   fontSize: "13px",
                  //                   // },
                  //                 },
                  //               }}
                  //             />
                  //           </Box>
                  //         </Grid>
                  //         <Grid item xs={6}>
                  //           <Typography
                  //             sx={{
                  //               color: color.TabDataTitlecolor,
                  //               fontSize: color.TabDatafontsize,
                  //               mb: color.TabDatamarginbottom,
                  //             }}
                  //           >
                  //             Suppiler
                  //           </Typography>
                  //           {/* <Box>
                  //             <TextField
                  //               size="small"
                  //               variant="standard"
                  //               placeholder="Enter Suppiler"
                  //               sx={{
                  //                 ".MuiInputBase-input": {
                  //                   // letterSpacing: "0.2rem",
                  //                   fontSize: "13px",

                  //                   // "&::placeholder": {
                  //                   //   // color: "green",
                  //                   //   opacity: 1,
                  //                   //   color: color.placeholderColor,
                  //                   //   fontSize: "13px",
                  //                   // },
                  //                 },
                  //               }}
                  //             />
                  //           </Box> */}
                  //            <Box>
                  //             <Select
                  //               size={"small"}
                  //               // variant="standard"
                  //               // type="text"
                  //               name="supplierId"
                  //               onChange={formik.handleChange}
                  //               value={formik.values.supplierId}
                  //               fullWidth
                  //               displayEmpty
                  //               MenuProps={{
                  //                 PaperProps: { sx: { maxHeight: "40vh" } },
                  //               }}
                  //               renderValue={
                  //                 formik.values.supplierId !== undefined
                  //                   ? undefined
                  //                   : () => (
                  //                       <Typography
                  //                         sx={{
                  //                           color: color.placeholderColor,
                  //                           fontSize: "13px",
                  //                         }}
                  //                       >
                  //                         Select
                  //                       </Typography>
                  //                     )
                  //               }
                  //             >
                  //               {suppiler.map((id, i) => (
                  //                 <MenuItem value={id.value}>
                  //                   {id.text}
                  //                 </MenuItem>
                  //               ))}
                  //             </Select>
                  //           </Box>
                  //         </Grid>
                  //       </Grid>
                  //     </Box>
                  //   </Paper>
                  // </Box>
                ) : (
                  <General assetData={data} direction={assetExpl?.page?"column":direction} assetImage={assetImage} />
                )}
              </TabPanel>
              <TabPanel value="2">
                <Parts data={data.id} />
              </TabPanel>
              <TabPanel value="3">
                <WorkTab data={data.id} />
              </TabPanel>
              <TabPanel value="4">
                <Specification data={data.id} direction={direction} />
              </TabPanel>
              <TabPanel value="5">
                <LineChart data={data}  />
              </TabPanel>
            </TabContext>
          )}
        </Grid>
        {/* </Box> */}
        {/* <Box sx={{width: {xs:direction==="column"?"50%":"15%",lg:direction==="column"?"50%":"fit-content"}}}> */}

        <Grid
          container
          justifyContent={"flex-end"}
          item
          xs={direction === "column" ? 6 : 2.5}
          md={direction === "column" ? 6 : 1.5}
          lg={direction === "column" ? 6 : 1}
          sx={{
            boxShadow:
              direction === "column" ? "-1px 1px 7px 2px rgb(0 0 0 / 32%)" : 0,
            minHeight: "92vh",
            backgroundColor: "transparent",
            display:assetExpl?.page?"none":"default"
          }}
        >
          <ListMenu
            contentPosition={contentPosition}
            data={data}
            direction={direction}
            assetExpl={assetExpl?.page}
            getData={getData}
          />
        </Grid>
        {/* </Box> */}
        <Dialog
          open={locationDialog}
          onClose={handleCreateClose}
          children={
            <LocationTreeView
              handlelocationClick={(name, id) => {
                handlelocationClick(name, id);
              }}
              handleCreateClose={handleCreateClose}
            />
          }
        />
      </Grid>
    </Box>
  );
};

export default Asset_Details;
