import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  createFilterOptions,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear, Refresh } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";

const InventoryCreationModal = ({ mode, handleCloseClick, editData }) => {
  const [partData, setPartId] = useState([]);
  const [stockAisleData, setStockAisleData] = useState([]);
  const [stockRowData, setStockRowData] = useState([]);
  const [stockBinData, setStockBinData] = useState([]);
  const [stockRoomData, setStockRoomData] = useState([]);
  const [partSelect, setPartSel] = useState("");
  const [srSelect, setSRSel] = useState("");
  const [srAisleSelect, setSRAisleSel] = useState("");
  const [srRowSelect, setSRRowSel] = useState("");
  const [srBinSelect, setSRBinSel] = useState("");
  const [resetData,setResetData]=useState(false)

  const [loading, setLoading] = useState(false);

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
  getData()

    ////// Stock Room////////

    // const stockroom = masterAPI["stockRoom"].link;
    // axiosPrivate
    //   .get(`${stockroom}`)
    //   .then((res) => {
    //     setLoading(false);
    //     setStockRoomData(res.data.result);
    //   })
    //   .catch((e) => {
    //     setLoading(false);
    //     setStockRoomData([])
    //     console.log(e, "createStockRoomgetAll");
    //   });
      // if(mode==="Edit"){
      //   const aisleLink=masterAPI["stockRoomAisle"].link
      //   const rowlink=masterAPI["stockRoomAisleRow"].link
      //   const binLink=masterAPI["stockRoomRowBin"].link
      //   axiosPrivate.get(`${aisleLink}/${editData.stockRoomId}`).then(res=>{
      //     setStockAisleData(res.data.result)


      //   }).catch(e=>{
      //     setStockAisleData([])
      //     console.log(e,"error stockRoom Aisle")
      //   })
      //   axiosPrivate.get(`${rowlink}/${editData.stockRoomRowAisleId}`).then(res=>{
      //     setStockRowData(res.data.result)
          

      //   }).catch(e=>{
      //     setStockRowData([])
      //     console.log(e,"error stockRoom Row")
      //   })
      //   axiosPrivate.get(`${binLink}/${editData.stockRoomRowId}`).then(res=>{
      //     setStockBinData(res.data.result)
          

      //   }).catch(e=>{
      //     setStockBinData([])
      //     console.log(e,"error stockRoom Bin")
      //   })
      // }
  }, []);

  const getData=async()=>{
    setLoading(true);

    //////// Part Group ///////

    const link = masterAPI["parts"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setLoading(false);
        setPartId(res.data.result);
      })
      .catch((e) => {
        setPartId([])
        setLoading(false)
        console.log(e, "createPartgetAll");
      });
  }

  const initialValue = {
    glAccount: mode === "Edit" ? editData.glAccount : "",
    quantity: mode === "Edit" ? editData.quantity : "",
    partId: mode === "Edit" ? editData.partId : "",
    stockRoomRowBinId: mode === "Edit" ? editData.stockRoomRowBinId : "",
    qrCodeData: mode === "Edit" ? editData.qrCodeData : "",
    stockRoomRowId: mode === "Edit" ? editData.stockRoomRowId : "",
    stockRoomId: mode === "Edit" ? editData.stockRoomId : "",
    stockRoomRowAisleId: mode === "Edit" ? editData.stockRoomRowAisleId : "",
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["inventoryCreation"].create;
    const updateLink = masterAPI["inventoryCreation"].update;
    if (mode === "Create") {
     await axiosPrivate
        .post(`${createLink}`, value)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setResetData(false)
          setPartSel("")
          setSRAisleSel("")
      setSRBinSel("")
      setSRRowSel("")
      setSRSel("")
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "create inventory");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "update inventory");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  const handleStockRoom = async(partId,srId) => {
  if(resetData){
    const srAisleLink = masterAPI["selectList"].stockRoomAisle;
   await axiosPrivate
      .get(`${srAisleLink}/${srId.value}`)
      .then((res) => {
        console.log(res, "srAisle");
        setStockAisleData(res.data);
      })
      .catch((e) => {
        setStockAisleData([])
        console.log(e, "error aisle");
      });
    }else{
      const getAisleLink=masterAPI["selectList"].aisleByPartAndRoom
   await axiosPrivate.get(`${getAisleLink}/${partId}/${srId.value}`).then(res=>{
      setStockAisleData(res.data)
    }).catch(e=>{
      setStockAisleData([])
      console.log(e,"error")
    })
    }
  };
  const handleStockRoomAisle = async(partId,srAisleId) => {
    if(resetData){

    
    const srAisleRowLink = masterAPI["selectList"].stockRoomAisleRow;
   await axiosPrivate
      .get(`${srAisleRowLink}/${srAisleId.value}`)
      .then((res) => {
        console.log(res, "srRow");
        setStockRowData(res.data);
      })
      .catch((e) => {
        setStockRowData([])
        console.log(e, "error Row");
      });
    }else{
      const getRowLink=masterAPI["selectList"].rowByPartAndAisle
     await axiosPrivate.get(`${getRowLink}/${partId}/${srAisleId.value}`).then(res=>{
        setStockRowData(res.data)
       
      }).catch(e=>{
        setStockRowData([])
        console.log(e,"error")
      })
    }
  };
  const handleStockRoomRow =async(partId,srRowId) => {
    if(resetData){
    const srBinLink = masterAPI["selectList"].stockRoomAisleRowBin;
   await axiosPrivate
      .get(`${srBinLink}/${srRowId.value}`)
      .then((res) => {
        console.log(res, "srBin");
        setStockBinData(res.data);
      })
      .catch((e) => {
        setStockBinData([])
        console.log(e, "error Bin");
      });
    }else{
      const getBinLink=masterAPI["selectList"].binByPartAndRow
     await axiosPrivate.get(`${getBinLink}/${partId}/${srRowId.value}`).then(res=>{
       setStockBinData(res.data)
      }).catch(e=>{
        setStockBinData([])
        console.log(e,"error")
      })
    }
  };

  const handleRefresh=async()=>{
    const getFullSR=masterAPI["selectList"].stockRoom
   await axiosPrivate.get(`${getFullSR}`).then(res=>{
       setResetData(true)
       setStockRoomData(res.data)
        setSRAisleSel("")
      setSRBinSel("")
      setSRRowSel("")
      setSRSel("")
       
    }).catch(e=>{
      setStockRoomData([])
      console.log(e)
    })
  }

  const handlePart=async(value)=>{
    // setCauseSel(value.causeCode+"_"+value.causeName)
    // setPartInfo(value)
    setResetData(false)
    const srLink=masterAPI["selectList"].stockRoomByPart
   await axiosPrivate.get(`${srLink}/${value.id}`).then(res=>{
      console.log(res)
      setStockRoomData(res.data)
      setSRAisleSel("")
      setSRBinSel("")
      setSRRowSel("")
      setSRSel("")
    }).catch(e=>{
      console.log(e)
    })
   
    
    }

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          New Inventory Creation
        </Typography>
        <Box sx={{display:"flex",gap:"1rem",alignItems:"center"}}>
        <IconButton onClick={handleRefresh} sx={{cursor:"pointer"}}>
           <Refresh sx={{color:"inherit"}} />
        </IconButton>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
        </Box>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
            <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Part
                </Typography>
                <Autocomplete
                // value={remedySelect}
                inputValue={partSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                loading={partData.length===0}
                options={partData}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.code + " - " + option.partName || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handlePart(value);
                    setFieldValue("partId",value.id)
                    
                  }else{
                    setPartSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setPartSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="partId"
                    placeholder="Select Part"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                          {partData.length===0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Stock Room
                </Typography>
              
                
                 <Autocomplete
                inputValue={srSelect}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={stockRoomData}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                   option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handleStockRoom(values.partId,value);
                    setFieldValue("stockRoomId",value.value)
                  }else{
                    setSRSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomId"
                    placeholder="Select Stock Room"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />

              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Aisle
                </Typography>
                <Autocomplete
                // value={remedySelect}
                inputValue={srAisleSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={stockAisleData}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                   option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handleStockRoomAisle(values.partId,value);
                    setFieldValue("stockRoomRowAisleId",value.value)
                  }else{
                    setSRAisleSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRAisleSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomRowAisleId"
                    placeholder="Select Aisle"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Row
                </Typography>
                
                     <Autocomplete
                // value={remedySelect}
                inputValue={srRowSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={stockRowData}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handleStockRoomRow(values.partId,value);
                    setFieldValue("stockRoomRowId",value.value)
                  }else{
                    setSRRowSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRRowSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomRowId"
                    placeholder="Select Row"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Bin
                </Typography>
               
                     <Autocomplete
                // value={remedySelect}
                inputValue={srBinSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={stockBinData}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    
                    setFieldValue("stockRoomRowBinId",value.value)
                  }else{
                    setSRBinSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRBinSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomRowBinId"
                    placeholder="Select Bin"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Quantity
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="quantity"
                  placeholder="Enter Quantity"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
                 <Grid item xs={12} md={6}>
                              <Typography
                                sx={{
                                  fontWeight: color.labelFontWeight,
                                  mb: color.labelmb,
                                  fontSize: color.labelFont,
                                  color: color.labelColor,
                                }}
                              >
                                Qr Code Data
                              </Typography>
                              <Field
                                as={TextField}
                                size={"small"}
                                type="text"
                                name="qrCodeData"
                                placeholder="Enter Qr Code Data"
                                fullWidth
                                sx={{
                                  ".MuiInputBase-input": {
                                    // letterSpacing: "0.2rem",
              
                                    "&::placeholder": {
                                      // color: "green",
                                      opacity: 1,
                                      color: color.placeholderColor,
                                      fontSize: "13px",
                                    },
                                  },
                                }}
                              />
                            </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Gl Account
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="glAccount"
                  placeholder="Enter GL Account"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default InventoryCreationModal;
