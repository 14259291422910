


import React, { useEffect, useState } from "react";
import TableContainer from "../../Component/TableContainer";
import {
  Box,
  Checkbox,
  Dialog,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { masterAPI } from "../Masters/dataConfig";
import { axiosPrivate } from "../../axios/axios";
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  ModeEdit,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { color } from "../../StyleData/Config";
import CreateWO from "../WorkManagement/WorkOrder/WO";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import NormalTable from "../../Component/NormalTable";
// import AssetModal from "./AssetModal";
// import CreateAssetCategory from "./AssetCategoryModal";

const OverDueWoList = (apiData) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  console.log('data:', data); 
  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    const filteredData = apiData.apiData.filter(item => item.status === 'OVERDUE');
    setData(filteredData);
  };
  
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  

  

  const columns = [

    {
      Header: "WO Code",
      accessor: "code",
  
      Cell: ({ cell }) => {
        return (
          <Link
            to={`/redirectwo?id=${cell.row.original.id}`}
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: "WO Name",
      accessor: "workOrderName",
    },
   
  ];



  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
            px: 2, 
            py: 1,
          }}
        >
          Overdue Work Orders
        </Typography>
      </Box>
      <Box
    sx={{
      px: 2, 
      py: 1, 
    }}
  >
    <NormalTable data={data} columns={columns} filt={true} />
  </Box>
      <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"md"}
        children={
          <CreateWO
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      />
    </>
  );
};

export default OverDueWoList;
