import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Grid, Button, Dialog, Divider, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import NormalTable from "../../Component/NormalTable";
import { color } from "../../StyleData/Config";


const PMComplChart = ({ dashBoardDataComplaianceXaxis, dashBoardDataComplaianceYaxis, type, complainWOData, isDialogOpen }) => {
  const [chartDialog, setChartDialog] = useState({ openComWOList: false, dataType: '' });

  const filteredDataCO = complainWOData?.filter(item => item.complaianceStatus === 'Complaiance') || [];
  const filteredDataNCO = complainWOData?.filter(item => item.complaianceStatus === 'Non-Complaiance') || [];
  const filteredData = chartDialog.dataType === 'Complaiance' ? filteredDataCO : filteredDataNCO;

  const columns = [
    {
      Header: "WO Code",
      accessor: "code",
      Cell: ({ cell }) => (
        <Link
          to={`/redirectwo?id=${cell.row.original.id}`}
          style={{
            color: "#0d6efd",
            textDecoration: "underline",
            fontWeight: 800,
          }}
           target="_blank"
            rel="noopener noreferrer"
        >
          {cell.value}
        </Link>
      ),
    },
    {
      Header: "WO Name",
      accessor: "workOrderName",
    },
  ];

  const handleChartClick = (e, chartContext, config) => {
    if (isDialogOpen && config?.dataPointIndex != null) {
      const selectedData = dashBoardDataComplaianceYaxis[config.dataPointIndex];
      const dataType = selectedData === 'Complaiance' ? 'Complaiance' : 'Non-Complaiance';
      setChartDialog({ openComWOList: true, dataType });
    }
  };

  const handleChartDialogClose = () => {
    setChartDialog({ openComWOList: false, dataType: '' });
  };

  useEffect(() => {
    if (!dashBoardDataComplaianceXaxis || !dashBoardDataComplaianceYaxis) {
      console.warn("chart data error chcck");
    }
  }, [dashBoardDataComplaianceXaxis, dashBoardDataComplaianceYaxis]);

  return (
    <>
    
        <Chart
          height={type ? 300 : 200}
          width={"100%"}
          options={{
            responsive: [
              {
                breakpoint: 768,
                options: {
                  chart: {
                    width: 600,
                  },
                },
              },
            ],
            series: dashBoardDataComplaianceXaxis,
            labels: dashBoardDataComplaianceYaxis,
            title: {
              text: "PM Compliance",
              style: {
                fontFamily: "Nunito Sans, sans-serif",
                color: "#2362B4",
              },
            },
            colors: [
              "#1395CF", "#ff5c75", "#BCDBAF", "#BECB23", "#E0DD95", "#FED7A6",
              "#F15325", "#F9ADA8", "#A71D48", "#CBAFD5", "#453191", "#B4BFDD",
              "#3D5EAC", "#62B146", "#D0DD37", "#F3EC3A", "#F9BD19", "#F99B1E",
              "#F15523", "#7C3697",
            ],
            chart: {
              type: "pie",
              toolbar: {
                show: true,
                export: {
                  csv: { filename: "Complaiance" },
                  svg: { filename: "Complaiance" },
                  png: { filename: "Complaiance" },
                },
              },
              events: {
                dataPointSelection: handleChartClick,
              },
            },
            plotOptions: {
              pie: {
                borderRadius: 4,
                dataLabels: {
                  total: {
                    enabled: false,
                    style: {
                      fontSize: "13px",
                      fontWeight: 900,
                      fontFamily: "Nunito Sans, sans-serif",
                    },
                  },
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
          }}
          type="pie"
          series={dashBoardDataComplaianceXaxis}
        />
    

      <Dialog
        open={chartDialog.openComWOList}
        onClose={handleChartDialogClose}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <Typography
                sx={{
                  color: "#2362B4",
                  fontSize: "16px",
                  fontWeight: 600,
                  mb: 2,
                  px: 2,
                  py: 1,
                }}
              >
                PM Compliance Work Orders
              </Typography>
            </Box>
            <Box sx={{ px: 2, py: 1 }}>
              <NormalTable data={filteredData} columns={columns} filt={true} />
            </Box>
          </Grid>
          <Divider />
          <Grid container item justifyContent={"flex-end"}>
            <Button onClick={handleChartDialogClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default PMComplChart;