import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  createFilterOptions,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { masterAPI } from "../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import { color } from "../../../../StyleData/Config";
import { Formik, Form, Field } from "formik";
import NormalTable from "../../../../Component/NormalTable";
import TableContainerComp from "../../../../Component/TableContainer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";
import LabourEdit from "./LabourEdit";
import {
  Add,
  Clear,
  DeleteOutline,
  DeleteOutlined,
  Edit,
  EditOutlined,
  GroupAdd,
  Save,
  Upload,
} from "@mui/icons-material";
import { store } from "../../../ReduxStore/store";
import AddLabour from "./addLabour";
import Labour from "../WorkPlan/Labour";
import { toast } from "react-toastify";
import { getTabId } from "@mui/lab";
import Remark from "./Remark";
import ChangeStatusWO from "../SideMenu/ChangeStatusWO";

const Completion = ({ workOrderId, direction, completionData, refreshKey  }) => {
  const [tastData, setTaskData] = useState([]);
  const [labourList, setLabourList] = useState([]);
  const [partList, setPartList] = useState([]);
  const [problemInfo, setProblemInfo] = useState({
    problemCode: "",
    problemName: "",
  });
  const [causes, setCauses] = useState([]);
  const [editLabour, setEditLabour] = useState(false);
  const [editData, setEditData] = useState({});
  const [causeInfo, setCauseInfo] = useState({});
  const [addCause, setAddCause] = useState([]);
  const [causeSelect, setCauseSel] = useState("");
  const [causeLoading, setCauseLoading] = useState(false);
  const [failureReport, setFailureReport] = useState({
    workOrderFailureReportList: [],
    downTime: {},
  });
  const [storeDownTime, setStoreDownTime] = useState({
    downTime: "",
    stopBeginDate: null,
    stopEndDate: null,
    stopBeginDateBol: false,
    stopEndDateBol: false,
  });
  const [checkedList, setCheckedList] = useState([]);
  const [checkedDetail, setCheckedDetail] = useState([]);
  const [checkTask, setCheckTask] = useState([]);
  const [hourValue, setHourValue] = useState("");
  const [approvalTime, setApprovalTime] =useState({
    downTime: "",
    waitingForApprovalStart: null,
    waitingForApprovalEnd: null,
    waitingForApprovalStartBol: false,
    waitingForApprovalEndBol: false,
  });
  const [waitPartsTime, setWaitPartsTime] =useState({
    downTime: "",
    waitingForPartStart: null,
    waitingForPartEnd: null,
    waitingForPartStartBol: false,
    waitingForPartEndBol: false,
  });
  const [loading, setLoading] = useState(false);
  const [addLabour, setAddLabour] = useState(false);
  const [checkBol, setCheckBol] = useState(false);
  const [historyNote, setHistoryNote] = useState({
    hisBol: false,
    history: "",
  });
  const [woDetail, setWODetail] = useState({});
  const [taskCheckList, setTaskCheckList] = useState([]);
  const [taskCheckComDetail, setTaskCheckComDetail] = useState([]);
  const [taskcheckBol, setTaskCheckBol] = useState(false);
  const [subTask, setSubTask] = useState("");
  const [remarkWOTask, setRemarkWOTask] = useState({
    bol: false,
    data: {},
  });

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
    getData();
  }, [refreshKey]);

  const getData = async () => {
    setLoading(true);
    let problemId = "";
    const completeDetail = masterAPI["workOrder"].completeDetail;
    await axiosPrivate
      .get(`${completeDetail}/${workOrderId}`)
      .then(async (res) => {
        console.log(res);
        const resData = res.data.result;
        const sortTaskList = resData.workOrderTaskList;

        const subTask = sortTaskList.filter(
          (fil) => fil.taskTypeName.toUpperCase() === "SUB-TASK-STATUS"
        );
        const nonSubTask = sortTaskList.filter(
          (fil) => fil.taskTypeName.toUpperCase() !== "SUB-TASK-STATUS"
        );

        const sortedTask = subTask.concat(
          nonSubTask.sort((a, b) => a.sequenceNo - b.sequenceNo)
        );
        setTaskData(sortedTask);
        //  let decimalPlanHours=resData.workedHours!==0? resData.workedHours.toString().split(".")[0]+resData.workedHours.toString().split(".")[1]/60:0
        //  console.log(decimalPlanHours)
        // const plannedHours=decimalPlanHours/resData.workOrderTaskList.length
        const storeLabourList = [];
        for (let i = 0; resData.workOrderTaskLabourList.length > i; i++) {
          storeLabourList.push({
            ...resData.workOrderTaskLabourList[i],
            workedHours:
              resData.workedHours !== 0
                ? resData.workedHours / resData.workOrderTaskList.length
                : 0,
          });
        }

        setLabourList(storeLabourList);
        // setPartList(resData.workOrderTaskPartsList);
        setProblemInfo({
          problemCode: resData.problemCode,
          problemName: resData.problemName,
        });
        setWODetail(resData);
        console.log(resData.preventiveMaintenanceId);
        setHistoryNote({ hisBol: false, history: resData.notes });
        setStoreDownTime({
          ...storeDownTime,
          stopBeginDate:
            resData.preventiveMaintenanceId === undefined
              ? resData.stopBeginDate !== undefined
                ? resData.stopBeginDate
                : null
              : null,
          stopEndDate:
            resData.preventiveMaintenanceId === undefined
              ? resData?.stopEndDate !== undefined
                ? resData.stopEndDate
                : null
              : null,
        });
        if (
          resData.stopBeginDate !== undefined &&
          resData.stopEndDate !== undefined
        ) {
          handleDownTime(
            resData.stopEndDate,
            false,
            resData.stopBeginDate,
            false
          );
        }
        handleDownTimeApprove(resData.waitingForApprovalStart, true, resData.waitingForApprovalEnd, true);
        handleDownTimeParts(resData.waitingForPartStart, true, resData.waitingForPartEnd, true);
        // setLoading(false)
        // setFailure_problem({failureClassId:res.data.result.failureClassId,problemId:res.data.result.problemId})
        ////////////////////////////////cause////////////////////////////////
        const causeLink = masterAPI["Causes"].causesbyproblem;
        console.log(problemId);
        await axiosPrivate
          .get(`${causeLink}/${res.data.result.problemId}`)
          .then((res) => {
            console.log(res, "problem causes");
            setCauses(res.data.result);
          })
          .catch((e) => {
            setCauses([]);
            console.log(e, "problem causes Error");
          });
      })
      .catch((e) => {
        setTaskData([]);
        setLabourList([]);
        setProblemInfo({ problemCode: "", problemName: "" });
        setWODetail({});
        setHistoryNote({ hisBol: false, history: "" });
        setStoreDownTime({
          downTime: "",
          stopBeginDate: null,
          stopEndDate: null,
          stopBeginDateBol: false,
          stopEndDateBol: false,
        });
        console.log(e, "work complete details error");
      });

    const failureReoportLink = masterAPI["failureReport"].getByWO;
    await axiosPrivate
      .get(`${failureReoportLink}/${workOrderId}`)
      .then((res) => {
        console.log(res);
        setFailureReport(res.data.result);
      })
      .catch((e) => {
        setFailureReport({ workOrderFailureReportList: [], downTime: {} });
        console.log(e, "failure Report error");
      });

    const getParts = masterAPI["issue"].getIssuedPartsbyWO;
    await axiosPrivate
      .get(`${getParts}/${workOrderId}`)
      .then((res) => {
        console.log(res);
        setPartList(res.data.result);
      })
      .catch((e) => {
        setPartList([]);
        console.log(e, "error");
      });
    setLoading(false);
  };

  const handleEdit = (data) => {
    setEditData(data);
    setEditLabour(true);
  };

  // const handleClose = () => {
  //   setEditData({});
  //   setEditLabour(false);
  //   getData();
  // };
  const handleLabourClose = () => {
    setCheckTask([]);
    // setCheckedDetail([])
    // setCheckedList([])
    setAddLabour(false);
    getData();
  };

  // const handleCause = (causeValue) => {
  //   setRemedy([]);
  //   setRemLoading(true);
  //   setCauseId(causeValue);
  //   setRemedySel("");
  //   const remedyLink = masterAPI["Remedies"].remdybycause;
  //   axiosPrivate
  //     .get(`${remedyLink}/${causeValue}`)
  //     .then((res) => {
  //       console.log(res, "remedy by cause completion");
  //       setRemedy(res.data.result);
  //       setRemLoading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e, "remedy Error");
  //       setRemLoading(false);
  //     });
  // };

  const handleCause = (value) => {
    // setCauseSel(value.causeCode+"_"+value.causeName)
    console.log(value);
    setCauseInfo(value);
  };

  const handleRemedy = (remedyInfo) => {
    console.log(remedyInfo, addCause);
    const updateRemedy = [];

    for (let i = 0; addCause.length > i; i++) {
      if (addCause[i].causeId === remedyInfo.causeId) {
        updateRemedy.push({
          ...addCause[i],
          remedyId: remedyInfo.id,
          code: remedyInfo.code,
          remedyName: remedyInfo.remedyName,
        });
      } else {
        updateRemedy.push(addCause[i]);
      }
    }

    console.log(updateRemedy);
    setAddCause(updateRemedy);
  };

  const deleteCause = (id) => {
    const delCause = addCause.filter((fil) => fil.causeId !== id);
    setAddCause(delCause);
  };

  const removeRemedy = (delInfo) => {
    const removeData = [];
    for (let i = 0; addCause.length > i; i++) {
      if (addCause[i].causeId === delInfo) {
        removeData.push({
          ...addCause[i],
          remedyId: null,
          code: "",
          remedyName: "",
        });
      } else {
        removeData.push(addCause[i]);
      }
    }
    console.log(removeData);
    setAddCause(removeData);
  };

  const handleAddCause = async () => {
    setCauseLoading(true);
    console.log(causeInfo);
    const remedyLink = masterAPI["Remedies"].remdybycause;
    await axiosPrivate
      .get(`${remedyLink}/${causeInfo.id}`)
      .then((res) => {
        console.log(res);
        const storeRemedyWithCause = {
          causeId: causeInfo.id,
          causeCode: causeInfo.code,
          causeName: causeInfo.causeName,
          remedyId: null,
          remedyName: "",
          code: "",
          workOrderId: workOrderId,
          remedyList: res.data.result,
        };
        console.log(storeRemedyWithCause);
        setAddCause([...addCause, storeRemedyWithCause]);
        setCauseSel("");
        setCauseInfo({});
        setCauseLoading(false);
      })
      .catch((e) => {
        setCauseLoading(false);
        console.log(e, "add cause");
      });
  };

  const handleChecked = (e) => {
    setCheckBol(false);
    if (checkedList.includes(e.id)) {
      console.log(e, "no");
      const removeList = checkedList.filter((l) => l !== e.id);
      setCheckedList(removeList);
      const removeDetail = checkedDetail.filter((l) => l.id !== e.id);
      setCheckedDetail(removeDetail);
      const removeTask = checkTask.filter((l) => l !== e.workOrderTaskId);
      setCheckTask(removeTask);
    } else {
      console.log("yes");
      setCheckedList([...checkedList, e.id]);
      setCheckTask([...checkTask, e.workOrderTaskId]);
      setCheckedDetail([...checkedDetail, e]);
    }
  };
  const handleTaskChecked = (e) => {
    setTaskCheckBol(false);
    if (taskCheckList.includes(e.id)) {
      const removeList = taskCheckList.filter((l) => l !== e.id);
      setTaskCheckList(removeList);
    } else {
      setTaskCheckList([...taskCheckList, e.id]);
    }
  };
  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setCheckBol(true);
      const getlabId = [];
      const gettaskId = [];
      for (let i = 0; labourList.length > i; i++) {
        console.log(labourList[i]);
        gettaskId.push(labourList[i].workOrderTaskId);
        getlabId.push(labourList[i].id);
      }
      setCheckedDetail(labourList);
      setCheckedList(getlabId);
      setCheckTask(gettaskId);
    } else {
      setCheckBol(false);
      setCheckedList([]);
      setCheckedDetail([]);
      setCheckTask([]);
    }
  };

  const handleTaskCheckAll = (e) => {
    if (e.target.checked) {
      setTaskCheckBol(true);

      const taskId = [];
      for (let i = 0; tastData.length > i; i++) {
        if (tastData[i].taskTypeName.toUpperCase() === "SUB-TASK-STATUS") {
          taskId.push(tastData[i].id);
        }
      }
      console.log(taskId);

      setTaskCheckList(taskId);
    } else {
      setTaskCheckBol(false);
      setTaskCheckList([]);
    }
  };

  const handleDelete = async (id) => {
    const labourDelete = masterAPI["workOrderTaskLabour"].delete;
    await axiosPrivate
      .delete(`${labourDelete}/${id}`)
      .then((res) => {
        console.log(res, "labour success");
        getData();
      })
      .catch((e) => {
        console.log(e, "error labour delete");
      });
  };

  const handleTaskFeed = (val, id, name) => {
    const addFeed = [];
    const feedbackData = [];
    for (let i = 0; tastData.length > i; i++) {
      if (tastData[i].id === id) {
        addFeed.push({
          ...tastData[i],
          [name]: val,
        });
        const filterComDetail = taskCheckComDetail.filter(
          (fil) => fil.id === id
        );
        if (filterComDetail.length === 0) {
          feedbackData.push({
            ...tastData[i],
            [name]: val,
          });
        } else {
          for (let j = 0; taskCheckComDetail.length > j; j++) {
            if (taskCheckComDetail[j].id === id) {
              feedbackData.push({
                ...taskCheckComDetail[j],
                [name]: val,
              });
            } else {
              feedbackData.push(taskCheckComDetail[i]);
            }
          }
        }
      } else {
        addFeed.push(tastData[i]);
      }
    }
    console.log(feedbackData);
    setTaskData(addFeed);
    setTaskCheckComDetail(feedbackData);
  };

  const handleUpload = async (id, fileInfo, dataBase64) => {
    console.log(fileInfo);
    const postData = {
      workOrderTaskId: id,
      documentName: fileInfo.name,
      documentType: "Image",
      documentData: dataBase64,
      documentCategory: "TechnicalSpecification",
    };
    console.log(postData);
    const uploadLink = masterAPI["workOrderTask"].upload;
    await axiosPrivate
      .post(`${uploadLink}`, postData)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const columnSortData = tastData.sort((a, b) => a.taskNo - b.taskNo);
  const columns = [
    {
      Header: "checkBox",
      accessor: "id",
      Cell: ({ cell }) => {
        return (
          <Checkbox
            sx={{
              display:
                cell.row.original.taskTypeName.toUpperCase() ===
                "SUB-TASK-STATUS"
                  ? "default"
                  : "none",
            }}
            checked={taskCheckList.includes(cell.row.original.id)}
            onClick={() => {
              handleTaskChecked(cell.row.original);
            }}
          />
        );
      },
    },
    {
      Header: "Task No",
      accessor: "taskNo",
    },
    {
      Header: "Task Name",
      accessor: "taskName",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              width: "13vw",
              fontSize: "inherit",
              fontWeight: "inherit",
            }}
          >
            {cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "FeedBack",
      accessor: "textValue",
      Cell: ({ cell }) => {
        const [textvalue, setTextValue] = useState(
          cell.row.original.textValue || ""
        );
        const [numbervalue, setNumberValue] = useState(
          cell.row.original.numberValue || 0
        );
        const [curReadValue, setCurReadValue] = useState(
          cell.row.original.currentReading || 0
        );
        const [taskStatusValue, setTaskStatusValue] = useState(
          cell.row.original.taskStatus || ""
        );
        const [multipleChoice, setMultiChoice] = useState(
          cell.row.original.listOfSelectedChoices || [""]
        );
        // const handleChange = (e) => {

        // };
        return (
          <>
            <TextField
              fullWidth
              type={"text"}
              size="small"
              placeholder="Enter Feedback"
              value={textvalue}
              sx={{
                display:
                  cell.row.original.taskTypeName.toUpperCase() === "TEXT FIELD"
                    ? "block"
                    : "none",
              }}
              onChange={(e) => {
                console.log(cell, e.target.value);
                setTextValue(e.target.value);
                cell.row.original.textValue = e.target.value;
              }}
              onBlur={(e) =>
                handleTaskFeed(
                  e.target.value,
                  cell.row.original.id,
                  "textValue"
                )
              }
            />
            <TextField
              fullWidth
              type={"number"}
              size="small"
              placeholder="Enter Feedback"
              value={numbervalue}
              sx={{
                display:
                  cell.row.original.taskTypeName.toUpperCase() ===
                  "NUMBER FIELD"
                    ? "block"
                    : "none",
              }}
              onChange={(e) => {
                console.log(cell, e.target.value);
                setNumberValue(e.target.value);
                cell.row.original.numberValue = e.target.value;
              }}
              onBlur={(e) =>
                handleTaskFeed(
                  e.target.value,
                  cell.row.original.id,
                  "numberValue"
                )
              }
            />
            <TextField
              fullWidth
              type={"number"}
              size="small"
              placeholder="Enter Feedback"
              value={curReadValue}
              sx={{
                display:
                  cell.row.original.taskTypeName.toUpperCase() ===
                  "METER READING"
                    ? "block"
                    : "none",
              }}
              onChange={(e) => {
                console.log(cell, e.target.value);
                setCurReadValue(e.target.value);
                cell.row.original.numberValue = e.target.value;
              }}
              onBlur={(e) =>
                handleTaskFeed(
                  e.target.value,
                  cell.row.original.id,
                  "currentReading"
                )
              }
            />

            <Typography
              sx={{
                display:
                  cell.row.original.taskTypeName.toUpperCase() ===
                  "SUB-TASK-STATUS"
                    ? "block"
                    : "none",
                color: "black",
              }}
            >
              {taskStatusValue}
            </Typography>

            <Select
              size="small"
              renderValue={(value) =>
                value === "" ? (
                  <Typography
                    sx={{
                      opacity: 1,
                      color: "#A9A9A9",
                      fontSize: "inherit",
                    }}
                  >
                    Select
                  </Typography>
                ) : (
                  value
                )
              }
              value={multipleChoice[0]}
              onChange={(e) => {
                setMultiChoice([e.target.value]);
                cell.row.original.listOfSelectedChoices = [e.target.value];
              }}
              onBlur={(e) => {
                handleTaskFeed(
                  [e.target.value],
                  cell.row.original.id,
                  "listOfSelectedChoices"
                );
              }}
              sx={{
                display:
                  cell.row.original.taskTypeName.toUpperCase() ===
                  "MULTIPLE CHOICE"
                    ? "block"
                    : "none",
              }}
            >
              {cell.row.original.listOfChoices !== undefined
                ? cell.row.original.listOfChoices.map((list, i) => (
                    <MenuItem key={i} value={list}>
                      {list}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "taskDuration",
      Cell: ({ cell }) => {
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton component="label">
              <Upload
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
              <input
                type="file"
                // accept={"image/png, image/gif, image/jpeg"}
                hidden
                onChange={(e) => {
                  console.log(e.target.files[0]);
                  if (e.target.files[0] !== undefined) {
                    const file = e.target.files[0];
                    console.log(file.name);
                    const fileType = file.type.split("/")[1];
                    console.log(fileType);
                    // if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {

                    //   toast.error("Invalid format");
                    // } else {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                      // console.log(reader.result);
                      const dataSplit = reader.result.split("base64,")[1];
                      handleUpload(cell.row.original.id, file, dataSplit);
                    };
                    reader.onerror = function (error) {};
                    // }
                  } else {
                  }
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                setRemarkWOTask({
                  bol: true,
                  data: cell.row.original,
                });
              }}
            >
              <EditOutlined
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const labourSortData = labourList.sort((a, b) => a.taskNo - b.taskNo);
  const labourListColumn = [
    {
      Header: "checkBox",
      accessor: "id",
      Cell: ({ cell }) => {
        return (
          <Checkbox
            checked={checkedList.includes(cell.row.original.id)}
            onClick={() => {
              handleChecked(cell.row.original);
            }}
          />
        );
      },
    },
    {
      Header: "Task No",
      accessor: "taskNo",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              color: "#0d6efd",
              fontSize: "inherit",
              fontWeight: 600,
            }}
          >
            {cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Labour",
      accessor: "labourName",
    },
    {
      Header: "Craft",
      accessor: "craftName",
    },
    {
      Header: "Business",
      accessor: "businessName",
    },
    {
      Header: "Qty",
      accessor: "quantity",
    },

    {
      Header: "Work Hours",
      accessor: "workedHours",
    },
    {
      Header: "Rate",
      accessor: "rate",
    },
    {
      Header: "Line Cost",
      accessor: "cost",
    },
    // {
    //   Header: "Actions",
    //   Cell: ({ cell }) => {
    //     // console.log(cell)
    //     return (
    //       <Box sx={{ whiteSpace: "nowrap" }}>
    //         {/* <IconButton
    //         onClick={() => {
    //               handleEdit(cell.row.original);
    //             }}>
    //           <EditOutlined

    //             sx={{
    //               fontSize: color.masterEditDelButton,
    //               color: color.masterEditDelButtonColor,
    //             }}
    //           />
    //         </IconButton> */}
    //           {/* <IconButton
    //            onClick={() => {
    //             handleDelete(cell.row.original.id);
    //           }}
    //           >
    //                       <DeleteOutline

    //                         sx={{
    //                           fontSize: color.masterEditDelButton,
    //                           color: color.masterEditDelButtonColor,
    //                         }}
    //                       />
    //                     </IconButton>                 */}
    //       </Box>
    //     );
    //   },
    // },
  ];

  const failureReportColumn = [
    {
      Header: "Cause Code",
      accessor: "causeCode",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              color: "#0d6efd",
              fontSize: "inherit",
              fontWeight: 600,
              cursor: "pointer",
            }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.value}
          </Typography>
        );
      },
      // disableFilters:true,
    },
    {
      Header: "Cause Name",
      accessor: "causeName",

      // disableFilters:true,
    },
    {
      Header: "Remedy",
      accessor: "remedyList",
      Cell: ({ cell }) => {
        return (
          <Autocomplete
            //inputValue={cell.row.original.remedyCode+"_"+cell.row.original.remedyName}
            value={
              cell.row.original.remedyId === null ? null : cell.row.original
            }
            fullWidth
            filterOptions={filterOptions}
            // loading={causeLoading}
            options={cell.value}
            PaperComponent={CustomPaper}
            getOptionLabel={(option) =>
              option.code + " - " + option.remedyName || option
            }
            freeSolo
            onChange={(event, value) => {
              console.log(value, event);
              if (value !== null) {
                handleRemedy(value);
              } else {
                removeRemedy(cell.row.original.causeId);
                // setCauseSel("")
                // setCauseInfo({})
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="remedy"
                placeholder="Select Remedy"
                //  onChange={handleChange}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ width: "20vw" }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      {false ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        );
      },
      disableFilters: true,
    },
    {
      Header: "Action",
      accessor: "remedyId",
      Cell: ({ cell }) => {
        return (
          <IconButton
            onClick={() => {
              deleteCause(cell.row.original.causeId);
            }}
          >
            <DeleteOutlined
              sx={{
                fontSize: color.masterEditDelButton,
                color: color.masterEditDelButtonColor,
              }}
            />
          </IconButton>
        );
      },
      disableFilters: true,
    },
  ];

  const failureReportDisplayColumn = [
    {
      Header: "Cause Name",
      accessor: "causeName",

      // disableFilters:true,
    },
    {
      Header: "Remedy",
      accessor: "remedyName",
    },
    // {
    //   Header:"Action",
    //   accessor:"remedyId",
    //   Cell:({cell})=>{
    //     return (
    //       <IconButton
    //       onClick={() => {
    //         deleteCause(cell.row.original.causeId);
    //       }}
    //       >
    //       <DeleteOutlined

    //         sx={{
    //           fontSize: color.masterEditDelButton,
    //           color: color.masterEditDelButtonColor,
    //         }}
    //       />
    //     </IconButton>
    //     )
    //   },
    //   disableFilters:true,
    // }
  ];

  const handleDownTime = (endDate, endBol, beginDate, beginBol) => {
    var date1 = new Date(beginDate);
    var date2 = new Date(endDate);
    var diff = date2.getTime() - date1.getTime();
    var msec = diff;
    var days = Math.floor(diff / 1000 / 60 / (60 * 24));
    msec -= days * 24 * 60 * 60 * 1000;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    // var ss = Math.floor(msec / 1000);
    // msec -= ss * 1000;

    const downTimeData = {
      stopBeginDateBol: beginBol,
      stopEndDateBol: endBol,
      stopBeginDate: beginDate,
      stopEndDate: endDate,
      downTime:
        beginDate !== null && endDate !== null
          ? days + " days " + hh + " hours " + mm + " minutes "
          : "",
    };

    // const downTimeData = {

    //   stopBeginDate:name==="stopBeginDate"?values:storeDownTime.stopBeginDate,
    //   stopEndDate:name==="stopBeginData"?moment(values).add(1,"days").format("YYYY-MM-DDTHH:mm:ss"):storeDownTime.stopEndDate,
    //   downTime:days+" days "+hh+" hours "+mm+" minutes "
    // };
    console.log(downTimeData);
    setStoreDownTime(downTimeData);
  };
  const handleDownTimeApprove = (startDate, startBol, endDate, endBol) => {
    let downTime = "";
  
    if (startDate && endDate) {
      const date1 = new Date(startDate);
      const date2 = new Date(endDate);
      const diff = date2.getTime() - date1.getTime();
  
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  
      downTime = `${days} days ${hours} hours ${minutes} minutes`;
    }
  
    const downTimeData = {
      waitingForApprovalStartBol: startBol,
      waitingForApprovalEndBol: endBol,
      waitingForApprovalStart: startDate,
      waitingForApprovalEnd: endDate,
      downTime,
    };
    console.log('downTimeData',downTimeData)
    setApprovalTime(downTimeData);

  };

  const handleDownTimeParts = (startDate, startBol, endDate, endBol) => {
    let downTime = "";
  
    if (startDate && endDate) {
      const date1 = new Date(startDate);
      const date2 = new Date(endDate);
      const diff = date2.getTime() - date1.getTime();
  
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  
      downTime = `${days} days ${hours} hours ${minutes} minutes`;
    }
  
    const downTimeData = {
      waitingForPartStartBol: startBol,
      waitingForPartEndBol: endBol,
      waitingForPartStart: startDate,
      waitingForPartEnd: endDate,
      downTime,
    };
    console.log('downTimeData',downTimeData)
    setWaitPartsTime(downTimeData);

  };

 // const partSortData = partList.sort((a, b) => a.taskNo - b.taskNo);
  const partsColumns = [
    // {
    //   Header: "Task No",
    //   accessor: "taskNo",
    //   Cell: ({ cell }) => {
    //     return (
    //       <Typography
    //         sx={{
    //           color: "#0d6efd",
    //           fontSize: "inherit",
    //           fontWeight: 600,
    //         }}
    //       >
    //         {cell.value}
    //       </Typography>
    //     );
    //   },
    // },
    {
      Header: "Part Code",
      accessor: "code",
    },
    {
      Header: "Part Name",
      accessor: "partName",
    },
    {
      Header: "Issued Qty",
      accessor: "quantity",
    },
    {
      Header: "Unit",
      accessor: "unitCost",
    },
    {
      Header: "Cost",
      accessor: "lineCost",
    },
  ];

  // const handleRemedy = (remedyValue) => {
  //   console.log(remedyValue);
  //   setRemedySel(remedyValue.remedyCode + "_" + remedyValue.remedyName);
  //   const checkStoreRemedy = storeRemedy.filter(
  //     (fil) => fil.causeId === causeId
  //   );
  //   if (checkStoreRemedy.length === 0) {
  //     const storeValue = {
  //       workOrderId: workOrderId,
  //       causeId: causeId,
  //       remedyId: remedyValue.id,
  //     };
  //     setStoreRemedy([...storeRemedy, storeValue]);
  //   } else {
  //     const updateRemedy = [];
  //     console.log(storeRemedy);
  //     for (let i = 0; storeRemedy.length > i; i++) {
  //       console.log(causeId, storeRemedy[i]);
  //       if (causeId === storeRemedy[i].causeId || storeRemedy.length === 0) {
  //         const updateValue = { ...storeRemedy[i], remedyId: remedyValue.id };
  //         updateRemedy.push(updateValue);
  //       } else {
  //         updateRemedy.push(storeRemedy[i]);
  //       }
  //       setStoreRemedy(updateRemedy);
  //     }
  //   }
  // };

  const onSubmit = async () => {
    if (addCause.length !== 0) {
      setLoading(true);
      const validation = addCause.filter((fil) => fil.remedyId === null);

      if (validation.length === 0) {
        // const postAddCause=[]
        // for(let i=0;i<addCause.length;i++){
        //     postAddCause.push({
        //       causeId:addCause[i].causeId,
        //       workOrderId:addCause[i].workOrderId,
        //       remedyId:addCause[i].remedyId
        //     })
        // }
        // console.log(postAddCause)
        const failureReoportLink = masterAPI["failureReport"].bulkinsert;
        await axiosPrivate
          .post(`${failureReoportLink}`, addCause)
          .then((res) => {
            console.log(res);
            setAddCause([]);
            getData();
          })
          .catch((e) => {
            setLoading(false);
            console.log(e, "error failureReport");
          });
      } else {
        setLoading(false);
        toast.error("Select Remedy For Cause");
      }
    }
    if (hourValue !== "") {
      setLoading(true);
      handleLabourSave();
    }
    if (taskCheckComDetail.length !== 0) {
      console.log(taskCheckComDetail);
      const bulkinsertTaskFeed = masterAPI["workOrderTask"].bulkupdate;
      await axiosPrivate
        .post(`${bulkinsertTaskFeed}`, taskCheckComDetail)
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e, "bulkinsert error");
        });
    }
    let workorderUpdate = {
      ...woDetail,
    };

    const woUpdatelink = masterAPI["workOrder"].update;
    if (
      historyNote.hisBol ||
      storeDownTime.stopBeginDateBol ||
      storeDownTime.stopEndDateBol
    ) {
      setLoading(true);
      workorderUpdate = {
        ...workorderUpdate,
        notes: historyNote.history,
        stopBeginDate: storeDownTime.stopBeginDate,
        stopEndDate: storeDownTime.stopEndDate,
      };
      console.log(workorderUpdate);
      await axiosPrivate
        .put(`${woUpdatelink}`, workorderUpdate)
        .then((res) => {
          console.log(res);
          getData();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  };

  const handleLabourSave = async () => {
    console.log(checkedList, hourValue);
    //const updateplannedhr=masterAPI["workOrderTaskLabour"].updateplannedHrs
    const updateplannedhr = masterAPI["workOrder"].update;
    // const putData=[]
    //  let decimalPlanHours= Number(hourValue.split(":")[0])+(Number(hourValue.split(":")[1])/60)
    //   const plannedHours=decimalPlanHours/checkedList.length
    //   for(let i=0;checkedList.length>i;i++){
    //     const updateData={
    //       id: checkedList[i],
    //       // plannedHours: plannedHours,
    //       workedHours: plannedHours,
    //       rate:checkedDetail[i].rate,
    //       cost:checkedDetail[i].rate*plannedHours
    //     }
    //     putData.push(updateData)
    //   }

    // console.log(putData,plannedHours)
    const putData = {
      ...woDetail,
      workedHours: hourValue,
    };

    await axiosPrivate
      .put(`${updateplannedhr}`, putData)
      .then((res) => {
        console.log(res);
        getData();
        // setEditLabour(false)
        setHourValue("");
        setCheckedList([]);
        setCheckedDetail([]);
        setCheckTask([]);
        setCheckBol(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, "error updated planned hours completion");
      });
  };

  const handleSubTask = (e) => {
    setSubTask(e.target.value);
    const fullData = tastData;
    const taskDetail = taskCheckComDetail;
    for (let i = 0; taskCheckList.length > i; i++) {
      for (let j = 0; fullData.length > j; j++) {
        if (taskCheckList[i] === fullData[j].id) {
          fullData[j] = {
            ...fullData[j],
            taskStatus: e.target.value,
          };
          const taskDetailFilt = taskDetail.filter(
            (fil) => fil.id === taskCheckList[i]
          );
          if (taskDetailFilt.length === 0) {
            taskDetail.push({
              ...fullData[j],
              taskStatus: e.target.value,
            });
          } else {
            for (let k = 0; taskDetail.length > k; k++) {
              if (taskDetail[k].id === taskCheckList[i]) {
                taskDetail[k] = {
                  ...taskDetail[k],
                  taskStatus: e.target.value,
                };
                break;
              }
            }
          }

          break;
        }
      }
    }

    setTaskData(fullData);
    setTaskCheckComDetail(taskDetail);
    setTaskCheckList([]);
    setSubTask("");
  };

  const handleRemarkClose = () => {
    setRemarkWOTask({
      bol: false,
      data: {},
    });
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container gap={"1rem"}>
          <Grid
            item
            xs={12}
            md={direction === "column" ? 12 : 11.6}
            sx={{
              boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
              p: 1.5,
              backgroundColor: "white",
            }}
          >
            <Box>
              <Grid container alignItems={"center"} sx={{ mb: "0.5rem" }}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: color.masterDialogTitleColor,
                        fontSize: color.masterDialogFontSize,
                      }}
                    >
                      {" "}
                      Task{" "}
                    </Typography>
                    <Select
                      size="small"
                      value={subTask}
                      displayEmpty
                      renderValue={(value) =>
                        value.toUpperCase() === "NONE" ||
                        value.toUpperCase() === "" ? (
                          <Typography
                            sx={{
                              opacity: 1,
                              color: "#A9A9A9",
                              fontSize: "inherit",
                            }}
                          >
                            Select
                          </Typography>
                        ) : (
                          value
                        )
                      }
                      onChange={(e) => {
                        handleSubTask(e);
                      }}
                      sx={{
                        display: taskCheckList.length !== 0 ? "block" : "none",
                      }}
                    >
                      <MenuItem value={"Open"}>Open</MenuItem>
                      <MenuItem value={"Progress"}>Progress</MenuItem>
                      <MenuItem value={"OnHold"}>On Hold</MenuItem>
                      <MenuItem value={"Complete"}>Complete</MenuItem>
                    </Select>
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)" }} />
              <NormalTable
                columns={columns}
                data={columnSortData}
                handleCheckAll={handleTaskCheckAll}
                checkBol={taskcheckBol}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            // md={12}
            md={direction === "column" ? 12 : 11.6}
            sx={{
              boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
              p: 1.5,
              backgroundColor: "white",
            }}
          >
            <Box>
              <Grid container alignItems={"center"} sx={{ mb: "0.5rem" }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                    }}
                  >
                    {" "}
                    Parts{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
              />
              <NormalTable data={partList} columns={partsColumns} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={direction === "column" ? 12 : 11.6}
            sx={{
              boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
              p: 1.5,
              backgroundColor: "white",
            }}
          >
            <Box>
              <Grid container alignItems={"center"} sx={{ mb: "0.5rem" }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                    }}
                  >
                    {" "}
                    Labour{" "}
                  </Typography>
                </Grid>
                <Grid container item xs={6} justifyContent={"flex-end"}>
                  {/* {editLabour? */}
                  <Box sx={{ display: "flex", gap: "0.5rem" }}>
                    <Tooltip title="Labour">
                      <IconButton
                        disabled={checkedList.length === 0}
                        onClick={() => {
                          setAddLabour(true);
                        }}
                      >
                        <GroupAdd sx={{ fontSize: "1.7rem" }} />
                      </IconButton>
                    </Tooltip>
                    <TextField
                      size="small"
                      type="text"
                      value={hourValue}
                      // inputProps={{readOnly:checkedList.length===0}}
                      placeholder="worker hours(HH.MM)"
                      onChange={(e) => {
                        setHourValue(e.target.value);
                      }}
                    />

                    {/* <IconButton onClick={handleLabourSave}>
              <Save sx={{color:"#0d6efd"}}/>
            </IconButton>
            <IconButton onClick={()=>{
              // setCheckedList([])
              setHourValue("")
              setEditLabour(false)
              }}>
              <Clear/>
            </IconButton> */}
                  </Box>
                  {/* :
          
           // <IconButton onClick={()=>{setEditLabour(true)}} disabled={checkedList.length===0}><EditOutlined sx={{color:checkedList.length===0?"default":"#0d6efd"}}/></IconButton>
        
            } */}
                </Grid>
              </Grid>
              <Divider
                sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
              />
              <NormalTable
                data={labourSortData}
                columns={labourListColumn}
                handleCheckAll={handleCheckAll}
                checkBol={checkBol}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={direction === "column" ? 12 : 11.6}
            sx={{
              boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
              p: 1.5,
              backgroundColor: "white",
            }}
          >
            <Box>
              <Grid container alignItems={"center"} sx={{ mb: "0.5rem" }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                    }}
                  >
                    {" "}
                    Failure Reporting
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
              />
              <Grid container item xs={12} spacing={"1rem"} sx={{ mb: "1rem" }}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        // mb: color.labelmb,
                        fontSize: color.labelFont,
                        // color: color.labelColor,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Problem :
                    </Typography>
                    <TextField
                      size={"small"}
                      variant="outlined"
                      value={
                        problemInfo.problemCode +
                        " - " +
                        problemInfo.problemName
                      }
                      inputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={"1rem"}
                  sx={{
                    display:
                      failureReport?.workOrderFailureReportList?.length === 0
                        ? "default"
                        : "none",
                  }}
                >
                  <Grid item xs={10}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: color.labelFontWeight,

                          fontSize: color.labelFont,

                          whiteSpace: "nowrap",
                        }}
                      >
                        Causes :
                      </Typography>

                      <Autocomplete
                        inputValue={causeSelect}
                        fullWidth
                        filterOptions={filterOptions}
                        loading={causeLoading}
                        options={causes}
                        PaperComponent={CustomPaper}
                        getOptionLabel={(option) =>
                          option.code + " - " + option.causeName || option
                        }
                        freeSolo
                        onChange={(event, value) => {
                          console.log(value);
                          if (value !== null) {
                            handleCause(value);
                          } else {
                            setCauseSel("");
                            setCauseInfo({});
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          console.log(newInputValue);
                          setCauseSel(newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="causes"
                            placeholder="Select Cause"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {causeLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      disabled={causeSelect === ""}
                      sx={{ backgroundColor: color.TableButtonColor }}
                      onClick={handleAddCause}
                    >
                      {causeLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <Add
                          sx={{
                            color: causeSelect === "" ? "default" : "white",
                          }}
                        />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              {failureReport?.workOrderFailureReportList?.length === 0 ? (
                <NormalTable
                  data={addCause}
                  columns={failureReportColumn}
                  filt={true}
                />
              ) : (
                <NormalTable
                  data={failureReport?.workOrderFailureReportList}
                  columns={failureReportDisplayColumn}
                  filt={true}
                />
              )}
            </Box>
            <Box sx={{ mt: "1rem" }}>
              <Grid container alignItems={"center"} sx={{ mb: "0.5rem" }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                    }}
                  >
                    {" "}
                    History Notes
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
              />
              <TextField
                placeholder="History note"
                variant="standard"
                value={historyNote.history}
                onChange={(e) => {
                  setHistoryNote({ hisBol: true, history: e.target.value });
                }}
                fullWidth
                minRows={1}
                maxRows={5}
                multiline
              />
            </Box>
          </Grid>
     
     

          <Grid
            item
            xs={12}
            md={direction === "column" ? 12 : 11.6}
            sx={{
              boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
              p: 1.5,
              backgroundColor: "white",
            }}
          >
            <Box>
              <Grid container alignItems={"center"} sx={{ mb: "0.5rem" }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                    }}
                  >
                    {" "}
                    Waiting For Approval Time
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
              />

              <Grid container spacing={"1rem"}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ".css-1pduc5x-MuiStack-root": {
                      paddingTop: 0,
                      overflowX: { xs: "auto", lg: "hidden" },
                      overflowY: "hidden",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Waiting For Approval Start
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DateTimePicker
                      value={
                        approvalTime.waitingForApprovalStart !== undefined
                          ? dayjs(approvalTime.waitingForApprovalStart)
                          : null
                      }
                        ampm={false}
                        format="DD/MM/YYYY HH:mm"
                        inputProps={{ readOnly: true }}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        // timezone=""
                        // sx={{".css-1xhypcz-MuiStack-root":{
                        //   padding:0
                        // }}}

                        slotProps={{
                          textField: {
                            size: "small",
                            variant: "standard",
                            fullWidth: true,
                            inputProps: {
                              readOnly: true, // Set the input as read-only
                            },
                          },
                          openPickerButton: {
                            sx: { display: 'none' }, // Hides the calendar icon
                          },
                        }}
                        fullWidth
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ".css-1pduc5x-MuiStack-root": {
                      paddingTop: 0,
                      overflowX: { xs: "auto", lg: "hidden" },
                      overflowY: "hidden",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                  Waiting For Approval End
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DateTimePicker
                        value={
                          approvalTime.waitingForApprovalEnd !== undefined
                            ? dayjs(approvalTime.waitingForApprovalEnd)
                            : null
                        }
                        ampm={false}
                        format="DD/MM/YYYY HH:mm"
                        inputProps={{ readOnly: true }}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        // sx={{".css-1xhypcz-MuiStack-root":{
                        //   padding:0
                        // }}}

                        slotProps={{
                          textField: {
                            size: "small",
                            variant: "standard",
                            fullWidth: true,
                            inputProps: {
                              readOnly: true, // Set the input as read-only
                            },
                          },
                          openPickerButton: {
                            sx: { display: 'none' }, // Hides the calendar icon
                          },
                        }}
                        fullWidth
                
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ".css-1pduc5x-MuiStack-root": {
                      paddingTop: 0,
                      overflowX: { xs: "auto", lg: "hidden" },
                      overflowY: "hidden",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Total Time
                  </Typography>
                  <TextField
                    size={"small"}
                    type="text"
                    name="downTime"
                    variant="standard"
                    //placeholder="Enter Down Time"
                    value={approvalTime.downTime}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    // onChange={(e) => {
                    //   handleDownTime("downTime", e.target.value);
                    // }}
                    sx={{
                      color: "red",
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",

                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
              

              {/* </Form>
     )}
     </Formik> */}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={direction === "column" ? 12 : 11.6}
            sx={{
              boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
              p: 1.5,
              backgroundColor: "white",
            }}
          >
            <Box>
              <Grid container alignItems={"center"} sx={{ mb: "0.5rem" }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                    }}
                  >
                    {" "}
                    Waiting For Parts Time
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
              />

              <Grid container spacing={"1rem"}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ".css-1pduc5x-MuiStack-root": {
                      paddingTop: 0,
                      overflowX: { xs: "auto", lg: "hidden" },
                      overflowY: "hidden",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Waiting For Parts Start
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DateTimePicker
                        value={
                          waitPartsTime.waitingForPartStart !== undefined
                            ? dayjs(waitPartsTime.waitingForPartStart)
                            : null
                        }
                        ampm={false}
                        format="DD/MM/YYYY HH:mm"
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        // timezone=""
                        // sx={{".css-1xhypcz-MuiStack-root":{
                        //   padding:0
                        // }}}

                        slotProps={{
                          textField: {
                            size: "small",
                            variant: "standard",
                            fullWidth: true,
                            inputProps: {
                              readOnly: true, // Set the input as read-only
                            },
                          },
                          openPickerButton: {
                            sx: { display: 'none' }, // Hides the calendar icon
                          },
                        }}
                        fullWidth
                    
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ".css-1pduc5x-MuiStack-root": {
                      paddingTop: 0,
                      overflowX: { xs: "auto", lg: "hidden" },
                      overflowY: "hidden",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                  Waiting For Parts End
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DateTimePicker
                         value={
                          waitPartsTime.waitingForPartEnd !== undefined
                            ? dayjs(waitPartsTime.waitingForPartEnd)
                            : null
                        }
                        ampm={false}
                        format="DD/MM/YYYY HH:mm"
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        // sx={{".css-1xhypcz-MuiStack-root":{
                        //   padding:0
                        // }}}

                        slotProps={{
                          textField: {
                            size: "small",
                            variant: "standard",
                            fullWidth: true,
                            inputProps: {
                              readOnly: true, // Set the input as read-only
                            },
                          },
                          openPickerButton: {
                            sx: { display: 'none' }, // Hides the calendar icon
                          },
                        }}
                        fullWidth
                       
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ".css-1pduc5x-MuiStack-root": {
                      paddingTop: 0,
                      overflowX: { xs: "auto", lg: "hidden" },
                      overflowY: "hidden",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Total Time
                  </Typography>
                  <TextField
                    size={"small"}
                    type="text"
                    name="downTime"
                    variant="standard"
                    //placeholder="Enter Down Time"
                    value={waitPartsTime.downTime}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    // onChange={(e) => {
                    //   handleDownTime("downTime", e.target.value);
                    // }}
                    sx={{
                      color: "red",
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",

                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
              

              {/* </Form>
     )}
     </Formik> */}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={direction === "column" ? 12 : 11.6}
            sx={{
              boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
              p: 1.5,
              backgroundColor: "white",
            }}
          >
            <Box>
              <Grid container alignItems={"center"} sx={{ mb: "0.5rem" }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                    }}
                  >
                    {" "}
                    Down Time
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
              />

              <Grid container spacing={"1rem"}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ".css-1pduc5x-MuiStack-root": {
                      paddingTop: 0,
                      overflowX: { xs: "auto", lg: "hidden" },
                      overflowY: "hidden",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Stop Begin Date
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DateTimePicker
                        value={
                          storeDownTime.stopBeginDate !== null
                            ? dayjs(storeDownTime.stopBeginDate)
                            : storeDownTime.stopEndDate
                        }
                        ampm={false}
                        format="DD/MM/YYYY HH:mm"
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        // timezone=""
                        // sx={{".css-1xhypcz-MuiStack-root":{
                        //   padding:0
                        // }}}

                        slotProps={{
                          textField: {
                            size: "small",
                            variant: "standard",
                            fullWidth: true,
                          },
                        }}
                        fullWidth
                        onChange={(newValue) => {
                          console.log(newValue);
                          if (newValue !== null) {
                            if (`${newValue.$d}` !== "Invalid Date") {
                              const dateFrom = moment(newValue.$d).format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );

                              // 2022-04-17T15:30
                              // console.log(dateFrom+"T"+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds())
                              // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                              // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                              // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                              // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec

                              handleDownTime(
                                storeDownTime.stopEndDate,
                                storeDownTime.stopEndDateBol,
                                dateFrom,
                                true
                              );
                            }
                          }
                        }}
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ".css-1pduc5x-MuiStack-root": {
                      paddingTop: 0,
                      overflowX: { xs: "auto", lg: "hidden" },
                      overflowY: "hidden",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Stop End Date
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DateTimePicker
                        value={
                          storeDownTime.stopEndDate !== null
                            ? dayjs(storeDownTime.stopEndDate)
                            : storeDownTime.stopEndDate
                        }
                        ampm={false}
                        format="DD/MM/YYYY HH:mm"
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        // sx={{".css-1xhypcz-MuiStack-root":{
                        //   padding:0
                        // }}}

                        slotProps={{
                          textField: {
                            size: "small",
                            variant: "standard",
                            fullWidth: true,
                          },
                        }}
                        fullWidth
                        onChange={(newValue) => {
                          console.log(newValue);
                          if (newValue !== null) {
                            // "YYYY-MM-DDTHH:mm:ss" date format
                            if (`${newValue.$d}` !== "Invalid Date") {
                              const dateFrom = moment(newValue.$d).format(
                                "YYYY-MM-DDTHH:mm"
                              );
                              console.log(dateFrom);
                              // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                              // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                              // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                              // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec

                              handleDownTime(
                                dateFrom,
                                true,
                                storeDownTime.stopBeginDate,
                                storeDownTime.stopBeginDateBol
                              );
                            }
                          }
                        }}
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ".css-1pduc5x-MuiStack-root": {
                      paddingTop: 0,
                      overflowX: { xs: "auto", lg: "hidden" },
                      overflowY: "hidden",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Down Time
                  </Typography>
                  <TextField
                    size={"small"}
                    type="text"
                    name="downTime"
                    variant="standard"
                    placeholder="Enter Down Time"
                    value={storeDownTime.downTime}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    // onChange={(e) => {
                    //   handleDownTime("downTime", e.target.value);
                    // }}
                    sx={{
                      color: "red",
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",

                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* </Form>
     )}
     </Formik> */}
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={11.6}
            justifyContent={"flex-end"}
            gap={"1rem"}
          >
            <Button
              variant="contained"
              onClick={() => {
                // setCreateTask(false)
              }}
              sx={{ background: "white", color: "blue", textTransform: "none" }}
            >
              Clear
            </Button>
            <Button
              type={"submit"}
              variant="contained"
              disabled={loading}
              sx={{
                textTransform: "none",
                backgroundColor: !loading ? "default" : color.TableButtonColor,
                "&:hover": {
                  backgroundColor: !loading ? "default" : color.buttonDisable,
                },
              }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Grid>
          {/* <Dialog
      open={editLabour}
      onClose={handleClose}
      fullWidth
      maxWidth={"xs"}
      children={
        <LabourEdit handleCloseClick={handleClose} editData={editData} />
      }
    /> */}
          <Dialog
            open={remarkWOTask.bol}
            onClose={handleRemarkClose}
            fullWidth
            maxWidth={"xs"}
            children={
              <Remark
                handleCancel={handleRemarkClose}
                data={remarkWOTask.data}
              />
            }
          />
          <Dialog
            open={addLabour}
            onClose={handleLabourClose}
            fullWidth
            maxWidth={"md"}
            children={
              <Labour
                handleCloseClick={handleLabourClose}
                taskData={checkTask}
                type={"completion"}
                dataDetail={checkedDetail}
              />
            }
          />
        </Grid>
      )}
    </Box>
  );
};

export default Completion;
