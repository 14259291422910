import { Box, Divider, Grid, Paper, Typography, Dialog, DialogContent, DialogTitle, IconButton  } from "@mui/material";
import React, { useEffect, useState } from "react";
import { color } from "../../../StyleData/Config";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import product_image from "../../../images/product2.jpg"
import Loading from "../../../Component/Loading";
import DateFormatter from "../../../Component/DateFormatter";
import { axiosPrivate } from "../../../axios/axios";
import defaultimage from "../../../images/Nav/defaultimage.png.jpg"
import { masterAPI } from "../../Masters/dataConfig";
const General = ({ assetData,direction,assetImage }) => {
  const [open, setOpen] = useState(false);
  const [ltdCost, setCost ] = useState([]);
  const [assetmtbf, setassetMTBF] = useState([]);
  const [yTDCost, setYTDCost] = useState([]);
 // const imageState = assetImage;
  console.log('yTDCost',yTDCost.totalCost);

useEffect(()=>{
  getData()
}, []);

const getData=async()=>{
  const assetCost = masterAPI["assets"].getCost
  await axiosPrivate.get(`${assetCost}/${assetData.id}`)
  .then(res=>{
    console.log(res.data.result)
    setCost(res.data.result)
  }).catch(e=>{
    setCost([])
    console.log(e,"errorcost")
  })

  const assetYTD = masterAPI["assets"].assetYTDCost
  await axiosPrivate.get(`${assetCost}/${assetData.id}`)
  .then(res=>{
    console.log(res.data.result)
    setYTDCost(res.data.result)
  }).catch(e=>{
    setYTDCost([])
    console.log(e,"errorcost")
  })

  const assetMTBF = masterAPI["assets"].getMTBF
  await axiosPrivate.get(`${assetMTBF}/${assetData.id}`)
  .then(res=>{
    console.log(res.data.result)
    setassetMTBF(res.data.result)
  }).catch(e=>{
    setassetMTBF([])
    console.log(e,"errorcost")
  })
}


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
    sx={{
      display: "flex",
      // alignItems: "center",
      gap: "1.5rem",
      flexWrap: "noWrap",
      flexDirection:direction,
      width:"95%"
    }}
  >
    <Paper sx={{ flex:"0 0 49%"}}>
      <Box sx={{p:1.5}}>
      <Typography
        sx={{
          color: color.Basiccolor,
          fontSize: color.BasicfontSize,
          fontWeight: color.Basicfontweight,
        }}
      >
        Basic
      </Typography>
      </Box>
      <Divider />
      <Box sx={{display:"flex",width:"100%",p:1.5}}>
        <Grid container sx={{}} spacing={"1rem"}>
        <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Asset Name
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.assetName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Asset Desc
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.assetName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Locaiton
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.locationName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Parent Asset
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.parentAssetName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Asset Type 
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.assetTypeName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Asset Category 
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.categoryName}
            </Typography>
          </Grid>
           
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Failure Classes 
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.failureClassName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Priority 
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.priorityName}
            </Typography>
          </Grid>
 
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Criticality 
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.criticalityName}
            </Typography>
          </Grid>
      
    
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Rotaing
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.assetPriorityId}
            </Typography>
          </Grid>
     
        </Grid>
         <Paper elevation={3} sx={{width:"12vw",height:"19vh",p:0.5}} >
          <img src={assetImage ? assetImage : defaultimage} alt="productImage" style={{width: "100%",height:"100%"}}  onClick={handleClickOpen} /> 
          </Paper>
     
      </Box>
    </Paper>
    <Paper sx={{ flex:"0 0 49%"}}>
      <Box sx={{p:1.5}}>
      <Typography
        sx={{
          color: color.Basiccolor,
          fontSize: color.BasicfontSize,
          fontWeight: color.Basicfontweight,
        }}
      >
        Additional Info
      </Typography>
      </Box>
      <Divider />
      <Box sx={{display:"flex",width:"100%",p:1.5}}>
        <Grid container sx={{}} spacing={"1rem"}>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Serial Number
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.serialNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Model
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.model}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Installation Date
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
            {moment(assetData.installationDate).format("DD/MM/YYYY HH:mm")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
          Purchase Price
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.purchasePrice}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
            Supplier
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.supplierName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Manufacturer
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.manufacturerName}
            </Typography>
          </Grid>
      
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              YTD Cost
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
               {yTDCost.totalCost} 
            </Typography>
          </Grid>
    
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             LTD Cost
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
               {ltdCost.totalCost} 
            </Typography>
          </Grid>
      
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             YTD MTBF
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
               {assetmtbf.totalInterval}
            </Typography>
          </Grid>
       
          
        </Grid>
       
      </Box>
    </Paper>
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Image Preview</DialogTitle>
                <IconButton
                    onClick={handleClose}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: color.CancelTextColor,
                    }}
                >
                    <CloseIcon />
                </IconButton>
        <DialogContent>
          <img src={assetImage ? assetImage : defaultimage} alt="productImage" style={{ width: '100%' }} />
        </DialogContent>
      </Dialog>
  </Box>
  );
};

export default General;
