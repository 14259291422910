import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { masterAPI } from "../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import NormalTable from "../../../../Component/NormalTable";
import { color } from "../../../../StyleData/Config";

const InsScheduleLog = ({ data }) => {
  const [logData, setLogData] = useState([]);
  const [logGeneral,setLogGeneral]=useState([])
  useEffect(() => {
    // const documentLink = masterAPI["assets"].assetLogByid;
    // console.log(data);
    // axiosPrivate
    //   .get(`${documentLink}/${data}`)
    //   .then((res) => {
    //     console.log(res.data);
    //     // setLogData(res.data.result);
    //   })
    //   .catch((e) => {
      setLogData([])
    //     console.log(e, "assetDocumentError");
    //   });
    const data1=[{
      date:"22-3-2023",
      logtype:"Approved",
      userId:"Mo1",
    
    },
    {
      date:"22-3-2023",
      logtype:"Not-Approved",
      userId:"Mo2",
      
    },
    {
      date:"22-3-2023",
      logtype:"Approved",
      userId:"Mo1",
     
    }]
    setLogData(data1)
    const data2=[{
        date:"22-3-2023",
        logtype:"WOClosed",
        status:"Successful",
        userId:"Mo1",
        Detail:"----"
      },
      {
        date:"22-3-2023",
        logtype:"WOClosed",
        status:"Successful",
        userId:"Mo2",
        Detail:"----"
      },
      {
        date:"22-3-2023",
        logtype:"WOClosed",
        status:"UnSuccessful",
        userId:"Mo1",
        Detail:"----"
      }]
      setLogGeneral(data2)
  }, []);

  const columns = [
    // {
    //   Header: "Doc ID",
    //   accessor: "id",
    //   Cell: ({ cell }) => {
    //     return (
    //       <Typography
    //         sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue" }}
    //       >
    //         {cell.value}
    //       </Typography>
    //     );
    //   },
    // },
    {
      Header: "Date",
      accessor: "date",
     
    },
    
    {
      Header: "User",
      accessor: "userId",
    },
    {
        Header: "Log Type",
        accessor: "logtype",
        Cell: ({ cell }) => {
            return (
              <Typography sx={{ fontSize: "inherit", fontWeight: "inherit",color: cell.value==="Approved"?"green":"red"  }}>
                {cell.value}
              </Typography>
            );
          },
      },
    
  ];

  const columnsGeneral = [
    // {
    //   Header: "Doc ID",
    //   accessor: "id",
    //   Cell: ({ cell }) => {
    //     return (
    //       <Typography
    //         sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue" }}
    //       >
    //         {cell.value}
    //       </Typography>
    //     );
    //   },
    // },
    {
      Header: "Date",
      accessor: "date",
     
    },
    
    {
      Header: "User",
      accessor: "userId",
    },
    {
      Header:"Log Type",
      accessor:"logtype"
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => {
            return (
              <Typography sx={{ fontSize: "inherit", fontWeight: "inherit",color: cell.value==="Successful"?"green":"red"  }}>
                {cell.value}
              </Typography>
            );
          },
      },
    
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
    <Box>
      <Typography
        sx={{
          color: color.sideTitleColor,
          fontSize: color.fontSizeSide,
          fontWeight: color.sideWeightFont,
        }}
      >
        Log
      </Typography>
    </Box>
    <Paper sx={{p:1}} >
      
      <Typography
              sx={{
                color: color.sideTitle2Color,
                fontSize: color.fontSizeSide,
                fontWeight: color.sideFontWeight,
              }}
            >
            General Log
            </Typography>
            <Divider sx={{m:"0.5rem 0 0.5rem 0"}} />
        <NormalTable columns={columnsGeneral} data={logGeneral} />
      </Paper>
    {/* <Paper sx={{p:1}} >
      
    <Typography
            sx={{
              color: color.sideTitle2Color,
              fontSize: color.fontSizeSide,
              fontWeight: color.sideFontWeight,
            }}
          >
            Approval Log
          </Typography>
          <Divider sx={{m:"0.5rem 0 0.5rem 0"}} />
      <NormalTable columns={columns} data={logData} />
    </Paper> */}
    </Box>
  );
};

export default InsScheduleLog;
