import React, { useEffect, useState } from "react";
import TableContainer from "../../../../Component/TableContainer";
import { Box, Checkbox, Dialog, IconButton, Switch, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { masterAPI } from "../../dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import {useSelector} from "react-redux"
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { color } from "../../../../StyleData/Config";
import{useDispatch} from "react-redux";
import ShiftAssinmentModal from "./ShiftAssinmentModal";


const ShiftAssinment = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const actionsAllow=useSelector(state=>state.dataRed.actionsAllow)
  const dispatch=useDispatch()

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [filterValue,setFilterValue]=useState("enableData")
  const [backUpData,setBackUpData]=useState([])

  useEffect(() => {
   // getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["failureClass"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        res.data.result.sort((a, b) => {
          return a.code.localeCompare(b.code, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        if(filterValue==="enableData"){
          const filterDataResult=res.data.result.filter(fil=>fil.isActive)
          console.log(filterDataResult, "locationresult");
          setData(filterDataResult);
          setBackUpData(res.data.result)
        }else{
          setData(res.data.result)
          setBackUpData(res.data.result)
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "failureClass");
        setIsLoading(false);
        setData([])
        setBackUpData([])
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  // const handleDelete = (id) => {
  //   const deleteLink = masterAPI["failureClass"].delete;
  //   axiosPrivate
  //     .delete(`${deleteLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "deletefailureClass");
  //     });
  // };

  const handleDelete = async(e,id) => {
    const enableLink = masterAPI["failureClass"].enable;
    const disableLink = masterAPI["failureClass"].disable;
    if(e.target.checked){
     await axiosPrivate
      .put(`${enableLink}/${id}`)
      .then((res) => {
        console.log(res.data);
        getData();
      })
      .catch((e) => {
        console.log(e, "enable ");
      });
    }else{
     await axiosPrivate
      .put(`${disableLink}/${id}`)
      .then((res) => {
        console.log(res.data);
        getData();
      })
      .catch((e) => {
        console.log(e, "disable ");
      });
    }
  };
const setDatadataset = [
                          {   
                            code: "SA1",
                            id: "1",
              
                            labourName: "John",
                            shiftName: "Morning Shift",
                            assignedDate: "31/01/2025",
                            isRotational: "Yes"
                          },
                          {   
                            code: "SA1",
                            id: "2",
       
                            labourName: "Raj",
                            shiftName: "Evening Shift",
                            assignedDate: "01/02/2025",
                            isRotational: "No"
                          },
                          {   
                            code: "SA1",
                            id: "3",
           
                            labourName: "Arun",
                            shiftName: "Night Shift",
                            assignedDate: "02/02/2025",
                            isRotational: "No"
                          },
                         
                      
                       ]
console.log("setDatadataset",setDatadataset);
  const columns = [
    {
      accessor: "id",
      disableFilters: true,
      Cell: () => {
        return (
          <Box sx={{width:color.checkBoxWidth }}>
          <Checkbox
            // icon={<RadioButtonUncheckedOutlined />}
            // checkedIcon={<CheckCircle />}
          />
          </Box>
        );
      },
    },
    {
      Header: "Shift Assinment Code",
      accessor: "code",
      // disableFilters: true,
      Cell: ({ cell }) => {
        return (
          <Link
           // to={`/masters/failureclass/problem?id=${cell.row.original.id}`}
       //     onClick={()=>{dispatch({type:"failureClass",name:'failureClass',payload:cell.row.original.id})}}
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: "Labour",
      accessor: "labourName",
      // disableFilters: true,
    },
{
Header: "Shit",
accessor: "shiftName",
},
    {
        Header: "Assigned Date",
        accessor: "assignedDate",
        // disableFilters: true,
    },
    {
        Header: "Is Rotational",
        accessor: "isRotational",
        // disableFilters: true,
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton disabled={!actionsAllow.edit}>
              <EditOutlined
                onClick={() => {
                  handleEdit(cell.row.original);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: actionsAllow.edit? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton>
            {/* <IconButton disabled={!actionsAllow.delete}>
              <DeleteOutline
                onClick={() => {
                  handleDelete(cell.row.original.id);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: actionsAllow.delete? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton> */}
            <Switch
            disabled={!actionsAllow.delete}
            checked={cell.row.original.isActive}
            onChange={(e)=>{handleDelete(e,cell.row.original.id)}}
           
             />
          </Box>
        );
      },
    },
  ];

  const handleChangeFilter=(e)=>{
   
    if(e==="showAll"){
      setData(backUpData)
    }else{
        const filterDataResult=backUpData.filter(fil=>fil.isActive)
        
        setData(filterDataResult);
    }
    setFilterValue(e)   
  }

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {/* {masterAPI["failureClass"].title} */}Shit
        </Typography>
      </Box>
     
        <TableContainer
          columns={columns}
          data={setDatadataset}
          buttonNew={handleButtonNew}
          loading={isLoading}
          buttonDisable={!actionsAllow.create}
          filterValue={filterValue}
          handleChange={handleChangeFilter}
          filterShow={true}
        />
   

      <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"md"}
        children={
          <ShiftAssinmentModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      />
    </>
  );
};

export default ShiftAssinment;
